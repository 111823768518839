<template>
    <AutocompleteField
        v-model="selectedSize"
        :items="sizes"
        :item-title="displayText"
        item-value="id"
        :label="label ? label : fieldLabel"
        :returnObject="false"
        noAlphaSort
    />
</template>

<script setup lang="ts">
import { IdName, MunicipalitySizes, Sizes } from "@/types";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import AutocompleteField from "./customVuetify/AutocompleteField.vue";

const { t: $t } = useI18n();

const props = defineProps({
    municipality: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
    },
});

const selectedSize = ref<string | null>(null);
const sizes: IdName[] = props.municipality ? MunicipalitySizes : Sizes;
const fieldLabel = ref("");

const displayText = computed(
    () => (size: IdName | null) => size ? size.name : ""
);

if (!props.label) {
    fieldLabel.value = $t("common.size");
}
</script>
