<template>
    <Card :title="$t('calls.callProcess')">
        <template #content>
            <v-row justify="space-between">
                <v-col cols="auto">
                    <Span>{{ $t("calls.pickedUp") }}</Span>
                </v-col>
                <v-col cols="auto">
                    <RadioButton
                        class="mr-4"
                        :text="$t('calls.yes')"
                        :selected="callData.PickedUp === true"
                        @click="
                            callData.PickedUp === true
                                ? (callData.PickedUp = null)
                                : ((callData.PickedUp = true),
                                  (callData.DecisionMaker = null),
                                  (callData.NextAction = 0 as NextAction))
                        "
                    />
                    <RadioButton
                        :text="$t('calls.no')"
                        :selected="callData.PickedUp === false"
                        @click="
                            callData.PickedUp === false
                                ? ((callData.PickedUp = null),
                                  (callData.DecisionMaker = null),
                                  (callData.NextAction = 0 as NextAction))
                                : ((callData.PickedUp = false),
                                  (callData.DecisionMaker = false),
                                  (callData.NextAction = 1))
                        "
                    />
                </v-col>
            </v-row>
            <div v-if="callData.PickedUp === false">
                <v-row justify="space-between">
                    <v-col cols="auto">
                        <Span>{{ $t("calls.isKo") }}</Span>
                    </v-col>
                    <v-col cols="auto">
                        <RadioButton
                            class="mr-4"
                            :text="$t('calls.yes')"
                            :selected="callData.NextAction === NextAction.ko"
                            @click="callData.NextAction = NextAction.ko"
                        />
                        <RadioButton
                            :text="$t('calls.no')"
                            :selected="callData.NextAction === NextAction.call"
                            @click="callData.NextAction = NextAction.call"
                        />
                    </v-col>
                </v-row>
                <v-row class="divider-row">
                    <v-col class="divider-col">
                        <v-divider />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col style="padding-top: 0; padding-bottom: 0">
                        <Comment @save="saveCall" />
                    </v-col>
                </v-row>
            </div>
            <div v-if="callData.PickedUp">
                <v-row justify="space-between">
                    <v-col cols="auto">
                        <Span>{{ $t("calls.decisionMaker") }}</Span>
                    </v-col>
                    <v-col cols="auto">
                        <RadioButton
                            class="mr-4"
                            :text="$t('calls.yes')"
                            :selected="callData.DecisionMaker === true"
                            @click="
                                callData.DecisionMaker === true
                                    ? (callData.DecisionMaker = null)
                                    : (callData.DecisionMaker = true)
                            "
                        />
                        <RadioButton
                            :text="$t('calls.no')"
                            :selected="callData.DecisionMaker === false"
                            @click="
                                callData.DecisionMaker === false
                                    ? (callData.DecisionMaker = null)
                                    : (callData.DecisionMaker = false)
                            "
                        />
                    </v-col>
                </v-row>
                <div v-if="callData.DecisionMaker !== null">
                    <v-row class="divider-row">
                        <v-col class="divider-col">
                            <v-divider />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <Span class="next-action-title">{{
                                $t("calls.nextActionTitle")
                            }}</Span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="radio-buttons-col">
                            <RadioButton
                                class="mr-4"
                                :text="$t('calls.nextAction.call')"
                                :selected="
                                    callData.NextAction === NextAction.call
                                "
                                @click="callData.NextAction = NextAction.call"
                            />
                            <RadioButton
                                class="mr-4"
                                :text="$t('calls.nextAction.meeting')"
                                :selected="
                                    callData.NextAction === NextAction.meeting
                                "
                                @click="
                                    callData.NextAction = NextAction.meeting
                                "
                            />
                            <RadioButton
                                class="mr-4"
                                :text="$t('calls.nextAction.mail')"
                                :selected="
                                    callData.NextAction === NextAction.mail
                                "
                                @click="callData.NextAction = NextAction.mail"
                            />
                            <RadioButton
                                :text="$t('calls.nextAction.ko')"
                                :selected="
                                    callData.NextAction === NextAction.ko
                                "
                                @click="callData.NextAction = NextAction.ko"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="callData.NextAction">
                        <v-col>
                            <CallWindow
                                v-if="callData.NextAction === NextAction.call"
                                @save="saveCall"
                            />
                            <Meeting
                                v-if="
                                    callData.NextAction === NextAction.meeting
                                "
                                @save="saveCall"
                            />
                            <Mail
                                v-if="callData.NextAction === NextAction.mail"
                                @save="saveCall"
                            />
                            <Comment
                                v-if="callData.NextAction === NextAction.ko"
                                @save="saveCall"
                            />
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </Card>
</template>

<script setup lang="ts">
import { Span } from "@/components/customVuetify";
import Card from "@/components/customVuetify/Card.vue";
import RadioButton from "@/components/customVuetify/RadioButton.vue";
import { Call, NextAction } from "@/types";
import { Ref, inject, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import CallWindow from "./NextAction/Call.vue";
import Comment from "./NextAction/Comment.vue";
import Mail from "./NextAction/Mail.vue";
import Meeting from "./NextAction/Meeting.vue";

const { t: $t } = useI18n();
const route = useRoute();
const prospectId = ref(route.params.id as string);

const callData = inject("callData") as Ref<Partial<Call>>;

const emit = defineEmits(["saveCall"]);

async function saveCall() {
    emit("saveCall");
}

watch(
    () => route.params.id,
    async (newProspectId) => {
        prospectId.value = newProspectId as string;
    },
    { immediate: true }
);

function handleKeydown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    if (
        (target.tagName === "INPUT" && !target.readOnly) ||
        (target.tagName === "TEXTAREA" && !target.readOnly)
    ) {
        return;
    }

    switch (event.key) {
        case "1":
        case "&":
            callData.value.NextAction = NextAction.call;
            break;
        case "2":
        case "é":
            callData.value.NextAction = NextAction.meeting;
            break;
        case "3":
        case '"':
            callData.value.NextAction = NextAction.mail;
            break;
        case "4":
        case "'":
            callData.value.NextAction = NextAction.ko;
            break;
    }
}

onMounted(() => {
    window.addEventListener("keydown", handleKeydown);
});

onUnmounted(async () => {
    window.removeEventListener("keydown", handleKeydown);
});
</script>

<style scoped>
.divider-row {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.divider-col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.next-action-title {
    font-size: 1rem;
    font-weight: bold !important;
    padding-top: 1rem;
}

.radio-buttons-col {
    display: flex !important;
    justify-content: space-between !important;
}

.radio-buttons-col > * {
    flex: 1 1 0 !important;
    text-align: center !important;
}
</style>
