<template>
    <v-container style="height: 100%">
        <v-empty-state
            headline="403"
            title="Forbidden"
            text="You don't have permission to access this page"
        >
            <template #actions>
                <v-btn class="button-bg-secondary" to="/home">{{
                    $t("common.gotoHome")
                }}</v-btn>
            </template>
        </v-empty-state>
    </v-container>
</template>
