<template>
    <div class="container">
        <Title :title="$t('orders.titleArchived')" />
        <Filters @applyFilters="applyFilters" />
        <List :orders="filteredOrders" />
    </div>
</template>

<script setup lang="ts">
import { getCurrentClient } from "@/commons/firebase";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { Client } from "@/types";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import Filters from "./Filters.vue";
import List from "./List.vue";

const orders = ref<Client[]>([]);
const filters = ref({});
const currentClient = getCurrentClient();

const filteredOrders = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return orders.value;
    }
    return orders.value.filter((client: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                client[key] &&
                typeof client[key] === "string"
            ) {
                return client[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

function fetchOrders() {
    if (currentClient.value) {
        const orderCollection = collection(db, "ArchivedOrders");
        const ordersQuery = query(
            orderCollection,
            where("ClientId", "==", currentClient.value.id)
        );
        const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
            orders.value = snapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() } as any;
            });
        });

        watchEffect((onInvalidate) => {
            onInvalidate(() => {
                unsubscribe();
            });
        });
    }
}

watch(currentClient, () => {
    fetchOrders();
});

onMounted(() => {
    fetchOrders();
});
</script>
