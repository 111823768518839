<template>
    <v-container style="height: 100%">
        <v-empty-state
            headline="500"
            title="Internal Error"
            text="An internal error occurred. Please try again later."
        >
            <template #actions>
                <v-btn class="button-bg-secondary" to="/home">{{
                    $t("common.gotoHome")
                }}</v-btn>
            </template>
        </v-empty-state>
    </v-container>
</template>
