<template>
    <div class="container">
        <Title :title="$t('reporting.clientTitle')" />
        <v-row style="padding-left: 5vw">
            <v-btn
                class="button-bg-white button-back"
                @click="$router.push('/performances')"
                icon="mdi-chevron-left"
            />
            <Span class="page-title">{{
                $t("reporting.detailedView") + " " + clientName
            }}</Span>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card style="padding: 22px" flat>
                    <Title
                        :title="$t('reporting.reportingByEmployee')"
                        noBold
                    />
                    <DetailsEmployees :clientId="clientId" />
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card style="padding: 22px" flat>
                    <Title :title="$t('reporting.reportingByOrder')" noBold />
                    <DetailsOrders :clientId="clientId" />
                </v-card>
            </v-col>
        </v-row>
        <Title :title="$t('reporting.globalStats')" />
        <GraphCallsOnPickedUp />
        <GraphLineChart />
    </div>
</template>

<script setup lang="ts">
import { getClientFromId } from "@/commons/firebase/index";
import { Span } from "@/components/customVuetify";
import Title from "@/components/customVuetify/Title.vue";
import DetailsEmployees from "@/views/Reporting/ReportingClient/DetailsEmployees/Index.vue";
import DetailsOrders from "@/views/Reporting/ReportingClient/DetailsOrders/Index.vue";
import GraphCallsOnPickedUp from "@/views/Reporting/ReportingClient/Graphs/CallsOnPickedUp/Index.vue";
import GraphLineChart from "@/views/Reporting/ReportingClient/Graphs/LineChart/Index.vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t: $t } = useI18n();
const route = useRoute();

const clientId = route.params.id as string;

const clientName = await getClientFromId(clientId as string).then((client) => {
    return client ? client.Name : "";
});
</script>

<style scoped>
.button-back {
    height: 50px !important;
    width: 50px !important;
    border: none !important;
    background-color: transparent !important;
    font-size: 34px !important;
}

.page-title {
    font-size: 1.8rem;
    font-weight: bold;
    padding-left: 1vw;
}

#bar-chart rect {
    fill: steelblue;
}

#bar-chart text {
    fill: white;
    font: 10px sans-serif;
    text-anchor: end;
}
</style>
