<template>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                class="search-field"
                v-model="filters.LastName"
                :label="$t('reporting.lastname')"
                :disableMessage="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <TextField
                class="search-field"
                v-model="filters.FirstName"
                :label="$t('reporting.firstname')"
                :disableMessage="true"
            ></TextField>
        </v-col>
        <v-col cols="2">
            <AutocompleteField
                v-model="filters.OrderId"
                :items="orders"
                :label="$t('reporting.order')"
                item-title="name"
                item-value="id"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
        <v-spacer />
        <v-col cols="4">
            <DateFilter
                v-model:start-date="startDate"
                v-model:end-date="endDate"
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { getOrderListFromClientsNameId } from "@/commons/firebase";
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import TextField from "@/components/customVuetify/TextField.vue";
import DateFilter from "@/components/DateFilter.vue";
import { store } from "@/store";
import { IdName } from "@/types";
import { onMounted, ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["addUser", "applyFilters"]);

const startDate = ref(store.state.reportingStartDate);
const endDate = ref(store.state.reportingEndDate);

const filters = ref({
    LastName: "",
    FirstName: "",
    OrderId: [],
    startDate: ref(new Date(store.state.reportingStartDate)),
    endDate: ref(new Date(store.state.reportingEndDate)),
});

const props = defineProps({
    clientId: {
        type: String,
        required: true,
    },
});

const orders = ref<IdName[]>([]);

watchEffect(async () => {
    const result = await getOrderListFromClientsNameId([props.clientId]);
    orders.value = Array.isArray(result.value) ? result.value : [];
});

watch(
    () => store.state.reportingStartDate,
    (newVal) => {
        if (newVal !== undefined) {
            startDate.value = newVal;
        }
    }
);

watch(
    () => store.state.reportingEndDate,
    (newVal) => {
        if (newVal !== undefined) {
            endDate.value = newVal;
        }
    }
);

watch(startDate, (newVal) => {
    store.commit("setReportingStartDate", newVal);
    filters.value.startDate = new Date(newVal);
    emit("applyFilters", filters.value);
});

watch(endDate, (newVal) => {
    store.commit("setReportingEndDate", newVal);
    filters.value.endDate = new Date(newVal);
    emit("applyFilters", filters.value);
});

watch(
    () => filters.value.OrderId,
    () => {
        emit("applyFilters", filters.value);
    }
);

onMounted(async () => {
    filters.value.startDate = new Date(startDate.value);
    filters.value.endDate = new Date(endDate.value);
    emit("applyFilters", filters.value);
});
</script>
