<template>
    <TextField
        v-bind="$attrs"
        :class="disableMessage ? 'disable-message' : ''"
        ref="autocompleteInput"
        :label="$t('clients.address')"
    />
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import TextField from "./TextField.vue";

const { t: $t } = useI18n();

const emit = defineEmits(["address-selected"]);

defineProps({
    value: String,
    disableMessage: Boolean,
});

let autocompleteInput = ref(null);
let address = ref("");

function formatAddress(addressComponents: any) {
    let streetNumber = getAddressComponent(addressComponents, "street_number");
    let route = getAddressComponent(addressComponents, "route");
    route = route.replace(streetNumber, "").trim();
    return `${streetNumber} ${route}`;
}

function getAddressComponent(components: any, type: any) {
    for (let i = 0; i < components.length; i++) {
        if (components[i].types.includes(type)) {
            return components[i].long_name;
        }
    }
    return "";
}

onMounted(() => {
    nextTick(() => {
        if (autocompleteInput.value) {
            var autocomplete = new google.maps.places.Autocomplete(
                autocompleteInput.value.$el.querySelector("input"),
                {
                    bounds: new google.maps.LatLngBounds(
                        new google.maps.LatLng(46.603354, 1.888334)
                    ),
                }
            );

            autocomplete.addListener("place_changed", () => {
                var place = autocomplete.getPlace();
                if (!place.address_components || !place.formatted_address)
                    return;
                address.value = formatAddress(place.address_components);
                nextTick(() => {
                    if (!autocompleteInput.value) return;
                    autocompleteInput.value.value = address.value;
                });
                emit("address-selected", place.address_components);
            });
        }
    });
});
</script>
