<template>
    <v-expansion-panels>
        <v-expansion-panel v-model="panelOpen">
            <v-expansion-panel-title :static="true" class="card-top">
                <Span>{{ formatDate(new Date()) }}</Span>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="card card-bottom">
                <v-col cols="12">
                    <v-row no-gutters v-if="!isLoading && isData">
                        <v-col
                            :cols="orders.length > 1 ? 6 : 12"
                            v-for="(order, index) in orders"
                            :key="index"
                            class="pt-3 pb-2"
                            :class="{
                                'pl-2': index % 2 !== 0,
                                'pr-2': index % 2 === 0,
                            }"
                        >
                            <v-card class="stats-card mt-2">
                                <v-card-title
                                    class="card-title text-center"
                                    style="padding-bottom: 0"
                                    >{{ order.name }}</v-card-title
                                >
                                <v-card-title
                                    class="card-title text-center"
                                    style="padding-top: 0"
                                    >{{ order.segment }}</v-card-title
                                >
                                <v-row>
                                    <v-col cols="6" class="text-start">{{
                                        $t("home.callsMade")
                                    }}</v-col>
                                    <v-col cols="6" class="text-end bold">{{
                                        order.totalCalls
                                    }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="text-start">{{
                                        $t("home.callsTaken")
                                    }}</v-col>
                                    <v-col cols="6" class="text-end bold">{{
                                        order.totalCallsTaken
                                    }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="text-start">{{
                                        $t("home.numberDecisionMakers")
                                    }}</v-col>
                                    <v-col cols="6" class="text-end bold">{{
                                        order.totalDecisionMakers
                                    }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="text-start">{{
                                        $t("home.rdvTaken")
                                    }}</v-col>
                                    <v-col cols="6" class="text-end bold">{{
                                        order.totalRdv
                                    }}</v-col>
                                </v-row>
                                <v-divider />
                                <v-row>
                                    <v-col cols="6"
                                        ><Span>{{
                                            $t("home.rateOnTaken")
                                        }}</Span></v-col
                                    >
                                    <v-col cols="6"
                                        ><Span>{{
                                            $t("home.rateOnDecisionMakers")
                                        }}</Span></v-col
                                    >
                                </v-row>
                                <v-row>
                                    <v-col cols="6"
                                        ><Span
                                            class="bold"
                                            style="font-size: 2rem"
                                            >{{ order.rdvOverCallsTaken }}</Span
                                        ></v-col
                                    >
                                    <v-col cols="6"
                                        ><Span
                                            class="bold"
                                            style="font-size: 2rem"
                                            >{{
                                                order.rdvOverDecisionMakers
                                            }}</Span
                                        ></v-col
                                    >
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row v-if="isLoading">
                        <v-col cols="12" class="text-center">
                            <v-progress-circular
                                indeterminate
                                :color="`rgb(var(--v-theme-secondary))`"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-row v-if="!isLoading && !isData">
                        <v-col cols="12" class="text-center">{{
                            $t("home.noData")
                        }}</v-col>
                    </v-row>
                </v-col>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup lang="ts">
import { getCurrentClient } from "@/commons/firebase";
import { getOrdersWorkedOnForDay } from "@/commons/reporting";
import { Span } from "@/components/customVuetify";
import { getAuth } from "firebase/auth";
import { onMounted, ref, watch } from "vue";

const auth = getAuth();
const orders = ref<any>([]);
const isLoading = ref(false);
const isData = ref(false);
const panelOpen = ref(false);
const currentClient = getCurrentClient();
const emit = defineEmits(["close", "submit", "orders"]);

function formatDate(date: Date) {
    const formattedDate = date.toLocaleDateString("fr-FR", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    });

    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

async function getData() {
    isLoading.value = true;
    if (!auth.currentUser) {
        return;
    }
    orders.value = await getOrdersWorkedOnForDay(
        currentClient.value?.id,
        auth.currentUser.uid,
        new Date(),
        new Date()
    );
    isData.value = orders.value.length > 0;
    isLoading.value = false;
    emit("orders", orders.value);
}

watch(currentClient, async () => {
    await getData();
});

onMounted(async () => {
    await getData();
});
</script>

<style scoped>
.card {
    background-color: rgb(var(--v-theme-background)) !important;
    border: 1px solid #e0e0e0;
}

.stats-card {
    background-color: rgb(var(--v-theme-button_white));
    border: 1px solid #ffffff !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
}

.card-title {
    font-size: 1.1rem;
    font-weight: bold;
}

.card-top {
    border: 1px solid #e0e0e0;
    font-size: 1.3rem;
    background-color: rgb(var(--v-theme-background)) !important;
}

.card-bottom {
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: transparent;
}
</style>
