<template>
    <Dialog
        v-model="editClientOpen"
        :title="$t('clients.edit')"
        width="60vw"
        :isLoading="isDataLoading"
        @submit="editClient"
        @close="$emit('close')"
    >
        <template #text>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Name"
                        :label="$t('clients.name')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Address"
                        :label="$t('clients.address')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.City"
                        :label="$t('clients.city')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.PostalCode"
                        :label="$t('clients.postalCode')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Country"
                        :label="$t('clients.country')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Region"
                        :label="$t('clients.region')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Department"
                        :label="$t('clients.department')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Capital"
                        :label="$t('clients.capital')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.SIRET"
                        :label="$t('clients.siret')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.APECode"
                        :label="$t('clients.apeCode')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Size"
                        :label="$t('clients.size')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="client.Turnover"
                        :label="$t('clients.turnover')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <v-spacer></v-spacer>
            <v-btn
                class="button-bg-secondary"
                @click="unarchiveClient"
                :loading="isLoading"
                :disabled="isLoading"
                :text="$t('common.unarchive')"
                prependIcon="mdi-archive"
            />
            <v-btn
                class="button-bg-secondary"
                type="submit"
                :loading="isLoading"
                :disabled="isLoading"
                :text="$t('common.save')"
                prependIcon="mdi-content-save"
            />
        </template>
        <template #footer>
            <PasswordConfirmationDialog
                v-model="confirmDeleteDialog"
                @success="deleteClient"
                @close="confirmDeleteDialog = false"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import PasswordConfirmationDialog from "@/components/PasswordConfirmationDialog.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import { db } from "@/main";
import rules from "@/rules";
import { defaultClient } from "@/types/client";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    updateDoc,
} from "firebase/firestore";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["close"]);

const editClientOpen = ref(false);
const confirmDeleteDialog = ref(false);

const props = defineProps({
    clientId: {
        type: String,
        required: true,
    },
});

const client = ref({ ...defaultClient });

const isLoading = ref(false);
const isDataLoading = ref(false);
const clientDocId = ref("");

async function unarchiveClient() {
    isLoading.value = true;
    try {
        const clientId = clientDocId.value;
        const clientDoc = doc(db, "ArchivedClients", clientId);

        await addDoc(collection(db, "Clients"), client.value);

        await deleteDoc(clientDoc);

        emit("close");
    } catch (error) {
        console.error("Error unarchiving client: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function editClient() {
    try {
        isLoading.value = true;
        const clientId = clientDocId.value;
        const clientDoc = doc(db, "ArchivedClients", clientId);
        await updateDoc(clientDoc, client.value);

        emit("close");
    } catch (error) {
        console.error("Error editing client: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function deleteClient() {
    isLoading.value = true;
    try {
        const clientId = clientDocId.value;
        const clientDoc = doc(db, "ArchivedClients", clientId);
        await deleteDoc(clientDoc);
        emit("close");
    } catch (error) {
        console.error("Error deleting user: ", error);
    } finally {
        isLoading.value = false;
    }
}

async function getClientData() {
    isDataLoading.value = true;
    const clientDoc = doc(db, "ArchivedClients", props.clientId);
    const clientSnapshot = await getDoc(clientDoc);
    if (clientSnapshot.exists()) {
        const clientInfos = clientSnapshot.data();
        clientDocId.value = clientSnapshot.id;
        client.value = { ...clientInfos } as any;
    }
    isDataLoading.value = false;
}

onMounted(() => {
    if (props.clientId) {
        getClientData();
    }
});
</script>
