<template>
    <div class="container">
        <Title :title="$t('extracts.titleCalls')" />
        <Filters @applyFilters="applyFilters" />
        <List :calls="filteredCalls" :loading="isLoading" />
    </div>
</template>

<script setup lang="ts">
import { getTrigram } from "@/commons/convertion";
import {
    getCurrentClient,
    getOrderFromId,
    getUserFromId,
} from "@/commons/firebase";
import { getCallsForDate } from "@/commons/reporting";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { store } from "@/store";
import { doc, getDoc } from "firebase/firestore";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const calls = ref<any>([]);
const isLoading = ref(false);
const filters = ref({
    Type: "",
    startDate: new Date(),
    endDate: new Date(),
    ArchivedCalls: true,
});

const filteredCalls = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return calls.value;
    }
    return calls.value.filter((call: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                call[key] &&
                typeof call[key] === "string"
            ) {
                return call[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

async function applyFilters(newFilters: any) {
    filters.value = newFilters;
    await getData();
}

async function getData() {
    isLoading.value = true;
    const callList = await getCallsForDate(
        getCurrentClient().value.id,
        filters.value.startDate,
        filters.value.endDate,
        filters.value.ArchivedCalls
    );

    calls.value = await Promise.all(
        callList.map(async (call: any) => {
            const user = (await getUserFromId(call.EmployeeId)).data();
            const order = (await getOrderFromId(call.OrderId)).data();

            let prospectRef = doc(
                db,
                "Orders",
                call.OrderId,
                "Prospects",
                call.ProspectId
            );
            let prospectSnap = await getDoc(prospectRef);
            let prospectData = prospectSnap.data();

            if (prospectData === undefined) {
                prospectRef = doc(
                    db,
                    "ArchivedOrders",
                    call.OrderId,
                    "ArchivedProspects",
                    call.ProspectId
                );
                prospectSnap = await getDoc(prospectRef);
                prospectData = prospectSnap.data();
            }

            return {
                Company: prospectData?.Name,
                Contact: call.FirstName + " " + call.LastName.toUpperCase(),
                FirstName: call.FirstName,
                LastName: call.LastName,
                Position: call.Position,
                Phone: call.Phone,
                Mail: call.Mail,
                Order: order?.Name,
                OrderId: call.OrderId,
                DocId: call.DocId,
                ProspectId: call.ProspectId,
                Comments: call.Comments,
                CallNumber: call.DocId,
                CallDate: call.Date.toDate().toLocaleDateString("fr-FR"),
                Employee: getTrigram(user?.FirstName, user?.LastName),
                PostalCode: prospectData?.PostalCode,
                City: prospectData?.City,
            };
        })
    );
    isLoading.value = false;
}

watch(
    () => store.state.currentClient,
    (newClient) => {
        if (newClient) {
            getData();
        }
    }
);

onMounted(async () => {
    await getData();
});
</script>
