<template>
    <List
        :items="meetings"
        :headers="headers"
        @rowClicked="showMeeting"
        :sort-by="[{ key: 'Company', order: 'asc' }]"
        :loading="loading"
    />
    <MeetingDetails
        :meetingProps="selectedMeeting"
        v-model="showDialog"
        @close="showDialog = false"
    />
</template>

<script setup lang="ts">
import MeetingDetails from "@/components/MeetingDetails.vue";
import List from "@/components/customVuetify/List.vue";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

defineProps({
    meetings: Array as () => DocumentData[],
    loading: Boolean,
});

const selectedMeeting: any = ref();
const showDialog = ref(false);

function showMeeting(meeting: any) {
    selectedMeeting.value = meeting;
    showDialog.value = true;
}

const headers = [
    { title: $t("extracts.type"), value: "Type" },
    { title: $t("extracts.company"), value: "Company" },
    { title: $t("extracts.turnover"), value: "Turnover" },
    { title: $t("extracts.size"), value: "Size" },
    { title: $t("extracts.website"), value: "Website" },
    { title: $t("extracts.postalCode"), value: "PostalCode" },
    { title: $t("extracts.city"), value: "City" },
    { title: $t("extracts.contact"), value: "Contact" },
    { title: $t("extracts.position"), value: "Position" },
    { title: $t("extracts.date"), value: "Date" },
    { title: $t("extracts.hour"), value: "Hour" },
    { title: $t("extracts.comment"), value: "Comments" },
    { title: $t("extracts.employee"), value: "Employee" },
];
</script>
