export interface Colors {
    Primary: string;
    Secondary: string;
    Text: string;
    Background: string;
    BackgroundDark: string;
}

export interface Client {
    Address: string;
    APECode: string;
    Capital: string;
    City: string;
    // Colors: Colors;
    Country: string;
    Department: string;
    Name: string;
    PostalCode: string;
    Region: string;
    SIRET: string;
    Size: string;
    Turnover: string;
    LogoUrl: string;
}
export const defaultClient: Client = {
    Address: "",
    APECode: "",
    Capital: "",
    City: "",
    // Colors: {
    //     Primary: "#000000",
    //     Secondary: "#000000",
    //     Text: "#000000",
    //     Background: "#000000",
    //     BackgroundDark: "#000000",
    // },
    Country: "",
    Department: "",
    Name: "",
    PostalCode: "",
    Region: "",
    SIRET: "",
    Size: null as unknown as string,
    Turnover: "",
    LogoUrl:
        "https://firebasestorage.googleapis.com/v0/b/youscalesaas.appspot.com/o/Logos%2FMoUfzDUmvDXO0TXsizsJ?alt=media&token=29a631c1-d94d-4c7a-8235-44c8d0da1612",
};
