<template>
    <List
        :headers="headers"
        :items="users"
        :sort-by="[{ key: 'LastName', order: 'asc' }]"
        :noClick="true"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { UserList } from "@/types";
import { DocumentData } from "firebase/firestore";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

defineProps({
    users: Array as () => UserList[] | DocumentData[],
});

const headers = [
    { title: $t("reporting.lastname"), value: "LastName" },
    { title: $t("reporting.firstname"), value: "FirstName" },
    { title: $t("reporting.totalCalls"), value: "totalCalls" },
    { title: $t("reporting.totalPickedUp"), value: "totalPickedUp" },
    {
        title: $t("reporting.totalDecisionMakers"),
        value: "totalDecisionMakers",
    },
    { title: $t("reporting.totalRdv"), value: "totalRdv" },
    { title: $t("reporting.rateOnPickedUp"), value: "rateOnPickedUp" },
    {
        title: $t("reporting.rateOnDecisionMakers"),
        value: "rateOnDecisionMakers",
    },
];
</script>
