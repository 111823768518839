<template>
    <div class="container">
        <Title :title="$t('db.titleAll')" />
        <Filters
            @applyFilters="applyFilters"
            :prospectsNumber="filteredProspects.length"
        />
        <List :prospects="filteredProspects" />
    </div>
</template>

<script setup lang="ts">
import { getDepartmentNumberFromPostalCode } from "@/commons/convertion";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { store } from "@/store";
import { IdName, Prospect, departmentRegionTypeMapping } from "@/types";
import { collection, getDocs, query, where } from "firebase/firestore";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const prospects = ref<Prospect[]>([]);
const currentClient = ref<IdName | null>(null);
const filters = ref({});

const filteredProspects = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return prospects.value;
    }
    return prospects.value.filter((prospect: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return prospect[key]
                    .toLowerCase()
                    .includes(value.toLowerCase());
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return value.some(
                    (v) => v.toLowerCase() === prospect[key].toLowerCase()
                );
            }
            return true;
        })
    );
});

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

async function setupFirestoreListener() {
    prospects.value = [];
    if (!currentClient.value) return;
    const ordersCollection = collection(db, "Orders");
    const q = query(
        ordersCollection,
        where("ClientId", "==", currentClient.value.id)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
        const prospectsCollection = collection(doc.ref, "Prospects");
        const prospectsSnapshot = await getDocs(prospectsCollection);
        const orderProspects = prospectsSnapshot.docs.map((doc) => {
            const parentDocument = doc.ref.parent.parent;
            return {
                DocId: doc.id,
                OrderId: parentDocument ? parentDocument.id : null,
                Department: departmentRegionTypeMapping[
                    getDepartmentNumberFromPostalCode(doc.data().PostalCode)
                ]
                    ? departmentRegionTypeMapping[
                          getDepartmentNumberFromPostalCode(
                              doc.data().PostalCode
                          )
                      ].department
                    : "Error",
                ...doc.data(),
            } as any;
        });
        prospects.value = [...prospects.value, ...orderProspects];
    });
}

watch(
    () => store.state.currentClient,
    (newClient) => {
        if (newClient) {
            currentClient.value = newClient;
            setupFirestoreListener();
        }
    }
);

onMounted(() => {
    currentClient.value = store.state.currentClient;
    setupFirestoreListener();
});
</script>
