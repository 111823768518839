interface DepartmentRegionTypeMapping {
    [key: string]: {
        region: string;
        department: string;
        type: string;
    };
}

export const departmentRegionTypeMapping: DepartmentRegionTypeMapping = {
    "N/A": { region: "N/A", department: "N/A", type: "N/A" },
    ERROR: { region: "ERROR", department: "ERROR", type: "ERROR" },
    "01": {
        region: "Auvergne-Rhône-Alpes",
        department: "Ain",
        type: "Métropole",
    },
    "02": { region: "Hauts-de-France", department: "Aisne", type: "Métropole" },
    "03": {
        region: "Auvergne-Rhône-Alpes",
        department: "Allier",
        type: "Métropole",
    },
    "04": {
        region: "Provence-Alpes-Côte d'Azur",
        department: "Alpes-de-Haute-Provence",
        type: "Métropole",
    },
    "05": {
        region: "Provence-Alpes-Côte d'Azur",
        department: "Hautes-Alpes",
        type: "Métropole",
    },
    "06": {
        region: "Provence-Alpes-Côte d'Azur",
        department: "Alpes-Maritimes",
        type: "Métropole",
    },
    "07": {
        region: "Auvergne-Rhône-Alpes",
        department: "Ardèche",
        type: "Métropole",
    },
    "08": { region: "Grand Est", department: "Ardennes", type: "Métropole" },
    "09": { region: "Occitanie", department: "Ariège", type: "Métropole" },
    "10": { region: "Grand Est", department: "Aube", type: "Métropole" },
    "11": { region: "Occitanie", department: "Aude", type: "Métropole" },
    "12": { region: "Occitanie", department: "Aveyron", type: "Métropole" },
    "13": {
        region: "Provence-Alpes-Côte d'Azur",
        department: "Bouches-du-Rhône",
        type: "Métropole",
    },
    "14": { region: "Normandie", department: "Calvados", type: "Métropole" },
    "15": {
        region: "Auvergne-Rhône-Alpes",
        department: "Cantal",
        type: "Métropole",
    },
    "16": {
        region: "Nouvelle-Aquitaine",
        department: "Charente",
        type: "Métropole",
    },
    "17": {
        region: "Nouvelle-Aquitaine",
        department: "Charente-Maritime",
        type: "Métropole",
    },
    "18": {
        region: "Centre-Val de Loire",
        department: "Cher",
        type: "Métropole",
    },
    "19": {
        region: "Nouvelle-Aquitaine",
        department: "Corrèze",
        type: "Métropole",
    },
    "21": {
        region: "Bourgogne-Franche-Comté",
        department: "Côte-d'Or",
        type: "Métropole",
    },
    "22": {
        region: "Bretagne",
        department: "Côtes-d'Armor",
        type: "Métropole",
    },
    "23": {
        region: "Nouvelle-Aquitaine",
        department: "Creuse",
        type: "Métropole",
    },
    "24": {
        region: "Nouvelle-Aquitaine",
        department: "Dordogne",
        type: "Métropole",
    },
    "25": {
        region: "Bourgogne-Franche-Comté",
        department: "Doubs",
        type: "Métropole",
    },
    "26": {
        region: "Auvergne-Rhône-Alpes",
        department: "Drôme",
        type: "Métropole",
    },
    "27": { region: "Normandie", department: "Eure", type: "Métropole" },
    "28": {
        region: "Centre-Val de Loire",
        department: "Eure-et-Loir",
        type: "Métropole",
    },
    "29": { region: "Bretagne", department: "Finistère", type: "Métropole" },
    "2A": { region: "Corse", department: "Corse-du-Sud", type: "Métropole" },
    "2B": { region: "Corse", department: "Haute-Corse", type: "Métropole" },
    "30": { region: "Occitanie", department: "Gard", type: "Métropole" },
    "31": {
        region: "Occitanie",
        department: "Haute-Garonne",
        type: "Métropole",
    },
    "32": { region: "Occitanie", department: "Gers", type: "Métropole" },
    "33": {
        region: "Nouvelle-Aquitaine",
        department: "Gironde",
        type: "Métropole",
    },
    "34": { region: "Occitanie", department: "Hérault", type: "Métropole" },
    "35": {
        region: "Bretagne",
        department: "Ille-et-Vilaine",
        type: "Métropole",
    },
    "36": {
        region: "Centre-Val de Loire",
        department: "Indre",
        type: "Métropole",
    },
    "37": {
        region: "Centre-Val de Loire",
        department: "Indre-et-Loire",
        type: "Métropole",
    },
    "38": {
        region: "Auvergne-Rhône-Alpes",
        department: "Isère",
        type: "Métropole",
    },
    "39": {
        region: "Bourgogne-Franche-Comté",
        department: "Jura",
        type: "Métropole",
    },
    "40": {
        region: "Nouvelle-Aquitaine",
        department: "Landes",
        type: "Métropole",
    },
    "41": {
        region: "Centre-Val de Loire",
        department: "Loir-et-Cher",
        type: "Métropole",
    },
    "42": {
        region: "Auvergne-Rhône-Alpes",
        department: "Loire",
        type: "Métropole",
    },
    "43": {
        region: "Auvergne-Rhône-Alpes",
        department: "Haute-Loire",
        type: "Métropole",
    },
    "44": {
        region: "Pays de la Loire",
        department: "Loire-Atlantique",
        type: "Métropole",
    },
    "45": {
        region: "Centre-Val de Loire",
        department: "Loiret",
        type: "Métropole",
    },
    "46": { region: "Occitanie", department: "Lot", type: "Métropole" },
    "47": {
        region: "Nouvelle-Aquitaine",
        department: "Lot-et-Garonne",
        type: "Métropole",
    },
    "48": { region: "Occitanie", department: "Lozère", type: "Métropole" },
    "49": {
        region: "Pays de la Loire",
        department: "Maine-et-Loire",
        type: "Métropole",
    },
    "50": { region: "Normandie", department: "Manche", type: "Métropole" },
    "51": { region: "Grand Est", department: "Marne", type: "Métropole" },
    "52": { region: "Grand Est", department: "Haute-Marne", type: "Métropole" },
    "53": {
        region: "Pays de la Loire",
        department: "Mayenne",
        type: "Métropole",
    },
    "54": {
        region: "Grand Est",
        department: "Meurthe-et-Moselle",
        type: "Métropole",
    },
    "55": { region: "Grand Est", department: "Meuse", type: "Métropole" },
    "56": { region: "Bretagne", department: "Morbihan", type: "Métropole" },
    "57": { region: "Grand Est", department: "Moselle", type: "Métropole" },
    "58": {
        region: "Bourgogne-Franche-Comté",
        department: "Nièvre",
        type: "Métropole",
    },
    "59": { region: "Hauts-de-France", department: "Nord", type: "Métropole" },
    "60": { region: "Hauts-de-France", department: "Oise", type: "Métropole" },
    "61": { region: "Normandie", department: "Orne", type: "Métropole" },
    "62": {
        region: "Hauts-de-France",
        department: "Pas-de-Calais",
        type: "Métropole",
    },
    "63": {
        region: "Auvergne-Rhône-Alpes",
        department: "Puy-de-Dôme",
        type: "Métropole",
    },
    "64": {
        region: "Nouvelle-Aquitaine",
        department: "Pyrénées-Atlantiques",
        type: "Métropole",
    },
    "65": {
        region: "Occitanie",
        department: "Hautes-Pyrénées",
        type: "Métropole",
    },
    "66": {
        region: "Occitanie",
        department: "Pyrénées-Orientales",
        type: "Métropole",
    },
    "67": { region: "Grand Est", department: "Bas-Rhin", type: "Métropole" },
    "68": { region: "Grand Est", department: "Haut-Rhin", type: "Métropole" },
    "69": {
        region: "Auvergne-Rhône-Alpes",
        department: "Rhône",
        type: "Métropole",
    },
    "70": {
        region: "Bourgogne-Franche-Comté",
        department: "Haute-Saône",
        type: "Métropole",
    },
    "71": {
        region: "Bourgogne-Franche-Comté",
        department: "Saône-et-Loire",
        type: "Métropole",
    },
    "72": {
        region: "Pays de la Loire",
        department: "Sarthe",
        type: "Métropole",
    },
    "73": {
        region: "Auvergne-Rhône-Alpes",
        department: "Savoie",
        type: "Métropole",
    },
    "74": {
        region: "Auvergne-Rhône-Alpes",
        department: "Haute-Savoie",
        type: "Métropole",
    },
    "75": { region: "Île-de-France", department: "Paris", type: "Métropole" },
    "76": {
        region: "Normandie",
        department: "Seine-Maritime",
        type: "Métropole",
    },
    "77": {
        region: "Île-de-France",
        department: "Seine-et-Marne",
        type: "Métropole",
    },
    "78": {
        region: "Île-de-France",
        department: "Yvelines",
        type: "Métropole",
    },
    "79": {
        region: "Nouvelle-Aquitaine",
        department: "Deux-Sèvres",
        type: "Métropole",
    },
    "80": { region: "Hauts-de-France", department: "Somme", type: "Métropole" },
    "81": { region: "Occitanie", department: "Tarn", type: "Métropole" },
    "82": {
        region: "Occitanie",
        department: "Tarn-et-Garonne",
        type: "Métropole",
    },
    "83": {
        region: "Provence-Alpes-Côte d'Azur",
        department: "Var",
        type: "Métropole",
    },
    "84": {
        region: "Provence-Alpes-Côte d'Azur",
        department: "Vaucluse",
        type: "Métropole",
    },
    "85": {
        region: "Pays de la Loire",
        department: "Vendée",
        type: "Métropole",
    },
    "86": {
        region: "Nouvelle-Aquitaine",
        department: "Vienne",
        type: "Métropole",
    },
    "87": {
        region: "Nouvelle-Aquitaine",
        department: "Haute-Vienne",
        type: "Métropole",
    },
    "88": { region: "Grand Est", department: "Vosges", type: "Métropole" },
    "89": {
        region: "Bourgogne-Franche-Comté",
        department: "Yonne",
        type: "Métropole",
    },
    "90": {
        region: "Bourgogne-Franche-Comté",
        department: "Territoire de Belfort",
        type: "Métropole",
    },
    "91": { region: "Île-de-France", department: "Essonne", type: "Métropole" },
    "92": {
        region: "Île-de-France",
        department: "Hauts-de-Seine",
        type: "Métropole",
    },
    "93": {
        region: "Île-de-France",
        department: "Seine-Saint-Denis",
        type: "Métropole",
    },
    "94": {
        region: "Île-de-France",
        department: "Val-de-Marne",
        type: "Métropole",
    },
    "95": {
        region: "Île-de-France",
        department: "Val-d'Oise",
        type: "Métropole",
    },
    "971": { region: "Guadeloupe", department: "Guadeloupe", type: "DOM" },
    "972": { region: "Martinique", department: "Martinique", type: "DOM" },
    "973": { region: "Guyane", department: "Guyane", type: "DOM" },
    "974": { region: "La Réunion", department: "La Réunion", type: "DOM" },
    "975": {
        region: "Saint-Pierre-et-Miquelon",
        department: "Saint-Pierre-et-Miquelon",
        type: "TOM",
    },
    "976": { region: "Mayotte", department: "Mayotte", type: "DOM" },
    "977": {
        region: "Saint-Barthélemy",
        department: "Saint-Barthélemy",
        type: "TOM",
    },
    "978": { region: "Saint-Martin", department: "Saint-Martin", type: "TOM" },
    "984": {
        region: "Terres australes et antarctiques françaises",
        department: "Terres australes et antarctiques françaises",
        type: "TOM",
    },
    "986": {
        region: "Wallis-et-Futuna",
        department: "Wallis-et-Futuna",
        type: "TOM",
    },
    "987": {
        region: "Polynésie française",
        department: "Polynésie française",
        type: "TOM",
    },
    "988": {
        region: "Nouvelle-Calédonie",
        department: "Nouvelle-Calédonie",
        type: "TOM",
    },
    "989": {
        region: "Île de Clipperton",
        department: "Île de Clipperton",
        type: "TOM",
    },
};
