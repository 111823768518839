<template>
    <Dialog
        v-model="dialogOpen"
        :title="$t('common.confirmAction')"
        width="35%"
        @submit="confirm"
        @close="cancel"
    >
        <template #text>
            <v-row v-if="text">
                <v-col cols="12" class="align-center pt-0">
                    <Span
                        class="bold red centered-text"
                        style="font-size: 16px"
                        >{{ text }}</Span
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <PopupTextField
                        v-model="password"
                        :type="showCurrentPassword ? 'text' : 'password'"
                        :append-inner-icon="
                            showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append-inner="
                            showCurrentPassword = !showCurrentPassword
                        "
                        :label="$t('common.password')"
                        :rules="[rules.required]"
                    />
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <div class="center-content">
                <v-btn
                    class="button-bg-secondary"
                    type="submit"
                    :loading="isLoading"
                    :disabled="isLoading"
                >
                    <template v-slot:prepend>
                        <v-icon>mdi-check</v-icon>
                    </template>
                    {{ $t("common.confirm") }}
                </v-btn>
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from "@/components/customVuetify/Dialog.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import { useSnackbar } from "@/composables/useSnackbar";
import rules from "@/rules";
import {
    EmailAuthProvider,
    getAuth,
    reauthenticateWithCredential,
} from "firebase/auth";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { Span } from "./customVuetify";

const { t: $t } = useI18n();
const { showSnackbar } = useSnackbar();

const emit = defineEmits(["close", "success"]);

defineProps({
    text: String,
});

const dialogOpen = ref(false);
const password = ref("");
const isLoading = ref(false);
const showCurrentPassword = ref(false);

function cancel() {
    showCurrentPassword.value = false;
    password.value = "";
    emit("close");
}

async function confirm() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user && user.email) {
        const credential = EmailAuthProvider.credential(
            user.email,
            password.value
        );
        try {
            isLoading.value = true;
            await reauthenticateWithCredential(user, credential);
            emit("success");
            emit("close");
        } catch (error) {
            showSnackbar($t("common.wrongPassword"), 3000, "var(--ys-red)");
            emit("close", error);
        } finally {
            isLoading.value = false;
        }
    } else {
        emit("close", new Error("No user is currently signed in."));
    }
}
</script>

<style scoped>
.v-row {
    height: auto !important;
}

.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-text {
    text-align: center;
}
</style>
