<template>
    <AutocompleteField
        v-model="selectedClass"
        :items="filteredClassCodes"
        :item-title="displayText"
        item-value="code"
        :label="$t('orders.class')"
        clearable
    />
</template>
<script setup lang="ts">
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import { Class, ClassCodes } from "@/types";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const props = defineProps({
    groups: {
        type: Array as () => string[],
        default: () => [],
    },
    modelValue: {
        type: Array as () => string[],
        default: () => [],
    },
});

const emit = defineEmits(["update:modelValue", "newval"]);
const selectedClass = ref<string[]>(props.modelValue);

const filteredClassCodes = computed(() => {
    return Object.entries(ClassCodes)
        .filter(([code]) => {
            return props.groups.some((groupPrefix) =>
                code.startsWith(groupPrefix)
            );
        })
        .map(([code, description]) => ({
            code,
            description,
        }));
});

const displayText = computed(
    () => (classobj: Class | null) =>
        classobj ? `${classobj.code} - ${classobj.description}` : ""
);

watch(
    () => props.modelValue,
    (newValue) => {
        selectedClass.value = newValue;
    }
);

watch(
    () => filteredClassCodes.value,
    () => {
        selectedClass.value = selectedClass.value.filter((val) =>
            filteredClassCodes.value.some((classobj) => classobj.code === val)
        );
        emit("newval", selectedClass.value);
    }
);

watch(
    () => selectedClass.value,
    (newValue) => {
        emit("update:modelValue", newValue);
    }
);
</script>
