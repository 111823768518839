<template>
    <v-app-bar height="60" class="top-bar">
        <v-col cols="auto" class="col-logo">
            <v-btn
                to="/home"
                class="logo-button"
                :ripple="false"
                color="transparent"
            >
                <img :src="logo" class="logo" alt="logo" />
                <Span v-if="!logo">{{ $t("common.logoNotFound") }}</Span>
            </v-btn>
        </v-col>
        <v-btn icon @click="$emit('toggleNavbar')">
            <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <Span>{{ $t("clients.client") }} :</Span>
        <v-col cols="2">
            <SelectField
                v-model="currentClient"
                :items="clientList"
                :disableMessage="true"
                :label="$t('clients.client')"
                item-title="name"
                item-value="id"
                return-object
            />
        </v-col>
        <v-btn class="help-button" variant="tonal" @click="helpOpen = !helpOpen"
            >{{ $t("common.reportProblem") }}
        </v-btn>
        <v-tooltip :text="$t('navbar.searchProspect')" location="bottom">
            <template #activator="{ props }">
                <div v-bind="props" ref="targetRef">
                    <v-btn
                        style="margin-left: 1vw"
                        icon="mdi-magnify"
                        color="rgb(var(--v-theme-button))"
                        @click="searchProspect = true"
                    />
                </div>
            </template>
        </v-tooltip>
        <v-btn
            icon
            @click="toggleDarkMode"
            style="margin-left: 1vw; margin-right: 1vw"
        >
            <v-icon class="theme-icon">{{
                isDark ? "mdi-weather-night" : "mdi-white-balance-sunny"
            }}</v-icon>
        </v-btn>
        <div class="date-time">
            <v-toolbar-title class="time">{{ currentTime }}</v-toolbar-title>
            <v-toolbar-title class="date">{{ currentDate }}</v-toolbar-title>
        </div>
        <HelpPopup
            v-model="helpOpen"
            @success="snackbar($t('help.success'))"
            @close="helpOpen = false"
        />
        <Snackbar v-model="showSnackbar" :text="snackbarText" />
    </v-app-bar>
    <SearchProspect v-model="searchProspect" @close="searchProspect = false" />
</template>

<script setup lang="ts">
import { getClientListNameId, getCurrentClient } from "@/commons/firebase";
import HelpPopup from "@/components/HelpPopup.vue";
import Snackbar from "@/components/SnackBar.vue";
import SelectField from "@/components/customVuetify/SelectField.vue";
import { db } from "@/main";
import { store } from "@/store";
import { IdName } from "@/types";
import { doc, onSnapshot } from "firebase/firestore";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import SearchProspect from "./SearchProspect.vue";
import { Span } from "./customVuetify";

const emit = defineEmits(["toggleNavbar", "toggleDark"]);

const currentDate = ref("01/01/1970");
const currentTime = ref("00:00:00");

defineProps({
    navbarOpen: Boolean,
});

const searchProspect = ref(false);

let snackbarText = "";
const showSnackbar = ref(false);
const clientList = ref<IdName[]>([]);
const currentClient = ref<IdName | null>(null);

function snackbar(text: string) {
    showSnackbar.value = true;
    snackbarText = text;
}

const helpOpen = ref(false);

const logo = ref("");

let intervalId: any;

const isDark = computed(() => store.state.theme === "dark");

const toggleDarkMode = () => {
    emit("toggleDark");
};

const getClientLogo = () => {
    const client = getCurrentClient();
    if (client.value.id) {
        const clientDoc = doc(db, "Clients", client.value.id);
        onSnapshot(clientDoc, (clientSnapshot) => {
            if (clientSnapshot.exists()) {
                const clientInfos = clientSnapshot.data();
                logo.value = clientInfos.LogoUrl;
            }
        });
    }
};

watch(
    () => store.state.currentClient,
    (newClient) => {
        if (newClient) {
            currentClient.value = newClient as IdName;
            getClientLogo();
        }
    }
);

watch(currentClient, (newClient: any) => {
    if (newClient) {
        store.commit("setClient", newClient);
    }
});

onMounted(async () => {
    clientList.value = await getClientListNameId();
    if (store.state.currentClient) {
        currentClient.value = store.state.currentClient as IdName;
    }

    const date = new Date();
    currentDate.value = date.toLocaleDateString();
    currentTime.value = date.toLocaleTimeString();
    intervalId = setInterval(() => {
        const date = new Date();
        currentDate.value = date.toLocaleDateString();
        currentTime.value = date.toLocaleTimeString();
    }, 1000);
    getClientLogo();
});

onUnmounted(() => {
    clearInterval(intervalId);
});
</script>

<style scoped>
.help-button {
    color: rgb(var(--v-theme-button)) !important;
    border-color: rgb(var(--v-theme-button)) !important;
    font-weight: bold;
}
.theme-icon {
    color: rgb(var(--v-theme-button)) !important;
}
.col-logo {
    padding-left: 4.5em;
    padding-right: 4em;
}

.logo {
    width: 10em;
    height: auto;
    top: 50%;
}

.logo-button {
    padding: 0;
}

.top-bar {
    color: rgb(var(--v-theme-text)) !important;
    background-color: rgb(var(--v-theme-bar)) !important;
    box-shadow: 0 1px 4px -1px rgb(var(--v-theme-text)) !important;
}

.date-time {
    padding-right: 1vw;
    text-align: center;
}

.time {
    font-size: 0.9rem;
    margin-bottom: -0.5em;
    color: rgb(var(--v-theme-text)) !important;
}

.date {
    font-size: 0.9rem;
    color: rgb(var(--v-theme-text)) !important;
}
</style>
