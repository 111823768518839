<template>
    <v-row>
        <v-col
            :cols="12 - Number(fieldSize)"
            :class="{
                'no-top-padding': noTopPadding || pbt0,
                'no-bottom-padding': noBottomPadding || pbt0,
                'd-flex': link,
                'justify-space-between': link,
                'align-center': link,
                'margin-bottom': !disableMessage,
            }"
        >
            <Span class="label-Span">{{ $t(label) }}</Span>
            <div class="d-flex justify-end">
                <v-btn
                    v-if="link"
                    :href="formattedLink"
                    target="_blank"
                    small
                    icon
                    flat
                >
                    <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
            </div>
        </v-col>
        <v-col
            :cols="fieldSize"
            :class="{
                'no-top-padding': noTopPadding || pbt0,
                'no-bottom-padding': noBottomPadding || pbt0,
            }"
        >
            <TextField
                v-if="!textArea"
                v-bind="$attrs"
                @input="updateValue"
                :label="$t(label)"
                :disableMessage="disableMessage"
                :readonly="readonly"
            />
            <TextArea
                v-if="textArea"
                v-bind="$attrs"
                @input="updateValue"
                :label="$t(label)"
                :disableMessage="disableMessage"
                :readonly="readonly"
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { Span, TextArea, TextField } from "@/components/customVuetify";
import { computed } from "vue";

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    noTopPadding: {
        type: Boolean,
        default: false,
    },
    noBottomPadding: {
        type: Boolean,
        default: false,
    },
    pbt0: {
        type: Boolean,
        default: true,
    },
    textArea: {
        type: Boolean,
        default: false,
    },
    fieldSize: {
        type: String,
        default: "6",
    },
    link: {
        type: String,
        default: "",
    },
    disableMessage: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["update:modelValue"]);

const updateValue = (event: InputEvent) => {
    emit("update:modelValue", (event.target as HTMLInputElement).value);
};

const formattedLink = computed(() => {
    if (!props.link) return "";
    return props.link.startsWith("http://") || props.link.startsWith("https://")
        ? props.link
        : `https://${props.link}`;
});
</script>

<style scoped>
.no-top-padding {
    padding-top: 0 !important;
}

.no-bottom-padding {
    padding-bottom: 0 !important;
}

.margin-bottom {
    margin-bottom: 22px;
}

.v-row {
    height: auto !important;
}
</style>
