import { Timestamp } from "firebase/firestore";

export interface Call {
    AppointmentDate: Date | null;
    AppointmentAddress: string;
    AssociateId: string;
    CallAgainDate: Date | null;
    ClientId: string;
    Comments: string;
    Date: Date;
    DecisionMaker: boolean | null;
    EmployeeId: string;
    FirstCall: boolean;
    Salutation: Salutations;
    FirstName: string;
    LastName: string;
    ProspectInterest: ProspectInterest;
    OrderId: string;
    Mail: string;
    NextAction: NextAction;
    Phone: string;
    PickedUp: boolean | null;
    Position: string;
    RdvType: number;
    Salesman: string;
    SendMail: boolean;
    DateToBeDefined: boolean;
}

export type CallHistory = {
    id: number;
    pickedUp: boolean;
    decisionMaker: boolean;
    comments: string;
    employeeId: string;
    nextAction: string;
    date: Timestamp;
};

export enum Salutations {
    Mr = 1,
    Mrs = 2,
}

export enum NextAction {
    call = 1,
    meeting = 2,
    mail = 3,
    ko = 4,
}

export enum ProspectInterest {
    notInterested = 1,
    interested = 2,
    veryInterested = 3,
}

export const interestColors = ["#ff8c3b", "#919191", "#0dff00"];

export const rdvTypes: { [key: number]: string } = {
    1: "Téléphone",
    2: "Visio",
    3: "Présentiel",
};

export const defaultCallData: Call = {
    AppointmentDate: null,
    AppointmentAddress: "",
    AssociateId: "",
    CallAgainDate: null,
    ClientId: "",
    Comments: "",
    Date: new Date(),
    DecisionMaker: null,
    EmployeeId: "",
    FirstCall: false,
    Salutation: 0 as Salutations,
    FirstName: "",
    ProspectInterest: 0 as ProspectInterest,
    LastName: "",
    Mail: "",
    NextAction: 0 as NextAction,
    OrderId: "",
    Phone: "",
    PickedUp: null,
    Position: "",
    RdvType: null as unknown as number,
    Salesman: "",
    SendMail: false,
    DateToBeDefined: false,
};
