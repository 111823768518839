<template>
    <Dialog
        v-model="isConsigneCreationVisible"
        :title="$t('other.SelectClientSpace')"
        width="40vw"
        :closable="false"
        @submit="setNewClient()"
        @close="emit('close')"
    >
        <template #text>
            <SelectField
                v-model="currentClient"
                :items="clientList"
                :disableMessage="true"
                :label="$t('clients.client')"
                item-title="name"
                item-value="id"
                return-object
            />
        </template>
        <template #actions>
            <v-row justify="center">
                <v-btn class="button-bg-secondary mt-4" type="submit">
                    {{ $t("common.validate") }}
                </v-btn>
            </v-row>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { getClientListNameId } from "@/commons/firebase";
import SelectField from "@/components/customVuetify/SelectField.vue";
import { store } from "@/store";
import { IdName } from "@/types";
import { onMounted, ref } from "vue";
import Dialog from "./customVuetify/Dialog.vue";

defineProps({
    navbarOpen: Boolean,
});

const emit = defineEmits(["close"]);

const clientList = ref<IdName[]>([]);
const currentClient = ref<IdName | null>(null);
const isConsigneCreationVisible = ref(true);

function setNewClient() {
    store.commit("setClient", currentClient.value);
    store.commit("setHasSeenPopup", true);
    emit("close");
}

onMounted(async () => {
    clientList.value = await getClientListNameId();
    if (store.state.currentClient) {
        currentClient.value = store.state.currentClient as IdName;
    }
});
</script>

<style scoped></style>
