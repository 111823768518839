<template>
    <AutocompleteField
        v-model="selectedSection"
        :items="sectionCodes"
        :item-title="displayText"
        item-value="code"
        :label="$t('orders.section')"
        clearable
    />
</template>

<script setup lang="ts">
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import { Section, SectionCodes } from "@/types";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const selectedSection = ref<string | null>(null);

const sectionCodes: Section[] = Object.entries(SectionCodes).map(
    ([code, description]) => ({
        code,
        description,
    })
);

const displayText = computed(
    () => (section: Section | null) =>
        section ? `${section.code} - ${section.description}` : ""
);
</script>
