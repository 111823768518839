export const Sizes = [
    { name: "0 salarié", id: "00" },
    { name: "1 à 2 salariés", id: "01" },
    { name: "3 à 5 salariés", id: "02" },
    { name: "6 à 9 salariés", id: "03" },
    { name: "10 à 19 salariés", id: "11" },
    { name: "20 à 49 salariés", id: "12" },
    { name: "50 à 99 salariés", id: "21" },
    { name: "100 à 199 salariés", id: "22" },
    { name: "200 à 249 salariés", id: "31" },
    { name: "250 à 499 salariés", id: "32" },
    { name: "500 à 999 salariés", id: "41" },
    { name: "1000 à 1999 salariés", id: "42" },
    { name: "2000 à 4999 salariés", id: "51" },
    { name: "5000 à 9999 salariés", id: "52" },
    { name: "10 000 salariés et plus", id: "53" },
    { name: "NC", id: "NN" },
];

export const MunicipalitySizes = [
    { name: "De 0 à 200 habitants", id: "M01" },
    { name: "De 201 à 500 habitants", id: "M02" },
    { name: "De 501 à 2000 habitants", id: "M03" },
    { name: "De 2001 à 5000 habitants", id: "M11" },
    { name: "De 5001 à 10 000 habitants", id: "M12" },
    { name: "De 10 001 à 20 000 habitants", id: "M21" },
    { name: "De 20 001 à 50 000 habitants", id: "M22" },
    { name: "De 50 001 à 100 000 habitants", id: "M23" },
    { name: "100 000 habitants ou +", id: "M31" },
    { name: "NC", id: "NN" },
];
