<template>
    <List
        :items="clients"
        :headers="headers"
        :showButton="true"
        :sort-by="[{ key: 'Name', order: 'asc' }]"
        @rowClicked="openEdit"
    >
        <template #button="{ item }">
            <v-btn
                class="button-bg-white edit-button"
                icon="mdi-pencil-outline"
                @click.stop="openEdit(item)"
            />
        </template>
    </List>
    <EditAssignments
        v-model="editAssignmentDialog"
        :clientId="selectedClientId"
        @close="editAssignmentDialog = false"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { Client } from "@/types";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import EditAssignments from "./EditAssignments.vue";

const { t: $t } = useI18n();

defineProps({
    clients: Array as () => Client[] | DocumentData[],
});

const editAssignmentDialog = ref(false);
const selectedClientId = ref("");

function openEdit(item: any) {
    editAssignmentDialog.value = true;
    selectedClientId.value = item.id;
}

const headers = [
    { title: $t("teams.name"), value: "Name" },
    { title: $t("teams.address"), value: "Address" },
    { title: $t("teams.postalCode"), value: "PostalCode" },
    { title: $t("teams.city"), value: "City" },
    { title: $t("teams.department"), value: "Department" },
    { title: $t("teams.region"), value: "Region" },
    { title: $t("teams.country"), value: "Country" },
    { title: $t("teams.apeCode"), value: "APECode" },
    { title: $t("teams.size"), value: "Size" },
    { title: $t("teams.assignments"), value: "Assignments" },
];
</script>

<style scoped>
.edit-button {
    height: 38px !important;
    width: 38px !important;
    background-color: transparent !important;
    border: transparent !important;
}
</style>
