<template>
    <Dialog
        v-model="createUserOpen"
        :title="$t('users.add')"
        width="50em"
        @submit="createUser"
        @close="close"
    >
        <template #text>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="user.LastName"
                        :label="$t('users.lastname')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="user.FirstName"
                        :label="$t('users.firstname')"
                        :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="user.Phone"
                        :label="$t('users.phone')"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <PopupTextField
                        v-model="user.Mail"
                        :label="$t('users.email')"
                        :rules="[rules.required, rules.email]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupTextField
                        v-model="password"
                        :label="$t('users.password')"
                        :rules="[rules.required, rules.password]"
                    />
                </v-col>
                <v-col cols="4">
                    <PopupAutocompleteField
                        v-model="selectedClient"
                        :items="clients"
                        :label="$t('users.client')"
                        :rules="[rules.required]"
                        :disabled="!isToviaUser"
                        item-title="name"
                        item-value="id"
                        return-object
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <Switch
                        v-model="isAdmin"
                        :label="$t('users.isAdmin')"
                        @change="
                            isSupervisor = false;
                            isOperator = false;
                        "
                    />
                </v-col>
                <v-col cols="4" class="d-flex align-center">
                    <Switch
                        v-model="isSupervisor"
                        :label="$t('users.isSupervisor')"
                        @change="
                            isAdmin = false;
                            isOperator = false;
                        "
                    />
                </v-col>
                <v-col cols="4">
                    <Switch
                        v-model="isOperator"
                        :label="$t('users.isOperator')"
                        @change="
                            isAdmin = false;
                            isSupervisor = false;
                        "
                    />
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <v-spacer></v-spacer>
            <Span class="error--text">{{ roleError }}</Span>
            <v-btn
                class="button-bg-secondary"
                type="submit"
                :loading="isLoading"
                :disabled="isLoading"
            >
                <template v-slot:prepend> <v-icon>mdi-check</v-icon> </template
                >{{ $t("common.create") }}</v-btn
            >
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import {
    getClientList,
    getCurrentUser,
    getCurrentUserClientNameId,
    isTovia,
} from "@/commons/firebase";
import { Span } from "@/components/customVuetify";
import PopupAutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import Switch from "@/components/customVuetify/Switch.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import { db } from "@/main";
import rules from "@/rules";
import { IdName, UserDB } from "@/types";
import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const emit = defineEmits(["close"]);

const roleError = ref("");
const createUserOpen = ref(false);
const password = ref("");
const isAdmin = ref(false);
const isSupervisor = ref(false);
const isOperator = ref(false);

const user = ref<Partial<UserDB>>({
    AssignedClients: [],
    LastName: "",
    FirstName: "",
    Phone: "",
    Mail: "",
    Role: 0 as number,
    SuperiorId: "",
    FirebaseUID: "",
    ClientId: "",
});

const isLoading = ref(false);

const clients = ref<IdName[]>([]);
const selectedClient = ref<IdName | null>(null);
const isToviaUser = ref(false);

watch(
    () => selectedClient.value,
    (newVal) => {
        if (newVal) {
            user.value.ClientId = newVal.id;
        }
    }
);

onMounted(async () => {
    isToviaUser.value = await isTovia();
    const clientList = await getClientList();
    clients.value = clientList.map((client) => ({
        id: client.DocId,
        name: client.Name,
    }));
    const client = await getCurrentUserClientNameId();
    if (client) {
        selectedClient.value = {
            id: client.DocId,
            name: client.Name,
        };
    }
});

async function createUser() {
    if (!isAdmin.value && !isSupervisor.value && !isOperator.value) {
        roleError.value = $t("users.roleError");
        return;
    }
    isLoading.value = true;
    try {
        const auth = getAuth();
        if (!auth.currentUser) {
            throw new Error("User not authenticated");
        }
        const idToken = await getIdToken(auth.currentUser);

        const userData = {
            email: user.value.Mail,
            password: password.value,
            claims: {
                admin: isAdmin.value,
                supervisor: isSupervisor.value,
                operator: isOperator.value,
                firstLogin: true,
            },
        };
        const response = await axios.post(
            "https://europe-west1-youscalesaas.cloudfunctions.net/createUserHttp",
            userData,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            }
        );

        if (isAdmin.value) {
            user.value.Role = 1;
        } else if (isSupervisor.value) {
            user.value.Role = 2;
        } else if (isOperator.value) {
            user.value.Role = 3;
        }
        const userId = response.data.uid;
        user.value.FirebaseUID = userId;

        if (user.value.Role != 1) {
            const currentUser = await getCurrentUser();
            user.value.SuperiorId = currentUser.DocId;
        }
        user.value.ClientId
            ? (user.value.AssignedClients = [user.value.ClientId])
            : 0;

        const userDoc = doc(db, "Users", userId);
        await setDoc(userDoc, user.value);

        clearUserInfos();
        emit("close");
    } catch (error) {
        console.error("Error creating user: ", error);
    } finally {
        isLoading.value = false;
    }
}

function clearUserInfos() {
    user.value = {
        LastName: "",
        FirstName: "",
        Phone: "",
        Mail: "",
        Role: 0 as number,
        SuperiorId: "",
        FirebaseUID: "",
        ClientId: "",
    };
    password.value = "";
    isAdmin.value = false;
    isSupervisor.value = false;
    isOperator.value = false;
    roleError.value = "";
}

function close() {
    clearUserInfos();
    emit("close");
}
</script>

<style scoped>
.error--text {
    color: #f44336;
    padding-right: 1em;
}
</style>
