export interface Group {
    code: string;
    description: string;
}

export const GroupCodes = {
    "01.1": "Cultures non permanentes",
    "01.2": "Cultures permanentes",
    "01.3": "Reproduction de plantes",
    "01.4": "Production animale",
    "01.5": "Culture et élevage associés",
    "01.6": "Activités de soutien à l'agriculture et traitement primaire des récoltes",
    "01.7": "Chasse, piégeage et services annexes",
    "02.1": "Sylviculture et autres activités forestières",
    "02.2": "Exploitation forestière",
    "02.3": "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
    "02.4": "Services de soutien à l'exploitation forestière",
    "03.1": "Pêche",
    "03.2": "Aquaculture",
    "05.1": "Extraction de houille",
    "05.2": "Extraction de lignite",
    "06.1": "Extraction de pétrole brut",
    "06.2": "Extraction de gaz naturel",
    "07.1": "Extraction de minerais de fer",
    "07.2": "Extraction de minerais de métaux non ferreux",
    "08.1": "Extraction de pierres, de sables et d'argiles",
    "08.9": "Activités extractives n.c.a.",
    "09.1": "Activités de soutien à l'extraction d'hydrocarbures",
    "09.9": "Activités de soutien aux autres industries extractives",
    "10.1": "Transformation et conservation de la viande et préparation de produits à base de viande",
    "10.2": "Transformation et conservation de poisson, de crustacés et de mollusques",
    "10.3": "Transformation et conservation de fruits et légumes",
    "10.4": "Fabrication d'huiles et graisses végétales et animales",
    "10.5": "Fabrication de produits laitiers",
    "10.6": "Travail des grains ; fabrication de produits amylacés",
    "10.7": "Fabrication de produits de boulangerie-pâtisserie et de pâtes alimentaires",
    "10.8": "Fabrication d'autres produits alimentaires",
    "10.9": "Fabrication d'aliments pour animaux",
    "11.0": "Fabrication de boissons",
    "12.0": "Fabrication de produits à base de tabac",
    "13.1": "Préparation de fibres textiles et filature",
    "13.2": "Tissage",
    "13.3": "Ennoblissement textile",
    "13.9": "Fabrication d'autres textiles",
    "14.1": "Fabrication de vêtements, autres qu'en fourrure",
    "14.2": "Fabrication d'articles en fourrure",
    "14.3": "Fabrication d'articles à mailles",
    "15.1": "Apprêt et tannage des cuirs ; préparation et teinture des fourrures ; fabrication d'articles de voyage, de maroquinerie et de sellerie",
    "15.2": "Fabrication de chaussures",
    "16.1": "Sciage et rabotage du bois",
    "16.2": "Fabrication d'articles en bois, liège, vannerie et sparterie",
    "17.1": "Fabrication de pâte à papier, de papier et de carton",
    "17.2": "Fabrication d'articles en papier ou en carton",
    "18.1": "Imprimerie et services annexes",
    "18.2": "Reproduction d'enregistrements",
    "19.1": "Cokéfaction",
    "19.2": "Raffinage du pétrole",
    "20.1": "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
    "20.2": "Fabrication de pesticides et d'autres produits agrochimiques",
    "20.3": "Fabrication de peintures, vernis, encres et mastics",
    "20.4": "Fabrication de savons, de produits d'entretien et de parfums",
    "20.5": "Fabrication d'autres produits chimiques",
    "20.6": "Fabrication de fibres artificielles ou synthétiques",
    "21.1": "Fabrication de produits pharmaceutiques de base",
    "21.2": "Fabrication de préparations pharmaceutiques",
    "22.1": "Fabrication de produits en caoutchouc",
    "22.2": "Fabrication de produits en plastique",
    "23.1": "Fabrication de verre et d'articles en verre",
    "23.2": "Fabrication de produits réfractaires",
    "23.3": "Fabrication de matériaux de construction en terre cuite",
    "23.4": "Fabrication d'autres produits en céramique et en porcelaine",
    "23.5": "Fabrication de ciment, chaux et plâtre",
    "23.6": "Fabrication d'ouvrages en béton, en ciment ou en plâtre",
    "23.7": "Taille, façonnage et finissage de pierres",
    "23.9": "Fabrication de produits abrasifs et de produits minéraux non métalliques n.c.a.",
    "24.1": "Sidérurgie",
    "24.2": "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier",
    "24.3": "Fabrication d'autres produits de première transformation de l'acier",
    "24.4": "Production de métaux précieux et d'autres métaux non ferreux",
    "24.5": "Fonderie",
    "25.1": "Fabrication d'éléments en métal pour la construction",
    "25.2": "Fabrication de réservoirs, citernes et conteneurs métalliques",
    "25.3": "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
    "25.4": "Fabrication d'armes et de munitions",
    "25.5": "Forge, emboutissage, estampage ; métallurgie des poudres",
    "25.6": "Traitement et revêtement des métaux ; usinage",
    "25.7": "Fabrication de coutellerie, d'outillage et de quincaillerie",
    "25.9": "Fabrication d'autres ouvrages en métaux",
    "26.1": "Fabrication de composants et cartes électroniques",
    "26.2": "Fabrication d'ordinateurs et d'équipements périphériques",
    "26.3": "Fabrication d'équipements de communication",
    "26.4": "Fabrication de produits électroniques grand public",
    "26.5": "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation ; horlogerie",
    "26.6": "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
    "26.7": "Fabrication de matériels optique et photographique",
    "26.8": "Fabrication de supports magnétiques et optiques",
    "27.1": "Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique",
    "27.2": "Fabrication de piles et d'accumulateurs électriques",
    "27.3": "Fabrication de fils et câbles et de matériel d'installation électrique",
    "27.4": "Fabrication d'appareils d'éclairage électrique",
    "27.5": "Fabrication d'appareils ménagers",
    "27.9": "Fabrication d'autres matériels électriques",
    "28.1": "Fabrication de machines d'usage général",
    "28.2": "Fabrication d'autres machines d'usage général",
    "28.3": "Fabrication de machines agricoles et forestières",
    "28.4": "Fabrication de machines de formage des métaux et de machines-outils",
    "28.9": "Fabrication d'autres machines d'usage spécifique",
    "29.1": "Construction de véhicules automobiles",
    "29.2": "Fabrication de carrosseries et remorques",
    "29.3": "Fabrication d'équipements automobiles",
    "30.1": "Construction navale",
    "30.2": "Construction de locomotives et d'autre matériel ferroviaire roulant",
    "30.3": "Construction aéronautique et spatiale",
    "30.4": "Construction de véhicules militaires de combat",
    "30.9": "Fabrication de matériels de transport n.c.a.",
    "31.0": "Fabrication de meubles",
    "32.1": "Fabrication d'articles de joaillerie, bijouterie et articles similaires",
    "32.2": "Fabrication d'instruments de musique",
    "32.3": "Fabrication d'articles de sport",
    "32.4": "Fabrication de jeux et jouets",
    "32.5": "Fabrication d'instruments et de fournitures à usage médical et dentaire",
    "32.9": "Activités manufacturières n.c.a.",
    "33.1": "Réparation d'ouvrages en métaux, de machines et d'équipements",
    "33.2": "Installation de machines et d'équipements industriels",
    "35.1": "Production, transport et distribution d'électricité",
    "35.2": "Production et distribution de combustibles gazeux",
    "35.3": "Production et distribution de vapeur et d'air conditionné",
    "36.0": "Captage, traitement et distribution d'eau",
    "37.0": "Collecte et traitement des eaux usées",
    "38.1": "Collecte des déchets",
    "38.2": "Traitement et élimination des déchets",
    "38.3": "Récupération",
    "39.0": "Dépollution et autres services de gestion des déchets",
    "41.1": "Promotion immobilière",
    "41.2": "Construction de bâtiments résidentiels et non résidentiels",
    "42.1": "Construction de routes et de voies ferrées",
    "42.2": "Construction de réseaux et de lignes",
    "42.9": "Construction d'autres ouvrages de génie civil",
    "43.1": "Démolition et préparation des sites",
    "43.2": "Travaux d'installation électrique, plomberie et autres travaux d'installation",
    "43.3": "Travaux de finition",
    "43.9": "Autres travaux de construction spécialisés",
    "45.1": "Commerce de véhicules automobiles",
    "45.2": "Entretien et réparation de véhicules automobiles",
    "45.3": "Commerce d'équipements automobiles",
    "45.4": "Commerce et réparation de motocycles",
    "46.1": "Intermédiaires du commerce de gros",
    "46.2": "Commerce de gros de produits agricoles bruts et d'animaux vivants",
    "46.3": "Commerce de gros de produits alimentaires, de boissons et de tabac",
    "46.4": "Commerce de gros de biens domestiques",
    "46.5": "Commerce de gros d'équipements de l'information et de la communication",
    "46.6": "Commerce de gros d'autres équipements industriels",
    "46.7": "Autres commerces de gros spécialisés",
    "46.9": "Commerce de gros non spécialisé",
    "47.1": "Commerce de détail en magasin non spécialisé",
    "47.2": "Commerce de détail alimentaire en magasin spécialisé",
    "47.3": "Commerce de détail de carburants en magasin spécialisé",
    "47.4": "Commerce de détail d'équipements de l'information et de la communication en magasin spécialisé",
    "47.5": "Commerce de détail d'autres équipements du foyer en magasin spécialisé",
    "47.6": "Commerce de détail de biens culturels et de loisirs en magasin spécialisé",
    "47.7": "Autres commerces de détail en magasin spécialisé",
    "47.8": "Commerce de détail sur éventaires et marchés",
    "47.9": "Commerce de détail hors magasin, éventaires ou marchés",
    "49.1": "Transport ferroviaire interurbain de voyageurs",
    "49.2": "Transports ferroviaires de fret",
    "49.3": "Autres transports terrestres de voyageurs",
    "49.4": "Transports routiers de fret et services de déménagement",
    "49.5": "Transports par conduites",
    "50.1": "Transports maritimes et côtiers de passagers",
    "50.2": "Transports maritimes et côtiers de fret",
    "50.3": "Transports fluviaux de passagers",
    "50.4": "Transports fluviaux de fret",
    "51.1": "Transports aériens de passagers",
    "51.2": "Transports aériens de fret et transports spatiaux",
    "52.1": "Entreposage et stockage",
    "52.2": "Services auxiliaires des transports",
    "53.1": "Activités de poste dans le cadre d'une obligation de service universel",
    "53.2": "Autres activités de poste et de courrier",
    "55.1": "Hôtels et hébergement similaire",
    "55.2": "Hébergement touristique et autre hébergement de courte durée",
    "55.3": "Terrains de camping et parcs pour caravanes ou véhicules de loisirs",
    "55.9": "Autres hébergements",
    "56.1": "Restaurants et services de restauration mobile",
    "56.2": "Traiteurs et autres services de restauration",
    "56.3": "Débits de boissons",
    "58.1": "Édition de livres et périodiques et autres activités d'édition",
    "58.2": "Édition de logiciels",
    "59.1": "Activités cinématographiques, vidéo et de télévision",
    "59.2": "Enregistrement sonore et édition musicale",
    "60.1": "Édition et diffusion de programmes radio",
    "60.2": "Programmation de télévision et télédiffusion",
    "61.1": "Télécommunications filaires",
    "61.2": "Télécommunications sans fil",
    "61.3": "Télécommunications par satellite",
    "61.9": "Autres activités de télécommunication",
    "62.0": "Programmation, conseil et autres activités informatiques",
    "63.1": "Traitement de données, hébergement et activités connexes ; portails Internet",
    "63.9": "Autres services d'information",
    "64.1": "Intermédiation monétaire",
    "64.2": "Activités des sociétés holding",
    "64.3": "Fonds de placement et entités financières similaires",
    "64.9": "Autres activités des services financiers, hors assurance et caisses de retraite",
    "65.1": "Assurance",
    "65.2": "Réassurance",
    "65.3": "Caisses de retraite",
    "66.1": "Activités auxiliaires de services financiers, hors assurance et caisses de retraite",
    "66.2": "Activités auxiliaires d'assurance et de caisses de retraite",
    "66.3": "Gestion de fonds",
    "68.1": "Activités des marchands de biens immobiliers",
    "68.2": "Location et exploitation de biens immobiliers propres ou loués",
    "68.3": "Activités immobilières pour compte de tiers",
    "69.1": "Activités juridiques",
    "69.2": "Activités comptables",
    "70.1": "Activités des sièges sociaux",
    "70.2": "Conseil de gestion",
    "71.1": "Activités d'architecture et d'ingénierie",
    "71.2": "Activités de contrôle et analyses techniques",
    "72.1": "Recherche-développement en sciences physiques et naturelles",
    "72.2": "Recherche-développement en sciences humaines et sociales",
    "73.1": "Publicité",
    "73.2": "Études de marché et sondages",
    "74.1": "Activités spécialisées de design",
    "74.2": "Activités photographiques",
    "74.3": "Traduction et interprétation",
    "74.9": "Autres activités spécialisées, scientifiques et techniques n.c.a.",
    "75.0": "Activités vétérinaires",
    "77.1": "Location et location-bail de véhicules automobiles",
    "77.2": "Location et location-bail de biens personnels et domestiques",
    "77.3": "Location et location-bail d'autres machines, équipements et biens",
    "77.4": "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
    "78.1": "Activités des agences de placement de main-d'œuvre",
    "78.2": "Activités des agences de travail temporaire",
    "78.3": "Autre mise à disposition de ressources humaines",
    "79.1": "Activités des agences de voyage et voyagistes",
    "79.9": "Autres services de réservation et activités connexes",
    "80.1": "Activités de sécurité privée",
    "80.2": "Activités liées aux systèmes de sécurité",
    "80.3": "Activités d'enquête",
    "81.1": "Activités combinées de soutien lié aux bâtiments",
    "81.2": "Activités de nettoyage",
    "81.3": "Services d'aménagement paysager",
    "82.1": "Activités administratives",
    "82.2": "Activités de centres d'appels",
    "82.3": "Organisation de salons professionnels et congrès",
    "82.9": "Activités de soutien aux entreprises n.c.a.",
    "84.1": "Administration générale, économique et sociale",
    "84.2": "Services de prérogative publique",
    "84.3": "Sécurité sociale obligatoire",
    "85.1": "Enseignement pré-primaire",
    "85.2": "Enseignement primaire",
    "85.3": "Enseignement secondaire",
    "85.4": "Enseignement supérieur et post-secondaire non supérieur",
    "85.5": "Autres activités d'enseignement",
    "85.6": "Activités de soutien à l'enseignement",
    "86.1": "Activités hospitalières",
    "86.2": "Activité des médecins et des dentistes",
    "86.9": "Autres activités pour la santé humaine",
    "87.1": "Hébergement médicalisé",
    "87.2": "Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes",
    "87.3": "Hébergement social pour personnes âgées ou handicapées physiques",
    "87.9": "Autres activités d'hébergement social",
    "88.1": "Action sociale sans hébergement pour personnes âgées et pour personnes handicapées",
    "88.9": "Autre action sociale sans hébergement",
    "90.0": "Activités créatives, artistiques et de spectacle",
    "91.0": "Bibliothèques, archives, musées et autres activités culturelles",
    "92.0": "Organisation de jeux de hasard et d'argent",
    "93.1": "Activités liées au sport",
    "93.2": "Activités récréatives et de loisirs",
    "94.1": "Activités des organisations économiques, patronales et professionnelles",
    "94.2": "Activités des syndicats de salariés",
    "94.9": "Activités des autres organisations associatives",
    "95.1": "Réparation d'ordinateurs et d'équipements de communication",
    "95.2": "Réparation de biens personnels et domestiques",
    "96.0": "Autres services personnels",
    "97.0": "Activités des ménages en tant qu'employeurs de personnel domestique",
    "98.1": "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre",
    "98.2": "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre",
    "99.0": "Activités des organisations et organismes extraterritoriaux",
};
