<template>
    <AutocompleteField
        v-model="selectedGroup"
        :items="filteredGroupCodes"
        :item-title="displayText"
        item-value="code"
        :label="$t('orders.group')"
        clearable
    />
</template>
<script setup lang="ts">
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import { Group, GroupCodes } from "@/types";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const props = defineProps({
    divisions: {
        type: Array as () => string[],
        default: () => [],
    },
    modelValue: {
        type: Array as () => string[],
        default: () => [],
    },
});

const emit = defineEmits(["update:modelValue", "newval"]);
const selectedGroup = ref<string[]>(props.modelValue);

const filteredGroupCodes = computed(() => {
    return Object.entries(GroupCodes)
        .filter(([code]) => {
            const divisionPart = code.split(".")[0];
            return props.divisions?.includes(divisionPart);
        })
        .map(([code, description]) => ({
            code,
            description,
        }));
});

const displayText = computed(
    () => (group: Group | null) =>
        group ? `${group.code} - ${group.description}` : ""
);

watch(
    () => props.modelValue,
    (newValue) => {
        selectedGroup.value = newValue;
    }
);

watch(
    () => filteredGroupCodes.value,
    () => {
        selectedGroup.value = selectedGroup.value.filter((val) =>
            filteredGroupCodes.value.some((classobj) => classobj.code === val)
        );
        emit("newval", selectedGroup.value);
    }
);

watch(
    () => selectedGroup.value,
    (newValue) => {
        emit("update:modelValue", newValue);
    }
);
</script>
