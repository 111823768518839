<template>
    <div class="container">
        <Title :title="`${$t('db.prospectList')} | ${orderName}`" />
        <Filters
            @applyFilters="applyFilters"
            @createProspect="createProspectDialog = true"
            :prospectsNumber="filteredProspects.length"
        />
        <List :prospects="filteredProspects" :orderId="orderId" />
    </div>
    <CreateProspect
        v-model="createProspectDialog"
        :orderId="orderId"
        @close="createProspectDialog = false"
    />
</template>

<script setup lang="ts">
import {
    formatPrice,
    getDepartmentNumberFromPostalCode,
} from "@/commons/convertion";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { store } from "@/store";
import { IdName, Prospect, departmentRegionTypeMapping } from "@/types";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import {
    computed,
    onBeforeMount,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import CreateProspect from "./CreateProspect.vue";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const prospects = ref<Prospect[]>([]);
const currentClient = ref<IdName | null>(null);
const filters = ref({});
const route = useRoute();
const orderId = route.params.id as string;
const router = useRouter();
const orderName = ref("");

const createProspectDialog = ref(false);

const filteredProspects = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return prospects.value;
    }
    return prospects.value.filter((client: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                client[key] &&
                typeof client[key] === "string"
            ) {
                return client[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

async function getOrderName() {
    if (orderId) {
        const orderDoc = doc(db, "Orders", orderId);
        await getDoc(orderDoc).then((doc) => {
            if (doc.exists()) {
                orderName.value = doc.data().Name;
            }
        });
    }
}

function setupFirestoreListener() {
    prospects.value = [];
    if (!currentClient.value) return;
    const prospectsCollection = collection(db, "Orders", orderId, "Prospects");
    const q = query(
        prospectsCollection,
        where("ClientId", "==", currentClient.value.id)
    );

    onSnapshot(q, (querySnapshot) => {
        const orderProspects = querySnapshot.docs.map((doc) => {
            const parentDocument = doc.ref.parent.parent;
            return {
                DocId: doc.id,
                OrderId: parentDocument ? parentDocument.id : null,
                Department: departmentRegionTypeMapping[
                    getDepartmentNumberFromPostalCode(doc.data().PostalCode)
                ]
                    ? departmentRegionTypeMapping[
                          getDepartmentNumberFromPostalCode(
                              doc.data().PostalCode
                          )
                      ].department
                    : "Error",
                ...doc.data(),
                Turnover: doc.data().Turnover
                    ? formatPrice(doc.data().Turnover)
                    : null,
            } as any;
        });
        prospects.value = [...orderProspects];
    });
}

// function freeUserAffectToOrder() {
//     store.state.selectedDepartmentIds
//     if (orderId && currentClient.value) {
//         const orderDoc = doc(db, "Orders", orderId);
//         const orderSnapshot = getDoc(orderDoc);
//         if (orderSnapshot.exists()) {
//             const orderData = orderSnapshot.data();
//             if (orderData.ClientId === currentClient.value.id) {
//                 store.commit("setSelectedDepartmentIds", []);
//             }
//         }
//     }
// }

async function updateEmployeeIDs() {
    const selectedDepartmentIds = store.state.selectedDepartmentIds; // Liste des codes postaux sélectionnés
    const ordersRef = collection(db, "Orders");

    // Récupérer tous les documents de la collection 'Orders'
    const querySnapshot = await getDocs(ordersRef);

    querySnapshot.forEach(async (docSnapshot) => {
        const orderData = docSnapshot.data();

        // Vérifier si 'Departments' existe dans la commande
        if (orderData.Departments) {
            const updatedDepartments = orderData.Departments.map(
                (department: any) => {
                    // Vérifier si le numéro de département est dans les ID sélectionnés
                    if (
                        selectedDepartmentIds.includes(
                            department.DepartmentNumber
                        )
                    ) {
                        return { ...department, EmployeeID: "" }; // Mettre EmployeeID à vide
                    }
                    return department;
                }
            );

            // Mettre à jour le document avec les nouveaux départements
            await updateDoc(doc(db, "Orders", docSnapshot.id), {
                Departments: updatedDepartments,
            });
        }
    });
}

watch(
    () => store.state.currentClient,
    (newClient) => {
        if (newClient) {
            currentClient.value = newClient;
            setupFirestoreListener();
        }
    }
);

watch(currentClient, async () => {
    if (orderId && currentClient.value) {
        const orderDoc = doc(db, "Orders", orderId);
        const orderSnapshot = await getDoc(orderDoc);
        if (orderSnapshot.exists()) {
            const orderData = orderSnapshot.data();
            if (orderData.ClientId !== currentClient.value.id) {
                router.push("/404");
            }
        } else {
            router.push("/404");
        }
    }
});

onBeforeMount(() => {
    getOrderName();
});

onMounted(() => {
    currentClient.value = store.state.currentClient;
    setupFirestoreListener();
});

onUnmounted(() => {
    // freeUserAffectToOrder();
    updateEmployeeIDs();
});
</script>
