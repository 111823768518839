<template>
    <Dialog
        v-model="dialogOpen"
        :title="$t('navbar.searchProspect')"
        width="70%"
        @submit="search"
        @close="close"
    >
        <template #text>
            <v-row>
                <v-col cols="4" class="pt-0">
                    <TextField
                        v-model="prospect.phoneNumber"
                        :label="$t('common.phoneNumber')"
                        disableMessage
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <TextField
                        v-model="prospect.mail"
                        :label="$t('common.mail')"
                        disableMessage
                    />
                </v-col>
                <v-col cols="4" class="pt-0">
                    <TextField
                        v-model="prospect.companyName"
                        :label="$t('calls.companyName')"
                        disableMessage
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="pt-0">
                    <TextField
                        v-model="prospect.lastName"
                        :label="$t('common.lastName')"
                        disableMessage
                    />
                </v-col>
                <v-col cols="6" class="pt-0">
                    <TextField
                        v-model="prospect.firstName"
                        :label="$t('common.firstName')"
                        disableMessage
                    />
                </v-col>
            </v-row>
            <v-divider v-if="isData" class="pb-4" />
            <Transition :name="transitionName" mode="out-in">
                <div :key="transitionKey">
                    <div v-if="isData">
                        <v-row>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.FirstName"
                                    :readonly="true"
                                    fieldSize="8"
                                    label="calls.firstName"
                                />
                            </v-col>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.LastName"
                                    :readonly="true"
                                    fieldSize="8"
                                    label="calls.lastName"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.Mail"
                                    :readonly="true"
                                    :textArea="true"
                                    fieldSize="8"
                                    label="calls.mail"
                                />
                            </v-col>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.Phone"
                                    :readonly="true"
                                    fieldSize="8"
                                    label="calls.phone"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.Position"
                                    :readonly="true"
                                    fieldSize="8"
                                    label="calls.position"
                                />
                            </v-col>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.CompanyName"
                                    :readonly="true"
                                    :textArea="true"
                                    field-size="8"
                                    label="calls.companyName"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.CompanyPhone"
                                    :readonly="true"
                                    fieldSize="8"
                                    label="calls.companyPhone"
                                />
                            </v-col>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.CompanyMail"
                                    :readonly="true"
                                    :textArea="true"
                                    fieldSize="8"
                                    label="calls.companyMail"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.ClientName"
                                    :readonly="true"
                                    fieldSize="8"
                                    label="calls.clientName"
                                />
                            </v-col>
                            <v-col cols="6" class="pb-0 pt-0">
                                <CardTextField
                                    v-model="prospectInfo.OrderName"
                                    :readonly="true"
                                    :textArea="true"
                                    fieldSize="8"
                                    label="calls.orderName"
                                />
                            </v-col>
                        </v-row>
                        <v-row style="justify-content: center">
                            <v-col cols="auto">
                                <v-btn
                                    class="button-bg-secondary"
                                    prepend-icon="mdi-phone-forward"
                                    :text="$t('calls.goToCallPage')"
                                    @click="goToCallPage"
                                />
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </Transition>
            <v-row v-if="isData && prospects.length > 1">
                <v-col class="space-between pt-0 pb-0">
                    <v-btn
                        class="big-icon icon-btn button-bg-white"
                        icon="mdi-chevron-left"
                        @click="previousProspect"
                        :disabled="currentIndex === 0 || prospects.length === 0"
                    />
                    <Span class="bold" style="left: 0">{{
                        prospects.length === 0
                            ? "0/0"
                            : currentIndex + 1 + "/" + prospects.length
                    }}</Span>
                    <v-btn
                        class="big-icon icon-btn button-bg-white"
                        icon="mdi-chevron-right"
                        @click="nextProspect"
                        :disabled="
                            currentIndex === prospects.length - 1 ||
                            prospects.length === 0
                        "
                    />
                </v-col>
            </v-row>
            <v-divider />
            <div
                v-if="!isData && searchDone && !isLoading"
                class="center-content"
            >
                <Span class="red">{{ $t("common.noInfoFound") }}</Span>
            </div>
        </template>
        <template #actions>
            <div class="center-content">
                <v-btn
                    type="submit"
                    class="button-bg-secondary"
                    :loading="isLoading"
                    :text="$t('common.search')"
                    prepend-icon="mdi-magnify"
                />
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { formatPhoneNumber } from "@/commons/convertion";
import { getCurrentClient } from "@/commons/firebase";
import {
    getClientName,
    getClientNameIdFromId,
    getOrderName,
} from "@/commons/firebase/index";
import Dialog from "@/components/customVuetify/Dialog.vue";
import TextField from "@/components/customVuetify/TextField.vue";
import { useSnackbar } from "@/composables/useSnackbar";
import { indexContacts, indexProspects } from "@/main";
import router from "@/router";
import { store } from "@/store";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Span } from "./customVuetify";
import CardTextField from "./customVuetify/CardTextField.vue";

const { t: $t } = useI18n();

const dialogOpen = ref(false);
const isLoading = ref(false);
const searchDone = ref(false);

const prospect = ref({
    phoneNumber: "",
    lastName: "",
    firstName: "",
    companyName: "",
    mail: "",
});

const transitionName = ref("");
const transitionKey = ref(0);
const currentIndex = ref(0);
const { showSnackbar } = useSnackbar();

const isData = computed(() => {
    return (
        prospectInfo.value.FirstName !== "" ||
        prospectInfo.value.LastName !== "" ||
        prospectInfo.value.Mail !== "" ||
        prospectInfo.value.Position !== "" ||
        prospectInfo.value.CompanyName !== ""
    );
});

const defaultProspectInfo = {
    FirstName: "",
    LastName: "",
    Mail: "",
    Phone: "",
    Position: "",
    CompanyName: "",
    CompanyPhone: "",
    CompanyMail: "",
    ClientName: "",
    ClientId: "",
    OrderName: "",
    OrderId: "",
    ProspectId: "",
};

const prospectInfo = ref({ ...defaultProspectInfo });

const prospects = ref<Array<typeof prospectInfo.value>>([]);

function close() {
    prospect.value = {
        phoneNumber: "",
        lastName: "",
        firstName: "",
        companyName: "",
        mail: "",
    };
    prospectInfo.value = { ...defaultProspectInfo };
    searchDone.value = false;
    emit("close");
}

function changeState(name: string) {
    transitionName.value = name;
    transitionKey.value++;
}

function previousProspect() {
    changeState("slide-left-fast");
    if (currentIndex.value > 0) {
        currentIndex.value--;
        prospectInfo.value = prospects.value[currentIndex.value];
    }
}

function nextProspect() {
    changeState("slide-right-fast");
    if (currentIndex.value < prospects.value.length - 1) {
        currentIndex.value++;
        prospectInfo.value = prospects.value[currentIndex.value];
    }
}

const emit = defineEmits(["close", "success"]);

async function search() {
    if (prospect.value.phoneNumber) {
        await searchPhoneNumber();
    } else if (prospect.value.mail) {
        await searchMail();
    } else if (prospect.value.companyName) {
        await searchCompanyName();
    } else if (prospect.value.lastName) {
        await searchLastName();
    } else if (prospect.value.firstName) {
        await searchFirstName();
    }
}

async function goToCallPage() {
    const newClient = await getClientNameIdFromId(prospectInfo.value.ClientId);
    const currentClient = getCurrentClient();
    if (newClient.id !== currentClient.value.id) {
        showSnackbar(
            `${$t("common.activeClientChanged")}: ${
                currentClient.value.name
            } -> ${newClient.name}`,
            3000,
            "rgb(var(--v-theme-secondary))"
        );
        store.commit("setClient", newClient);
        await new Promise((resolve) => setTimeout(resolve, 500));
    }
    router.push({
        name: "callInterface",
        params: { id: prospectInfo.value.ProspectId },
    });
    store.commit("setProspectIndex", 0);
    store.commit("setProspectIds", [
        {
            prospectId: prospectInfo.value.ProspectId,
            orderId: prospectInfo.value.OrderId,
        },
    ]);
    emit("close");
}

async function searchPhoneNumber() {
    isLoading.value = true;
    prospectInfo.value = { ...defaultProspectInfo };
    prospects.value = [];
    currentIndex.value = 0;

    if (!prospect.value.phoneNumber) {
        isLoading.value = false;
        return;
    }

    try {
        const prospectsResponse = await Promise.all([
            indexProspects.search<any>(prospect.value.phoneNumber, {
                restrictSearchableAttributes: ["Phone"],
            }),
        ]);

        for (const hit of prospectsResponse[0].hits) {
            const orderId = hit.path.split("/")[1];
            const order = await getOrderName(orderId);
            const client = await getClientName(hit.ClientId);
            prospects.value.push({
                FirstName: "",
                LastName: "",
                Mail: "",
                Phone: "",
                Position: "",
                CompanyName: hit.Name,
                CompanyPhone: formatPhoneNumber(hit.Phone),
                CompanyMail: hit.Mail,
                ClientName: client,
                ClientId: hit.ClientId,
                OrderName: order,
                OrderId: orderId,
                ProspectId: hit.objectID,
            });
        }

        const contactsResponse = await Promise.all([
            indexContacts.search<any>(prospect.value.phoneNumber, {
                restrictSearchableAttributes: ["Phone"],
            }),
        ]);

        for (const hit of contactsResponse[0].hits) {
            const prospectId = hit.path.split("/")[3];
            const orderId = hit.path.split("/")[1];
            const prospectDoc = await indexProspects.getObject<any>(prospectId);
            const order = await getOrderName(orderId);
            const client = await getClientName(prospectDoc.ClientId);
            prospects.value.push({
                FirstName: hit.FirstName,
                LastName: hit.LastName,
                Mail: hit.Mail,
                Phone: formatPhoneNumber(hit.Phone),
                Position: hit.Position,
                CompanyName: prospectDoc.Name,
                CompanyPhone: formatPhoneNumber(prospectDoc.Phone),
                CompanyMail: prospectDoc.Mail,
                ClientName: client,
                ClientId: prospectDoc.ClientId,
                OrderName: order,
                OrderId: orderId,
                ProspectId: prospectId,
            });
        }

        if (prospects.value.length > 0) {
            prospectInfo.value = prospects.value[0];
        }
    } catch (error) {
        console.error("Error searching phone number:", error);
    } finally {
        isLoading.value = false;
        searchDone.value = true;
    }
}

async function searchMail() {
    isLoading.value = true;
    prospectInfo.value = { ...defaultProspectInfo };
    prospects.value = [];
    currentIndex.value = 0;

    if (!prospect.value.mail) {
        isLoading.value = false;
        return;
    }

    try {
        const prospectsResponse = await Promise.all([
            indexProspects.search<any>(prospect.value.mail, {
                restrictSearchableAttributes: ["Mail"],
            }),
        ]);

        for (const hit of prospectsResponse[0].hits) {
            const orderId = hit.path.split("/")[1];
            const order = await getOrderName(orderId);
            const client = await getClientName(hit.ClientId);
            prospects.value.push({
                FirstName: "",
                LastName: "",
                Mail: "",
                Phone: "",
                Position: "",
                CompanyName: hit.Name,
                CompanyPhone: formatPhoneNumber(hit.Phone),
                CompanyMail: hit.Mail,
                ClientName: client,
                ClientId: hit.ClientId,
                OrderName: order,
                OrderId: orderId,
                ProspectId: hit.objectID,
            });
        }

        const contactsResponse = await Promise.all([
            indexContacts.search<any>(prospect.value.mail, {
                restrictSearchableAttributes: ["Mail"],
            }),
        ]);

        for (const hit of contactsResponse[0].hits) {
            const prospectId = hit.path.split("/")[3];
            const orderId = hit.path.split("/")[1];
            const prospectDoc = await indexProspects.getObject<any>(prospectId);
            const order = await getOrderName(orderId);
            const client = await getClientName(prospectDoc.ClientId);
            prospects.value.push({
                FirstName: hit.FirstName,
                LastName: hit.LastName,
                Mail: hit.Mail,
                Phone: formatPhoneNumber(hit.Phone),
                Position: hit.Position,
                CompanyName: prospectDoc.Name,
                CompanyPhone: formatPhoneNumber(prospectDoc.Phone),
                CompanyMail: prospectDoc.Mail,
                ClientName: client,
                ClientId: prospectDoc.ClientId,
                OrderName: order,
                OrderId: orderId,
                ProspectId: prospectId,
            });
        }

        if (prospects.value.length > 0) {
            prospectInfo.value = prospects.value[0];
        }
    } catch (error) {
        console.error("Error searching phone number:", error);
    } finally {
        isLoading.value = false;
        searchDone.value = true;
    }
}

async function searchCompanyName() {
    isLoading.value = true;
    prospectInfo.value = { ...defaultProspectInfo };
    prospects.value = [];
    currentIndex.value = 0;

    if (!prospect.value.companyName) {
        isLoading.value = false;
        return;
    }

    try {
        const prospectsResponse = await Promise.all([
            indexProspects.search<any>(prospect.value.companyName, {
                restrictSearchableAttributes: ["Name"],
            }),
        ]);

        for (const hit of prospectsResponse[0].hits) {
            const orderId = hit.path.split("/")[1];
            const order = await getOrderName(orderId);
            const client = await getClientName(hit.ClientId);
            prospects.value.push({
                FirstName: "",
                LastName: "",
                Mail: "",
                Phone: "",
                Position: "",
                CompanyName: hit.Name,
                CompanyPhone: formatPhoneNumber(hit.Phone),
                CompanyMail: hit.Mail,
                ClientName: client,
                ClientId: hit.ClientId,
                OrderName: order,
                OrderId: orderId,
                ProspectId: hit.objectID,
            });
        }

        if (prospects.value.length > 0) {
            prospectInfo.value = prospects.value[0];
        }
    } catch (error) {
        console.error("Error searching company name:", error);
    } finally {
        isLoading.value = false;
        searchDone.value = true;
    }
}

async function searchLastName() {
    isLoading.value = true;
    prospectInfo.value = { ...defaultProspectInfo };
    prospects.value = [];
    currentIndex.value = 0;

    if (!prospect.value.lastName) {
        isLoading.value = false;
        return;
    }

    try {
        const contactsResponse = await Promise.all([
            indexContacts.search<any>(prospect.value.lastName, {
                restrictSearchableAttributes: ["LastName"],
            }),
        ]);

        for (const hit of contactsResponse[0].hits) {
            const prospectId = hit.path.split("/")[3];
            const orderId = hit.path.split("/")[1];
            const prospectDoc = await indexProspects.getObject<any>(prospectId);
            const order = await getOrderName(orderId);
            const client = await getClientName(prospectDoc.ClientId);
            prospects.value.push({
                FirstName: hit.FirstName,
                LastName: hit.LastName,
                Mail: hit.Mail,
                Phone: formatPhoneNumber(hit.Phone),
                Position: hit.Position,
                CompanyName: prospectDoc.Name,
                CompanyPhone: formatPhoneNumber(prospectDoc.Phone),
                CompanyMail: prospectDoc.Mail,
                ClientName: client,
                ClientId: prospectDoc.ClientId,
                OrderName: order,
                OrderId: orderId,
                ProspectId: prospectId,
            });
        }

        if (prospects.value.length > 0) {
            prospectInfo.value = prospects.value[0];
        }
    } catch (error) {
        console.error("Error searching last name:", error);
    } finally {
        isLoading.value = false;
        searchDone.value = true;
    }
}

async function searchFirstName() {
    isLoading.value = true;
    prospectInfo.value = { ...defaultProspectInfo };
    prospects.value = [];
    currentIndex.value = 0;

    if (!prospect.value.firstName) {
        isLoading.value = false;
        return;
    }

    try {
        const contactsResponse = await Promise.all([
            indexContacts.search<any>(prospect.value.firstName, {
                restrictSearchableAttributes: ["FirstName"],
            }),
        ]);

        for (const hit of contactsResponse[0].hits) {
            const prospectId = hit.path.split("/")[3];
            const orderId = hit.path.split("/")[1];
            const prospectDoc = await indexProspects.getObject<any>(prospectId);
            const order = await getOrderName(orderId);
            const client = await getClientName(prospectDoc.ClientId);
            prospects.value.push({
                FirstName: hit.FirstName,
                LastName: hit.LastName,
                Mail: hit.Mail,
                Phone: formatPhoneNumber(hit.Phone),
                Position: hit.Position,
                CompanyName: prospectDoc.Name,
                CompanyPhone: formatPhoneNumber(prospectDoc.Phone),
                CompanyMail: prospectDoc.Mail,
                ClientName: client,
                ClientId: prospectDoc.ClientId,
                OrderName: order,
                OrderId: orderId,
                ProspectId: prospectId,
            });
        }

        if (prospects.value.length > 0) {
            prospectInfo.value = prospects.value[0];
        }
    } catch (error) {
        console.error("Error searching first name:", error);
    } finally {
        isLoading.value = false;
        searchDone.value = true;
    }
}

watch(
    () => prospect.value.phoneNumber,
    () => {
        prospect.value.companyName = "";
        prospect.value.firstName = "";
        prospect.value.lastName = "";
    }
);

watch(
    () => prospect.value.companyName,
    () => {
        prospect.value.phoneNumber = "";
        prospect.value.firstName = "";
        prospect.value.lastName = "";
    }
);

watch(
    () => prospect.value.lastName,
    () => {
        prospect.value.companyName = "";
        prospect.value.firstName = "";
        prospect.value.phoneNumber = "";
    }
);

watch(
    () => prospect.value.firstName,
    () => {
        prospect.value.companyName = "";
        prospect.value.phoneNumber = "";
        prospect.value.lastName = "";
    }
);
</script>

<style scoped>
.v-row {
    height: auto !important;
}
</style>
