<template>
    <v-row v-if="(!orders || orders.length === 0) && isLoading">
        <v-col cols="12" class="text-center">
            <v-progress-circular
                indeterminate
                :color="`rgb(var(--v-theme-secondary))`"
            ></v-progress-circular>
        </v-col>
    </v-row>
    <div v-else>
        <div
            class="report-section"
            v-for="(order, index) in orders"
            :key="index"
        >
            <Span class="bold" :for="'report-' + index">
                {{ index + 1 }} - {{ (order as any).name }}
            </Span>
            <TextArea
                :id="'report-' + index"
                v-model="(order as any).reportComment"
                :label="$t('popupMyDayReport.actionsTaken')"
                outlined
                dense
                class="pt-2"
                rows="3"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import TextArea from "@/components/customVuetify/TextArea.vue";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Span } from "../customVuetify";

const { t: $t } = useI18n();
const emit = defineEmits(["close", "inputData"]);
const orders = ref([]);

const props = defineProps({
    dataOrders: {
        type: Array,
        default: () => [],
    },
    isLoading: Boolean,
});

watch(
    () => props.dataOrders,
    (newVal) => {
        (orders.value as any) = newVal.map((order: any) => ({
            ...order,
            reportComment: "",
        }));
    },
    { immediate: true }
);

watch(
    orders,
    (newVal) => {
        emit("inputData", newVal);
    },
    { deep: true }
);
</script>
