<template>
    <Dialog
        v-model="dialogOpen"
        :title="$t('db.selectRegion')"
        width="40%"
        :height="dropdownOpen ? '35vw' : '25vw'"
        @close="$emit('close')"
    >
        <template #text>
            <div class="tree">
                <Treeselect
                    v-model="localisation"
                    :multiple="true"
                    :options="options"
                    @open="
                        dropdownOpen = true;
                        regionError = false;
                    "
                    @close="dropdownOpen = false"
                />
                <Span
                    v-if="regionError"
                    style="color: #f44336; padding-top: 0vh"
                    >{{ $t("db.selectDepartment") }}</Span
                >
            </div>
        </template>
        <template #actions>
            <div class="center-content padding-over">
                <v-btn
                    class="button-bg-secondary"
                    :loading="isLoading"
                    :disabled="isLoading"
                    :text="$t('common.confirm')"
                    prepend-icon="mdi-check"
                    @click="confirm"
                />
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { Span } from "@/components/customVuetify";
import Dialog from "@/components/customVuetify/Dialog.vue";
import { db } from "@/main";
import { store } from "@/store";
import { Departments, Order, departmentRegionTypeMapping } from "@/types";
import "@/vue3-treeselect.min.css";
import Treeselect from "@zanmato/vue3-treeselect";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { getCurrentUser } from "../../commons/firebase";

const { t: $t } = useI18n();

const emit = defineEmits(["close", "confirm"]);

const props = defineProps<{
    order: Order;
}>();

const regionError = ref(false);
const dialogOpen = ref(false);
const dropdownOpen = ref(false);
const isLoading = ref(false);
const departments = ref<Departments[]>([]);
const localisation = ref<(string | null)[][]>([]);
const options = ref<
    {
        id: (string | null)[];
        label: string;
        children: { id: string; label: string }[];
    }[]
>([]);

async function getDisabledDepartments(OrderID: string) {
    const User = await getCurrentUser();
    const disabledDepartments = new Set();

    // Obtenez le document de commande spécifique
    const orderDocRef = doc(db, "Orders", OrderID);
    const orderDocSnap = await getDoc(orderDocRef);

    // Vérifiez si le document existe et a un champ Departments
    if (orderDocSnap.exists() && orderDocSnap.data().Departments) {
        const departments = orderDocSnap.data().Departments;

        // Itérez sur chaque Localisation dans le tableau Departments
        departments.forEach((dept: any) => {
            if (dept.EmployeeID !== "" && dept.EmployeeID !== User.DocId) {
                disabledDepartments.add(dept.DepartmentNumber);
            }
        });
    }
    return disabledDepartments;
}

watch(
    () => props.order,
    async (newOrder) => {
        if (newOrder && newOrder.Departments) {
            departments.value = newOrder.Departments;
            const disabledDepartmentsSet = await getDisabledDepartments(
                newOrder.DocId
            );

            const regions = new Map();

            newOrder.Departments.forEach((department) => {
                const {
                    region,
                    department: departmentName,
                    type,
                } = departmentRegionTypeMapping[department.DepartmentNumber];
                const category =
                    type === "DOM"
                        ? "Outre-Mer - DOM"
                        : type === "TOM"
                        ? "Outre-Mer - TOM"
                        : "Métropole";

                if (!regions.has(category)) {
                    regions.set(category, {
                        id: category,
                        label: category,
                        children: [],
                    });
                }

                if (
                    !regions
                        .get(category)
                        .children.some((sub: any) => sub.id === region)
                ) {
                    regions.get(category).children.push({
                        id: region,
                        label: region,
                        children: [],
                    });
                }

                const regionGroup = regions
                    .get(category)
                    .children.find((sub: any) => sub.id === region);
                const isDisabled = disabledDepartmentsSet.has(
                    department.DepartmentNumber
                );
                regionGroup.children.push({
                    id: department.DepartmentNumber,
                    label: departmentName,
                    isDisabled,
                });
            });

            options.value = Array.from(regions.values()).map((typeInfo) => ({
                ...typeInfo,
                children: typeInfo.children.map((region: any) => ({
                    ...region,
                    children: region.children.map((dept: any) => ({
                        ...dept,
                        isDisabled: dept.isDisabled,
                    })),
                })),
            }));
        }
    },
    { immediate: true }
);

function getCategoryDepartmentCodes(category: any) {
    return Object.entries(departmentRegionTypeMapping)
        .filter(([, { type }]) => type === category)
        .map(([code]) => code);
}

async function confirm() {
    const User = await getCurrentUser();

    let allSelectedIds: string[] = [];

    localisation.value
        .flat()
        .filter((id) => id !== null)
        .forEach((id) => {
            if (
                id === "Métropole" ||
                id === "Outre-Mer - DOM" ||
                id === "Outre-Mer - TOM"
            ) {
                allSelectedIds.push(
                    ...getCategoryDepartmentCodes(
                        id.replace("Outre-Mer - ", "")
                    )
                );
            } else {
                id ? allSelectedIds.push(id) : 0;
            }
        });

    const selectedIdsSet = new Set(allSelectedIds);

    const orderRef = doc(db, "Orders", props.order.DocId);
    const orderSnap = await getDoc(orderRef);
    if (!orderSnap.exists()) {
        console.error("Le document de commande n'existe pas.");
        return;
    }

    // Mettez à jour chaque département dans le tableau Departments avec le nouvel EmployeeID si sélectionné.
    const newDepartments = orderSnap
        .data()
        .Departments.map((department: any) => {
            if (selectedIdsSet.has(department.DepartmentNumber)) {
                return { ...department, EmployeeID: User.DocId };
            } else {
                return department;
            }
        });

    // Enregistrez les modifications dans Firestore.
    await updateDoc(orderRef, {
        Departments: newDepartments,
    });

    store.commit("setSelectedDepartmentIds", Array.from(selectedIdsSet));
    emit("confirm");
}

onMounted(async () => {
    departments.value = props.order ? props.order.Departments : [];
});
</script>

<style scoped>
.padding-over {
    padding-top: 1em !important;
}
</style>
