<template>
    <v-row class="filters-padding">
        <v-col cols="2">
            <TextField
                v-model="filters.Name"
                :label="$t('prospects.name')"
                :clearable="true"
                :disableMessage="true"
            />
        </v-col>
        <v-col cols="4">
            <AutocompleteField
                v-model="filters.OrderId"
                :items="orders"
                :label="$t('calls.order')"
                item-title="name"
                item-value="id"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
        <v-col cols="2">
            <SelectField
                v-model="filters.CallNumber"
                :items="callNumbers"
                :label="$t('calls.callNumber')"
                item-title="name"
                item-value="id"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
        <v-col cols="2" class="pt-0 pb-0">
            <Checkbox
                v-model="filters.HasContactSheet"
                :value="true"
                color="rgb(var(--v-theme-secondary))"
                class="disable-message"
            >
                <template v-slot:label>
                    <v-tooltip
                        :text="$t('calls.showContactSheet')"
                        location="top"
                    >
                        <template #activator="{ props }">
                            <div v-bind="props" ref="targetRef">
                                {{ $t("calls.contactsOnly") }}
                            </div>
                        </template>
                    </v-tooltip>
                </template>
            </Checkbox>
        </v-col>
        <v-col cols="4">
            <ApeField
                v-model="filters.APECode"
                :label="$t('common.apeCode')"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
        <v-col cols="2">
            <AutocompleteField
                v-model="filters.Operator"
                :items="props.operatorList"
                :label="$t('calls.operator')"
                item-title="name"
                item-value="id"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
    </v-row>
    <v-row class="filters-padding">
        <v-col cols="4">
            <AutocompleteField
                v-model="filters.Region"
                :items="regionList"
                :label="$t('calls.region')"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
        <v-col cols="4">
            <AutocompleteField
                v-model="filters.Department"
                :items="departmentList"
                :label="$t('calls.department')"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
        <v-col cols="2">
            <TextField
                v-model="filters.PostalCode"
                :label="$t('prospects.postalCode')"
                :clearable="true"
                :disableMessage="true"
            />
        </v-col>
        <v-col cols="2">
            <AutocompleteField
                v-model="filters.CreationDate"
                :items="props.creationDateList"
                :label="$t('calls.creationDate')"
                item-title="name"
                item-value="id"
                :disableMessage="true"
                clearable
                multiple
            />
        </v-col>
        <v-col cols="2">
            <v-btn
                @click="resetFilters"
                class="button-bg-secondary"
                :text="$t('calls.resetFilters')"
            />
        </v-col>
    </v-row>
    <v-row class="filters-padding">
        <v-col cols="4" class="pt-0 pb-0">
            <Checkbox
                v-model="filters.CalledToday"
                :value="true"
                color="rgb(var(--v-theme-secondary))"
                class="disable-message"
            >
                <template v-slot:label>
                    <v-tooltip
                        :text="$t('calls.tooltipCheckbox')"
                        location="top"
                    >
                        <template #activator="{ props }">
                            <div v-bind="props" ref="targetRef">
                                {{ $t("calls.showAlreadyCalled") }}
                            </div>
                        </template>
                    </v-tooltip>
                </template>
            </Checkbox>
        </v-col>
        <v-spacer />
        <v-col cols="4" class="pb-0 pt-0">
            <v-row no-gutters>
                <div class="flex-container">
                    <div>
                        <Checkbox
                            v-model="filters.NextAction"
                            :value="NextAction.call"
                        />
                        <Span style="font-weight: bold"
                            >{{ callsNumber }}&nbsp;</Span
                        >
                        <Span>{{ $t("calls.calls") }}</Span>
                    </div>
                    <Span> &nbsp;| </Span>
                    <div>
                        <Checkbox
                            v-model="filters.NextAction"
                            :value="NextAction.mail"
                        />
                        <Span style="font-weight: bold"
                            >{{ mailsNumber }}&nbsp;</Span
                        >
                        <Span>{{ $t("calls.mails") }}</Span>
                    </div>
                    <Span> &nbsp;| </Span>
                    <div>
                        <Checkbox
                            v-model="filters.NextAction"
                            :value="NextAction.meeting"
                        />
                        <Span style="font-weight: bold"
                            >{{ meetingsNumber }}&nbsp;</Span
                        >
                        <Span>{{ $t("calls.meetings") }}</Span>
                    </div>
                    <Span> &nbsp;| </Span>
                    <div>
                        <Checkbox
                            v-model="filters.NextAction"
                            :value="NextAction.ko"
                        />
                        <Span style="font-weight: bold"
                            >{{ koNumber }}&nbsp;</Span
                        >
                        <Span>{{ $t("calls.ko") }}</Span>
                    </div>
                </div>
            </v-row>
        </v-col>
        <v-col cols="2">
            <Span style="font-weight: bold">{{ prospectsNumber }}&nbsp;</Span
            ><Span>{{ $t("calls.prospectsInDB") }}&nbsp;</Span
            ><Span style="font-weight: bold"
                >{{ decisionMakerNumber }}&nbsp;</Span
            ><Span>{{ $t("calls.decisionMakers") }}</Span>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { getActiveOrderListNameId } from "@/commons/firebase";
import ApeField from "@/components/ApeField.vue";
import { Span } from "@/components/customVuetify";
import AutocompleteField from "@/components/customVuetify/AutocompleteField.vue";
import Checkbox from "@/components/customVuetify/Checkbox.vue";
import SelectField from "@/components/customVuetify/SelectField.vue";
import TextField from "@/components/customVuetify/TextField.vue";
import { store } from "@/store";
import { NextAction, departmentRegionTypeMapping } from "@/types";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["applyFilters"]);

const filters = ref({
    Name: "",
    Region: null,
    Department: null,
    PostalCode: "",
    OrderId: null,
    CallNumber: null,
    NextAction: [NextAction.call],
    CalledToday: false,
    HasContactSheet: false,
    APECode: null as unknown as any,
    Order: "",
    CreationDate: [],
    Operator: [],
});

const regions = Object.values(departmentRegionTypeMapping).map(
    (item) => item.region
);
const uniqueRegions = [...new Set(regions)];
const regionList = uniqueRegions.map((region) => {
    return { name: region, id: region };
});

const departments = Object.values(departmentRegionTypeMapping).map(
    (item) => item.department
);
const uniqueDepartments = [...new Set(departments)];
const departmentList = uniqueDepartments.map((department, index) => {
    return { name: department, id: index };
});

const orders = ref<any[]>([]);

const props = defineProps<{
    prospectsNumber: number;
    decisionMakerNumber: number;
    callsNumber: number;
    mailsNumber: number;
    meetingsNumber: number;
    koNumber: number;
    creationDateList: { name: string; id: string }[];
    operatorList: { name: string; id: string }[];
}>();

const callNumbers = [
    { id: 1, name: "Call 1" },
    { id: 2, name: "Call 2" },
    { id: 3, name: "Call 3" },
    { id: 4, name: "Call 4" },
    { id: 5, name: "Call 5" },
    { id: "6+", name: "Call 6 ou +" },
];

function resetFilters() {
    filters.value = {
        Name: "",
        Region: null,
        Department: null,
        PostalCode: "",
        OrderId: null,
        CallNumber: null,
        NextAction: [NextAction.call],
        CalledToday: false,
        HasContactSheet: false,
        APECode: null,
        Order: "",
        CreationDate: [],
        Operator: [],
    };
    emit("applyFilters", filters.value);
}

watch(
    () => store.state.currentClient,
    async (client) => {
        if (client) {
            const result = await getActiveOrderListNameId();
            orders.value = Array.isArray(result.value) ? result.value : [];
            filters.value.OrderId = null;
        }
    }
);

onMounted(async () => {
    const result = await getActiveOrderListNameId();
    orders.value = Array.isArray(result.value) ? result.value : [];
    if (store.state.callsFilters) {
        filters.value = store.state.callsFilters;
        filters.value.NextAction = [NextAction.call];
        filters.value.Operator = [];
    }
    emit("applyFilters", filters.value);
});

onUnmounted(() => {
    store.commit("setCallsFilters", filters.value);
});
</script>

<style scoped>
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flex-container > div {
    display: flex;
    align-items: center;
}
</style>
