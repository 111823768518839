export interface Class {
    code: string;
    description: string;
}

export const ClassCodes = {
    "01.11":
        "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
    "01.12": "Culture du riz",
    "01.13": "Culture de légumes, de melons, de racines et de tubercules",
    "01.14": "Culture de la canne à sucre",
    "01.15": "Culture du tabac",
    "01.16": "Culture de plantes à fibres",
    "01.19": "Autres cultures non permanentes",
    "01.21": "Culture de la vigne",
    "01.22": "Culture de fruits tropicaux et subtropicaux",
    "01.23": "Culture d'agrumes",
    "01.24": "Culture de fruits à pépins et à noyau",
    "01.25":
        "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
    "01.26": "Culture de fruits oléagineux",
    "01.27": "Culture de plantes à boissons",
    "01.28":
        "Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques",
    "01.29": "Autres cultures permanentes",
    "01.30": "Reproduction de plantes",
    "01.41": "Élevage de vaches laitières",
    "01.42": "Élevage d'autres bovins et de buffles",
    "01.43": "Élevage de chevaux et d'autres équidés",
    "01.44": "Élevage de chameaux et d'autres camélidés",
    "01.45": "Élevage d'ovins et de caprins",
    "01.46": "Élevage de porcins",
    "01.47": "Élevage de volailles",
    "01.49": "Élevage d'autres animaux",
    "01.50": "Culture et élevage associés",
    "01.61": "Activités de soutien aux cultures",
    "01.62": "Activités de soutien à la production animale",
    "01.63": "Traitement primaire des récoltes",
    "01.64": "Traitement des semences",
    "01.70": "Chasse, piégeage et services annexes",
    "02.10": "Sylviculture et autres activités forestières",
    "02.20": "Exploitation forestière",
    "02.30":
        "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
    "02.40": "Services de soutien à l'exploitation forestière",
    "03.11": "Pêche en mer",
    "03.12": "Pêche en eau douce",
    "03.21": "Aquaculture en mer",
    "03.22": "Aquaculture en eau douce",
    "05.10": "Extraction de houille",
    "05.20": "Extraction de lignite",
    "06.10": "Extraction de pétrole brut",
    "06.20": "Extraction de gaz naturel",
    "07.10": "Extraction de minerais de fer",
    "07.21": "Extraction de minerais d'uranium et de thorium",
    "07.29": "Extraction d'autres minerais de métaux non ferreux",
    "08.11":
        "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
    "08.12":
        "Exploitation de gravières et sablières, extraction d'argiles et de kaolin",
    "08.91": "Extraction des minéraux chimiques et d'engrais minéraux",
    "08.92": "Extraction de tourbe",
    "08.93": "Production de sel",
    "08.99": "Autres activités extractives n.c.a.",
    "09.10": "Activités de soutien à l'extraction d'hydrocarbures",
    "09.90": "Activités de soutien aux autres industries extractives",
    "10.11": "Transformation et conservation de la viande de boucherie",
    "10.12": "Transformation et conservation de la viande de volaille",
    "10.13": "Préparation de produits à base de viande",
    "10.20":
        "Transformation et conservation de poisson, de crustacés et de mollusques",
    "10.31": "Transformation et conservation de pommes de terre",
    "10.32": "Préparation de jus de fruits et légumes",
    "10.39": "Autre transformation et conservation de fruits et légumes",
    "10.41": "Fabrication d'huiles et graisses",
    "10.42": "Fabrication de margarine et graisses comestibles similaires",
    "10.51": "Exploitation de laiteries et fabrication de fromage",
    "10.52": "Fabrication de glaces et sorbets",
    "10.61": "Travail des grains",
    "10.62": "Fabrication de produits amylacés",
    "10.71": "Fabrication de pain et de pâtisserie fraîche",
    "10.72":
        "Fabrication de biscuits, biscottes et pâtisseries de conservation",
    "10.73": "Fabrication de pâtes alimentaires",
    "10.81": "Fabrication de sucre",
    "10.82": "Fabrication de cacao, chocolat et de produits de confiserie",
    "10.83": "Transformation du thé et du café",
    "10.84": "Fabrication de condiments et assaisonnements",
    "10.85": "Fabrication de plats préparés",
    "10.86": "Fabrication d'aliments homogénéisés et diététiques",
    "10.89": "Fabrication d'autres produits alimentaires n.c.a.",
    "10.91": "Fabrication d'aliments pour animaux de ferme",
    "10.92": "Fabrication d'aliments pour animaux de compagnie",
    "11.01": "Production de boissons alcooliques distillées",
    "11.02": "Production de vin (de raisin)",
    "11.03": "Fabrication de cidre et de vins de fruits",
    "11.04": "Production d'autres boissons fermentées non distillées",
    "11.05": "Fabrication de bière",
    "11.06": "Fabrication de malt",
    "11.07":
        "Industrie des eaux minérales et autres eaux embouteillées et des boissons rafraîchissantes",
    "12.00": "Fabrication de produits à base de tabac",
    "13.10": "Préparation de fibres textiles et filature",
    "13.20": "Tissage",
    "13.30": "Ennoblissement textile",
    "13.91": "Fabrication d'étoffes à mailles",
    "13.92": "Fabrication d'articles textiles, sauf habillement",
    "13.93": "Fabrication de tapis et moquettes",
    "13.94": "Fabrication de ficelles, cordes et filets",
    "13.95": "Fabrication de non-tissés, sauf habillement",
    "13.96": "Fabrication d'autres textiles techniques et industriels",
    "13.99": "Fabrication d'autres textiles n.c.a.",
    "14.11": "Fabrication de vêtements en cuir",
    "14.12": "Fabrication de vêtements de travail",
    "14.13": "Fabrication de vêtements de dessus",
    "14.14": "Fabrication de vêtements de dessous",
    "14.19": "Fabrication d'autres vêtements et accessoires",
    "14.20": "Fabrication d'articles en fourrure",
    "14.31": "Fabrication d'articles chaussants à mailles",
    "14.39": "Fabrication d'autres articles à mailles",
    "15.11":
        "Apprêt et tannage des cuirs ; préparation et teinture des fourrures",
    "15.12": "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
    "15.20": "Fabrication de chaussures",
    "16.10": "Sciage et rabotage du bois",
    "16.21": "Fabrication de placage et de panneaux de bois",
    "16.22": "Fabrication de parquets assemblés",
    "16.23": "Fabrication de charpentes et d'autres menuiseries",
    "16.24": "Fabrication d'emballages en bois",
    "16.29":
        "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
    "17.11": "Fabrication de pâte à papier",
    "17.12": "Fabrication de papier et de carton",
    "17.21":
        "Fabrication de papier et carton ondulés et d'emballages en papier ou en carton",
    "17.22": "Fabrication d'articles en papier à usage sanitaire ou domestique",
    "17.23": "Fabrication d'articles de papeterie",
    "17.24": "Fabrication de papiers peints",
    "17.29": "Fabrication d'autres articles en papier ou en carton",
    "18.11": "Imprimerie de journaux",
    "18.12": "Autre imprimerie (labeur)",
    "18.13": "Activités de pré-presse",
    "18.14": "Reliure et activités connexes",
    "18.20": "Reproduction d'enregistrements",
    "19.10": "Cokéfaction",
    "19.20": "Raffinage du pétrole",
    "20.11": "Fabrication de gaz industriels",
    "20.12": "Fabrication de colorants et de pigments",
    "20.13": "Fabrication d'autres produits chimiques inorganiques de base",
    "20.14": "Fabrication d'autres produits chimiques organiques de base",
    "20.15": "Fabrication de produits azotés et d'engrais",
    "20.16": "Fabrication de matières plastiques de base",
    "20.17": "Fabrication de caoutchouc synthétique",
    "20.20": "Fabrication de pesticides et d'autres produits agrochimiques",
    "20.30": "Fabrication de peintures, vernis, encres et mastics",
    "20.41": "Fabrication de savons, détergents et produits d'entretien",
    "20.42": "Fabrication de parfums et de produits pour la toilette",
    "20.51": "Fabrication de produits explosifs",
    "20.52": "Fabrication de colles",
    "20.53": "Fabrication d'huiles essentielles",
    "20.59": "Fabrication d'autres produits chimiques n.c.a.",
    "20.60": "Fabrication de fibres artificielles ou synthétiques",
    "21.10": "Fabrication de produits pharmaceutiques de base",
    "21.20": "Fabrication de préparations pharmaceutiques",
    "22.11": "Fabrication et rechapage de pneumatiques",
    "22.19": "Fabrication d'autres articles en caoutchouc",
    "22.21":
        "Fabrication de plaques, feuilles, tubes et profilés en matières plastiques",
    "22.22": "Fabrication d'emballages en matières plastiques",
    "22.23":
        "Fabrication d'éléments en matières plastiques pour la construction",
    "22.29": "Fabrication d'autres articles en matières plastiques",
    "23.11": "Fabrication de verre plat",
    "23.12": "Façonnage et transformation du verre plat",
    "23.13": "Fabrication de verre creux",
    "23.14": "Fabrication de fibres de verre",
    "23.19":
        "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
    "23.20": "Fabrication de produits réfractaires",
    "23.31": "Fabrication de carreaux en céramique",
    "23.32":
        "Fabrication de briques, tuiles et produits de construction, en terre cuite",
    "23.41":
        "Fabrication d'articles céramiques à usage domestique ou ornemental",
    "23.42": "Fabrication d'appareils sanitaires en céramique",
    "23.43": "Fabrication d'isolateurs et pièces isolantes en céramique",
    "23.44": "Fabrication d'autres produits céramiques à usage technique",
    "23.49": "Fabrication d'autres produits céramiques",
    "23.51": "Fabrication de ciment",
    "23.52": "Fabrication de chaux et plâtre",
    "23.61": "Fabrication d'éléments en béton pour la construction",
    "23.62": "Fabrication d'éléments en plâtre pour la construction",
    "23.63": "Fabrication de béton prêt à l'emploi",
    "23.64": "Fabrication de mortiers et bétons secs",
    "23.65": "Fabrication d'ouvrages en fibre-ciment",
    "23.69": "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
    "23.70": "Taille, façonnage et finissage de pierres",
    "23.91": "Fabrication de produits abrasifs",
    "23.99": "Fabrication d'autres produits minéraux non métalliques n.c.a.",
    "24.10": "Sidérurgie",
    "24.20":
        "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier",
    "24.31": "Étirage à froid de barres",
    "24.32": "Laminage à froid de feuillards",
    "24.33": "Profilage à froid par formage ou pliage",
    "24.34": "Tréfilage à froid",
    "24.41": "Production de métaux précieux",
    "24.42": "Métallurgie de l'aluminium",
    "24.43": "Métallurgie du plomb, du zinc ou de l'étain",
    "24.44": "Métallurgie du cuivre",
    "24.45": "Métallurgie des autres métaux non ferreux",
    "24.46": "Élaboration et transformation de matières nucléaires",
    "24.51": "Fonderie de fonte",
    "24.52": "Fonderie d'acier",
    "24.53": "Fonderie de métaux légers",
    "24.54": "Fonderie d'autres métaux non ferreux",
    "25.11":
        "Fabrication de structures métalliques et de parties de structures",
    "25.12": "Fabrication de portes et fenêtres en métal",
    "25.21":
        "Fabrication de radiateurs et de chaudières pour le chauffage central",
    "25.29":
        "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
    "25.30":
        "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
    "25.40": "Fabrication d'armes et de munitions",
    "25.50": "Forge, emboutissage, estampage ; métallurgie des poudres",
    "25.61": "Traitement et revêtement des métaux",
    "25.62": "Usinage",
    "25.71": "Fabrication de coutellerie",
    "25.72": "Fabrication de serrures et de ferrures",
    "25.73": "Fabrication d'outillage",
    "25.91": "Fabrication de fûts et emballages métalliques similaires",
    "25.92": "Fabrication d'emballages métalliques légers",
    "25.93":
        "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
    "25.94": "Fabrication de vis et de boulons",
    "25.99": "Fabrication d'autres produits métalliques n.c.a.",
    "26.11": "Fabrication de composants électroniques",
    "26.12": "Fabrication de cartes électroniques assemblées",
    "26.20": "Fabrication d'ordinateurs et d'équipements périphériques",
    "26.30": "Fabrication d'équipements de communication",
    "26.40": "Fabrication de produits électroniques grand public",
    "26.51":
        "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation",
    "26.52": "Horlogerie",
    "26.60":
        "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
    "26.70": "Fabrication de matériels optique et photographique",
    "26.80": "Fabrication de supports magnétiques et optiques",
    "27.11":
        "Fabrication de moteurs, génératrices et transformateurs électriques",
    "27.12":
        "Fabrication de matériel de distribution et de commande électrique",
    "27.20": "Fabrication de piles et d'accumulateurs électriques",
    "27.31": "Fabrication de câbles de fibres optiques",
    "27.32": "Fabrication d'autres fils et câbles électroniques ou électriques",
    "27.33": "Fabrication de matériel d'installation électrique",
    "27.40": "Fabrication d'appareils d'éclairage électrique",
    "27.51": "Fabrication d'appareils électroménagers",
    "27.52": "Fabrication d'appareils ménagers non électriques",
    "27.90": "Fabrication d'autres matériels électriques",
    "28.11":
        "Fabrication de moteurs et turbines, à l'exception des moteurs d'avions et de véhicules",
    "28.12": "Fabrication d'équipements hydrauliques et pneumatiques",
    "28.13": "Fabrication d'autres pompes et compresseurs",
    "28.14": "Fabrication d'autres articles de robinetterie",
    "28.15": "Fabrication d'engrenages et d'organes mécaniques de transmission",
    "28.21": "Fabrication de fours et brûleurs",
    "28.22": "Fabrication de matériel de levage et de manutention",
    "28.23":
        "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
    "28.24": "Fabrication d'outillage portatif à moteur incorporé",
    "28.25":
        "Fabrication d'équipements aérauliques et frigorifiques industriels",
    "28.29": "Fabrication de machines diverses d'usage général",
    "28.30": "Fabrication de machines agricoles et forestières",
    "28.41": "Fabrication de machines de formage des métaux",
    "28.49": "Fabrication d'autres machines-outils",
    "28.91": "Fabrication de machines pour la métallurgie",
    "28.92": "Fabrication de machines pour l'extraction ou la construction",
    "28.93": "Fabrication de machines pour l'industrie agro-alimentaire",
    "28.94": "Fabrication de machines pour les industries textiles",
    "28.95":
        "Fabrication de machines pour les industries du papier et du carton",
    "28.96":
        "Fabrication de machines pour le travail du caoutchouc ou des plastiques",
    "28.99": "Fabrication d'autres machines d'usage spécifique n.c.a.",
    "29.10": "Construction de véhicules automobiles",
    "29.20": "Fabrication de carrosseries et remorques",
    "29.31":
        "Fabrication d'équipements électriques et électroniques automobiles",
    "29.32": "Fabrication d'autres équipements automobiles",
    "30.11": "Construction de navires et de structures flottantes",
    "30.12": "Construction de bateaux de plaisance",
    "30.20":
        "Construction de locomotives et d'autre matériel ferroviaire roulant",
    "30.30": "Construction aéronautique et spatiale",
    "30.40": "Construction de véhicules militaires de combat",
    "30.91": "Fabrication de motocycles",
    "30.92": "Fabrication de bicyclettes et de véhicules pour invalides",
    "30.99": "Fabrication d'autres équipements de transport n.c.a.",
    "31.01": "Fabrication de meubles de bureau et de magasin",
    "31.02": "Fabrication de meubles de cuisine",
    "31.03": "Fabrication de matelas",
    "31.09": "Fabrication d'autres meubles",
    "32.11": "Frappe de monnaie",
    "32.12": "Fabrication d'articles de joaillerie et bijouterie",
    "32.13":
        "Fabrication d'articles de bijouterie fantaisie et articles similaires",
    "32.20": "Fabrication d'instruments de musique",
    "32.30": "Fabrication d'articles de sport",
    "32.40": "Fabrication de jeux et jouets",
    "32.50":
        "Fabrication d'instruments et de fournitures à usage médical et dentaire",
    "32.91": "Fabrication d'articles de brosserie",
    "32.99": "Autres activités manufacturières n.c.a.",
    "33.11": "Réparation d'ouvrages en métaux",
    "33.12": "Réparation de machines et équipements mécaniques",
    "33.13": "Réparation de matériels électroniques et optiques",
    "33.14": "Réparation d'équipements électriques",
    "33.15": "Réparation et maintenance navale",
    "33.16": "Réparation et maintenance d'aéronefs et d'engins spatiaux",
    "33.17": "Réparation et maintenance d'autres équipements de transport",
    "33.19": "Réparation d'autres équipements",
    "33.20": "Installation de machines et d'équipements industriels",
    "35.11": "Production d'électricité",
    "35.12": "Transport d'électricité",
    "35.13": "Distribution d'électricité",
    "35.14": "Commerce d'électricité",
    "35.21": "Production de combustibles gazeux",
    "35.22": "Distribution de combustibles gazeux par conduites",
    "35.23": "Commerce de combustibles gazeux par conduites",
    "35.30": "Production et distribution de vapeur et d'air conditionné",
    "36.00": "Captage, traitement et distribution d'eau",
    "37.00": "Collecte et traitement des eaux usées",
    "38.11": "Collecte des déchets non dangereux",
    "38.12": "Collecte des déchets dangereux",
    "38.21": "Traitement et élimination des déchets non dangereux",
    "38.22": "Traitement et élimination des déchets dangereux",
    "38.31": "Démantèlement d'épaves",
    "38.32": "Récupération de déchets triés",
    "39.00": "Dépollution et autres services de gestion des déchets",
    "41.10": "Promotion immobilière",
    "41.20": "Construction de bâtiments résidentiels et non résidentiels",
    "42.11": "Construction de routes et autoroutes",
    "42.12": "Construction de voies ferrées de surface et souterraines",
    "42.13": "Construction de ponts et tunnels",
    "42.21": "Construction de réseaux pour fluides",
    "42.22": "Construction de réseaux électriques et de télécommunications",
    "42.91": "Construction d'ouvrages maritimes et fluviaux",
    "42.99": "Construction d'autres ouvrages de génie civil n.c.a.",
    "43.11": "Travaux de démolition",
    "43.12": "Travaux de préparation des sites",
    "43.13": "Forages et sondages",
    "43.21": "Installation électrique",
    "43.22":
        "Travaux de plomberie et installation de chauffage et de conditionnement d'air",
    "43.29": "Autres travaux d'installation",
    "43.31": "Travaux de plâtrerie",
    "43.32": "Travaux de menuiserie",
    "43.33": "Travaux de revêtement des sols et des murs",
    "43.34": "Travaux de peinture et vitrerie",
    "43.39": "Autres travaux de finition",
    "43.91": "Travaux de couverture",
    "43.99": "Autres travaux de construction spécialisés n.c.a.",
    "45.11": "Commerce de voitures et de véhicules automobiles légers",
    "45.19": "Commerce d'autres véhicules automobiles",
    "45.20": "Entretien et réparation de véhicules automobiles",
    "45.31": "Commerce de gros d'équipements automobiles",
    "45.32": "Commerce de détail d'équipements automobiles",
    "45.40": "Commerce et réparation de motocycles",
    "46.11":
        "Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis",
    "46.12":
        "Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques",
    "46.13": "Intermédiaires du commerce en bois et matériaux de construction",
    "46.14":
        "Intermédiaires du commerce en machines, équipements industriels, navires et avions",
    "46.15":
        "Intermédiaires du commerce en meubles, articles de ménage et quincaillerie",
    "46.16":
        "Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir",
    "46.17": "Intermédiaires du commerce en denrées, boissons et tabac",
    "46.18":
        "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
    "46.19": "Intermédiaires du commerce en produits divers",
    "46.21":
        "Commerce de gros de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail",
    "46.22": "Commerce de gros de fleurs et plantes",
    "46.23": "Commerce de gros d'animaux vivants",
    "46.24": "Commerce de gros de cuirs et peaux",
    "46.31": "Commerce de gros de fruits et légumes",
    "46.32": "Commerce de gros de viandes et de produits à base de viande",
    "46.33":
        "Commerce de gros de produits laitiers, œufs, huiles et matières grasses comestibles",
    "46.34": "Commerce de gros de boissons",
    "46.35": "Commerce de gros de produits à base de tabac",
    "46.36": "Commerce de gros de sucre, chocolat et confiserie",
    "46.37": "Commerce de gros de café, thé, cacao et épices",
    "46.38":
        "Commerce de gros d'autres produits alimentaires, y compris poissons, crustacés et mollusques",
    "46.39": "Commerce de gros non spécialisé de denrées, boissons et tabac",
    "46.41": "Commerce de gros de textiles",
    "46.42": "Commerce de gros d'habillement et de chaussures",
    "46.43": "Commerce de gros d'appareils électroménagers",
    "46.44": "Commerce de gros de vaisselle, verrerie et produits d'entretien",
    "46.45": "Commerce de gros de parfumerie et de produits de beauté",
    "46.46": "Commerce de gros de produits pharmaceutiques",
    "46.47": "Commerce de gros de meubles, de tapis et d'appareils d'éclairage",
    "46.48": "Commerce de gros d'articles d'horlogerie et de bijouterie",
    "46.49": "Commerce de gros d'autres biens domestiques",
    "46.51":
        "Commerce de gros d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
    "46.52":
        "Commerce de gros de composants et d'équipements électroniques et de télécommunication",
    "46.61": "Commerce de gros de matériel agricole",
    "46.62": "Commerce de gros de machines-outils",
    "46.63":
        "Commerce de gros de machines pour l'extraction, la construction et le génie civil",
    "46.64":
        "Commerce de gros de machines pour l'industrie textile et l'habillement",
    "46.65": "Commerce de gros de mobilier de bureau",
    "46.66": "Commerce de gros d'autres machines et équipements de bureau",
    "46.69": "Commerce de gros d'autres machines et équipements",
    "46.71": "Commerce de gros de combustibles et de produits annexes",
    "46.72": "Commerce de gros de minerais et métaux",
    "46.73":
        "Commerce de gros de bois, de matériaux de construction et d'appareils sanitaires",
    "46.74":
        "Commerce de gros de quincaillerie et fournitures pour plomberie et chauffage",
    "46.75": "Commerce de gros de produits chimiques",
    "46.76": "Commerce de gros d'autres produits intermédiaires",
    "46.77": "Commerce de gros de déchets et débris",
    "46.90": "Commerce de gros non spécialisé",
    "47.11":
        "Commerce de détail en magasin non spécialisé à prédominance alimentaire",
    "47.19": "Autre commerce de détail en magasin non spécialisé",
    "47.21": "Commerce de détail de fruits et légumes en magasin spécialisé",
    "47.22":
        "Commerce de détail de viandes et de produits à base de viande en magasin spécialisé",
    "47.23":
        "Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé",
    "47.24":
        "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé",
    "47.25": "Commerce de détail de boissons en magasin spécialisé",
    "47.26":
        "Commerce de détail de produits à base de tabac en magasin spécialisé",
    "47.29": "Autres commerces de détail alimentaires en magasin spécialisé",
    "47.30": "Commerce de détail de carburants en magasin spécialisé",
    "47.41":
        "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
    "47.42":
        "Commerce de détail de matériels de télécommunication en magasin spécialisé",
    "47.43":
        "Commerce de détail de matériels audio/vidéo en magasin spécialisé",
    "47.51": "Commerce de détail de textiles en magasin spécialisé",
    "47.52":
        "Commerce de détail de quincaillerie, peintures et verres en magasin spécialisé",
    "47.53":
        "Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé",
    "47.54":
        "Commerce de détail d'appareils électroménagers en magasin spécialisé",
    "47.59":
        "Commerce de détail de meubles, appareils d'éclairage et autres articles de ménage en magasin spécialisé",
    "47.61": "Commerce de détail de livres en magasin spécialisé",
    "47.62":
        "Commerce de détail de journaux et papeterie en magasin spécialisé",
    "47.63":
        "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
    "47.64": "Commerce de détail d'articles de sport en magasin spécialisé",
    "47.65": "Commerce de détail de jeux et jouets en magasin spécialisé",
    "47.71": "Commerce de détail d'habillement en magasin spécialisé",
    "47.72":
        "Commerce de détail de chaussures et d'articles en cuir en magasin spécialisé",
    "47.73":
        "Commerce de détail de produits pharmaceutiques en magasin spécialisé",
    "47.74":
        "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
    "47.75":
        "Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé",
    "47.76":
        "Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé",
    "47.77":
        "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
    "47.78": "Autre commerce de détail de biens neufs en magasin spécialisé",
    "47.79": "Commerce de détail de biens d'occasion en magasin",
    "47.81": "Commerce de détail alimentaire sur éventaires et marchés",
    "47.82":
        "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
    "47.89": "Autres commerces de détail sur éventaires et marchés",
    "47.91": "Vente à distance",
    "47.99": "Autres commerces de détail hors magasin, éventaires ou marchés",
    "49.10": "Transport ferroviaire interurbain de voyageurs",
    "49.20": "Transports ferroviaires de fret",
    "49.31": "Transports urbains et suburbains de voyageurs",
    "49.32": "Transports de voyageurs par taxis",
    "49.39": "Autres transports terrestres de voyageurs n.c.a.",
    "49.41": "Transports routiers de fret",
    "49.42": "Services de déménagement",
    "49.50": "Transports par conduites",
    "50.10": "Transports maritimes et côtiers de passagers",
    "50.20": "Transports maritimes et côtiers de fret",
    "50.30": "Transports fluviaux de passagers",
    "50.40": "Transports fluviaux de fret",
    "51.10": "Transports aériens de passagers",
    "51.21": "Transports aériens de fret",
    "51.22": "Transports spatiaux",
    "52.10": "Entreposage et stockage",
    "52.21": "Services auxiliaires des transports terrestres",
    "52.22": "Services auxiliaires des transports par eau",
    "52.23": "Services auxiliaires des transports aériens",
    "52.24": "Manutention",
    "52.29": "Autres services auxiliaires des transports",
    "53.10":
        "Activités de poste dans le cadre d'une obligation de service universel",
    "53.20": "Autres activités de poste et de courrier",
    "55.10": "Hôtels et hébergement similaire",
    "55.20": "Hébergement touristique et autre hébergement de courte durée",
    "55.30":
        "Terrains de camping et parcs pour caravanes ou véhicules de loisirs",
    "55.90": "Autres hébergements",
    "56.10": "Restaurants et services de restauration mobile",
    "56.21": "Services des traiteurs",
    "56.29": "Autres services de restauration",
    "56.30": "Débits de boissons",
    "58.11": "Édition de livres",
    "58.12": "Édition de répertoires et de fichiers d'adresses",
    "58.13": "Édition de journaux",
    "58.14": "Édition de revues et périodiques",
    "58.19": "Autres activités d'édition",
    "58.21": "Édition de jeux électroniques",
    "58.29": "Édition d'autres logiciels",
    "59.11":
        "Production de films cinématographiques, de vidéo et de programmes de télévision",
    "59.12":
        "Post-production de films cinématographiques, de vidéo et de programmes de télévision",
    "59.13":
        "Distribution de films cinématographiques, de vidéo et de programmes de télévision",
    "59.14": "Projection de films cinématographiques",
    "59.20": "Enregistrement sonore et édition musicale",
    "60.10": "Édition et diffusion de programmes radio",
    "60.20": "Programmation de télévision et télédiffusion",
    "61.10": "Télécommunications filaires",
    "61.20": "Télécommunications sans fil",
    "61.30": "Télécommunications par satellite",
    "61.90": "Autres activités de télécommunication",
    "62.01": "Programmation informatique",
    "62.02": "Conseil informatique",
    "62.03": "Gestion d'installations informatiques",
    "62.09": "Autres activités informatiques",
    "63.11": "Traitement de données, hébergement et activités connexes",
    "63.12": "Portails Internet",
    "63.91": "Activités des agences de presse",
    "63.99": "Autres services d'information n.c.a.",
    "64.11": "Activités de banque centrale",
    "64.19": "Autres intermédiations monétaires",
    "64.20": "Activités des sociétés holding",
    "64.30": "Fonds de placement et entités financières similaires",
    "64.91": "Crédit-bail",
    "64.92": "Autre distribution de crédit",
    "64.99":
        "Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.",
    "65.11": "Assurance vie",
    "65.12": "Autres assurances",
    "65.20": "Réassurance",
    "65.30": "Caisses de retraite",
    "66.11": "Administration de marchés financiers",
    "66.12": "Courtage de valeurs mobilières et de marchandises",
    "66.19":
        "Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite",
    "66.21": "Évaluation des risques et dommages",
    "66.22": "Activités des agents et courtiers d'assurances",
    "66.29":
        "Autres activités auxiliaires d'assurance et de caisses de retraite",
    "66.30": "Gestion de fonds",
    "68.10": "Activités des marchands de biens immobiliers",
    "68.20": "Location et exploitation de biens immobiliers propres ou loués",
    "68.31": "Agences immobilières",
    "68.32": "Administration de biens immobiliers",
    "69.10": "Activités juridiques",
    "69.20": "Activités comptables",
    "70.10": "Activités des sièges sociaux",
    "70.21": "Conseil en relations publiques et communication",
    "70.22": "Conseil pour les affaires et autres conseils de gestion",
    "71.11": "Activités d'architecture",
    "71.12": "Activités d'ingénierie",
    "71.20": "Activités de contrôle et analyses techniques",
    "72.11": "Recherche-développement en biotechnologie",
    "72.19":
        "Recherche-développement en autres sciences physiques et naturelles",
    "72.20": "Recherche-développement en sciences humaines et sociales",
    "73.11": "Activités des agences de publicité",
    "73.12": "Régie publicitaire de médias",
    "73.20": "Études de marché et sondages",
    "74.10": "Activités spécialisées de design",
    "74.20": "Activités photographiques",
    "74.30": "Traduction et interprétation",
    "74.90":
        "Autres activités spécialisées, scientifiques et techniques n.c.a.",
    "75.00": "Activités vétérinaires",
    "77.11":
        "Location et location-bail de voitures et de véhicules automobiles légers",
    "77.12": "Location et location-bail de camions",
    "77.21": "Location et location-bail d'articles de loisirs et de sport",
    "77.22": "Location de vidéocassettes et disques vidéo",
    "77.29":
        "Location et location-bail d'autres biens personnels et domestiques",
    "77.31": "Location et location-bail de machines et équipements agricoles",
    "77.32":
        "Location et location-bail de machines et équipements pour la construction",
    "77.33":
        "Location et location-bail de machines de bureau et de matériel informatique",
    "77.34": "Location et location-bail de matériels de transport par eau",
    "77.35": "Location et location-bail de matériels de transport aérien",
    "77.39":
        "Location et location-bail d'autres machines, équipements et biens matériels n.c.a.",
    "77.40":
        "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
    "78.10": "Activités des agences de placement de main-d'œuvre",
    "78.20": "Activités des agences de travail temporaire",
    "78.30": "Autre mise à disposition de ressources humaines",
    "79.11": "Activités des agences de voyage",
    "79.12": "Activités des voyagistes",
    "79.90": "Autres services de réservation et activités connexes",
    "80.10": "Activités de sécurité privée",
    "80.20": "Activités liées aux systèmes de sécurité",
    "80.30": "Activités d'enquête",
    "81.10": "Activités combinées de soutien lié aux bâtiments",
    "81.21": "Nettoyage courant des bâtiments",
    "81.22":
        "Autres activités de nettoyage des bâtiments et nettoyage industriel",
    "81.29": "Autres activités de nettoyage",
    "81.30": "Services d'aménagement paysager",
    "82.11": "Services administratifs combinés de bureau",
    "82.19":
        "Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau",
    "82.20": "Activités de centres d'appels",
    "82.30": "Organisation de salons professionnels et congrès",
    "82.91":
        "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
    "82.92": "Activités de conditionnement",
    "82.99": "Autres activités de soutien aux entreprises n.c.a.",
    "84.11": "Administration publique générale",
    "84.12":
        "Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale",
    "84.13": "Administration publique (tutelle) des activités économiques",
    "84.21": "Affaires étrangères",
    "84.22": "Défense",
    "84.23": "Justice",
    "84.24": "Activités d'ordre public et de sécurité",
    "84.25": "Services du feu et de secours",
    "84.30": "Sécurité sociale obligatoire",
    "85.10": "Enseignement pré-primaire",
    "85.20": "Enseignement primaire",
    "85.31": "Enseignement secondaire général",
    "85.32": "Enseignement secondaire technique ou professionnel",
    "85.41": "Enseignement post-secondaire non supérieur",
    "85.42": "Enseignement supérieur",
    "85.51": "Enseignement de disciplines sportives et d'activités de loisirs",
    "85.52": "Enseignement culturel",
    "85.53": "Enseignement de la conduite",
    "85.59": "Enseignements divers",
    "85.60": "Activités de soutien à l'enseignement",
    "86.10": "Activités hospitalières",
    "86.21": "Activité des médecins généralistes",
    "86.22": "Activité des médecins spécialistes",
    "86.23": "Pratique dentaire",
    "86.90": "Autres activités pour la santé humaine",
    "87.10": "Hébergement médicalisé",
    "87.20":
        "Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes",
    "87.30": "Hébergement social pour personnes âgées ou handicapées physiques",
    "87.90": "Autres activités d'hébergement social",
    "88.10":
        "Action sociale sans hébergement pour personnes âgées et pour personnes handicapées",
    "88.91": "Action sociale sans hébergement pour jeunes enfants",
    "88.99": "Autre action sociale sans hébergement n.c.a.",
    "90.01": "Arts du spectacle vivant",
    "90.02": "Activités de soutien au spectacle vivant",
    "90.03": "Création artistique",
    "90.04": "Gestion de salles de spectacles",
    "91.01": "Gestion des bibliothèques et des archives",
    "91.02": "Gestion des musées",
    "91.03":
        "Gestion des sites et monuments historiques et des attractions touristiques similaires",
    "91.04":
        "Gestion des jardins botaniques et zoologiques et des réserves naturelles",
    "92.00": "Organisation de jeux de hasard et d'argent",
    "93.11": "Gestion d'installations sportives",
    "93.12": "Activités de clubs de sports",
    "93.13": "Activités des centres de culture physique",
    "93.19": "Autres activités liées au sport",
    "93.21": "Activités des parcs d'attractions et parcs à thèmes",
    "93.29": "Autres activités récréatives et de loisirs",
    "94.11": "Activités des organisations patronales et consulaires",
    "94.12": "Activités des organisations professionnelles",
    "94.20": "Activités des syndicats de salariés",
    "94.91": "Activités des organisations religieuses",
    "94.92": "Activités des organisations politiques",
    "94.99": "Activités des organisations associatives n.c.a.",
    "95.11": "Réparation d'ordinateurs et d'équipements périphériques",
    "95.12": "Réparation d'équipements de communication",
    "95.21": "Réparation de produits électroniques grand public",
    "95.22":
        "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
    "95.23": "Réparation de chaussures et d'articles en cuir",
    "95.24": "Réparation de meubles et d'équipements du foyer",
    "95.25": "Réparation d'articles d'horlogerie et de bijouterie",
    "95.29": "Réparation d'autres biens personnels et domestiques",
    "96.01": "Blanchisserie-teinturerie",
    "96.02": "Coiffure et soins de beauté",
    "96.03": "Services funéraires",
    "96.04": "Entretien corporel",
    "96.09": "Autres services personnels n.c.a.",
    "97.00":
        "Activités des ménages en tant qu'employeurs de personnel domestique",
    "98.10":
        "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre",
    "98.20":
        "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre",
    "99.00": "Activités des organisations et organismes extraterritoriaux",
};
