import {
    departmentRegionTypeMapping,
    interestColors,
    orderPhase,
    orderPriority,
    Sizes,
} from "@/types";
import { orderState } from "@/types/order";
import { Timestamp } from "firebase/firestore";
import { getUserFromId } from "./firebase";

export function convertPriority(id: any) {
    return orderPriority.find((p) => p.id === id) || null;
}

export function getDateObjFromDateTimeStrings(
    date: string | Date,
    time: string
): Date {
    let day,
        month,
        year = null;
    if (date && typeof date === "string") {
        [day, month, year] = date.split("/").map(Number);
    } else if (date instanceof Date) {
        day = date.getDate();
        month = date.getMonth() + 1;
        year = date.getFullYear();
    }

    let hours, minutes;
    if (time && typeof time === "string") {
        if (time.includes("h")) {
            [hours, minutes] = time.split("h").map(Number);
        } else {
            [hours, minutes] = time.split(":").map(Number);
        }
    }

    return year && month
        ? new Date(year, month - 1, day, hours, minutes)
        : new Date("01/01/1970");
}

export function getDateObjFromDateString(date: string | Date): Date {
    let day, month, year;

    if (date && typeof date === "string") {
        [day, month, year] = date.split("/").map(Number);
    } else if (typeof date === "object") {
        day = date.getDate();
        month = date.getMonth() + 1;
        year = date.getFullYear();
    } else {
        day = month = year = null;
    }

    return year && month && day
        ? new Date(year, month - 1, day)
        : new Date("01/01/1970");
}

export function getSizeStringFromCode(value: string) {
    const size = Sizes.find((size) => size.id === value);
    return size ? size.name : value;
}

export function convertPhase(id: any) {
    return orderPhase.find((p) => p.id === id) || null;
}

export function convertState(id: any) {
    return orderState.find((p) => p.id === id) || null;
}

export function formatPhoneNumber(phoneNumber: string) {
    if (phoneNumber === null) return "";
    if (typeof phoneNumber !== "string") return "ERROR";
    return (
        phoneNumber
            .replace(/\s/g, "")
            .match(/.{1,2}/g)
            ?.join(" ") || ""
    );
}

export function formatTimestampTommhhddmmyyyy(timestamp: Timestamp) {
    const date = timestamp.toDate();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function formatPrice(price: string | number | null | undefined) {
    if (price == null || price == "") return "";
    if (price === "NC" || price === "N/A") return price;
    price = String(price)
        .replace(/\s/g, "")
        .replace(/€/g, "")
        .replace(/[.]/g, ",");
    const [integerPart, decimalPart] = price.split(",");
    const reversedIntegerPart = integerPart.split("").reverse().join("");
    const formattedIntegerPart =
        reversedIntegerPart.match(/.{1,3}/g)?.join(" ") || "";
    const formattedPrice = formattedIntegerPart.split("").reverse().join("");
    return decimalPart
        ? `${formattedPrice},${decimalPart} €`
        : `${formattedPrice} €`;
}

export function formatSiret(siret: string) {
    if (siret.length === 14) {
        return siret.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, "$1 $2 $3 $4");
    } else if (siret.length === 9) {
        return siret.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
    } else {
        return siret;
    }
}

export async function getTrigramFromId(id: string) {
    if (!id) return "ERROR";
    const user = (await getUserFromId(id)).data();
    return `${user.FirstName.charAt(0)}${user.LastName.charAt(
        0
    )}${user.LastName.charAt(1)}`.toUpperCase();
}

export function getTrigram(firstName: string, lastName: string) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}${lastName.charAt(
        1
    )}`.toUpperCase();
}

export function transformIntinRegion(departmentCode: string) {
    const mapping = departmentRegionTypeMapping[departmentCode];
    if (mapping) {
        return mapping.region;
    } else {
        throw new Error(`Invalid department code: ${departmentCode}`);
    }
}

export function getColorFromProspectInterest(interest: number) {
    switch (interest) {
        case 1:
            return interestColors[0];
        case 2:
            return interestColors[1];
        case 3:
            return interestColors[2];
        default:
            return "transparent";
    }
}

export function getDepartmentNumberFromPostalCode(postalCode: any) {
    if (!postalCode) return "N/A";
    if (typeof postalCode === "number") {
        postalCode = postalCode.toString();
    }
    if (postalCode.slice(0, 2) === "97" || postalCode.slice(0, 2) === "98") {
        return postalCode.slice(0, 3);
    }
    return postalCode.slice(0, 2);
}
