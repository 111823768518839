<template>
    <List
        :headers="headers"
        :items="users"
        :sort-by="[{ key: 'Name', order: 'asc' }]"
        :showButton="true"
    >
        <template #button="{ item }">
            <v-btn
                class="button-bg-white list-button"
                icon="mdi-call-made"
                @click.stop="goToReportingClient(item)"
            />
        </template>
    </List>
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import router from "@/router";
import { UserList } from "@/types";
import { DocumentData } from "firebase/firestore";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

defineProps({
    users: Array as () => UserList[] | DocumentData[],
});

const goToReportingClient = (item: any) => {
    router.push({
        name: "reportingClient",
        params: { id: item.id },
    });
};

const headers = [
    { title: $t("reporting.clientName"), value: "Name" },
    { title: $t("reporting.totalCalls"), value: "totalCalls" },
    { title: $t("reporting.totalPickedUp"), value: "totalPickedUp" },
    {
        title: $t("reporting.totalDecisionMakers"),
        value: "totalDecisionMakers",
    },
    { title: $t("reporting.totalRdv"), value: "totalRdv" },
    { title: $t("reporting.rateOnPickedUp"), value: "rateOnPickedUp" },
    {
        title: $t("reporting.rateOnDecisionMakers"),
        value: "rateOnDecisionMakers",
    },
];
</script>

<style scoped>
.list-button {
    height: 38px !important;
    width: 38px !important;
    border: none !important;
    background-color: transparent !important;
}
</style>
