<template>
    <img class="logo" src="@/assets/YouScale-salesLogo.png" alt="Logo" />
    <v-card class="login-card" elevation="4">
        <v-card-title class="text-center">{{ $t("login.login") }}</v-card-title>
        <v-card-text>
            <v-form @submit.prevent="login">
                <v-text-field
                    v-model="email"
                    :label="$t('login.email')"
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    :label="$t('login.password')"
                    :type="showPassword ? 'text' : 'password'"
                    :append-inner-icon="
                        showPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append-inner="showPassword = !showPassword"
                ></v-text-field>
                <v-btn color="primary" type="submit" block height="50px">{{
                    $t("login.login")
                }}</v-btn>
            </v-form>
        </v-card-text>
        <v-alert v-if="error" class="error" type="error" outlined>{{
            error
        }}</v-alert>
    </v-card>
    <v-btn color="#dd2733" class="forgot-button" @click="forgot">
        {{ $t("login.forgot") }}
    </v-btn>
</template>

<script setup lang="ts">
import router from "@/router";
import {
    getAuth,
    getIdTokenResult,
    onAuthStateChanged,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const email = ref("");
const password = ref("");
const showPassword = ref(false);
const error = ref<string | null>(null);
const auth = getAuth();

const currentUser = ref(auth.currentUser);

onAuthStateChanged(auth, (user) => {
    currentUser.value = user;
});

function forgot() {
    router.push("/forgot");
}

async function login() {
    try {
        await signInWithEmailAndPassword(auth, email.value, password.value);
        if (!auth.currentUser) {
            throw new Error("No user found");
        }
        const idTokenResult = await getIdTokenResult(auth.currentUser);
        email.value = "";
        password.value = "";
        error.value = null;
        if (idTokenResult.claims.firstLogin) {
            router.push("/change-password");
        } else {
            router.push("/");
        }
    } catch (err: unknown) {
        if (err instanceof Error) {
            error.value = err.message;
            console.error("Login error:", err);
        } else if (typeof err === "string") {
            error.value = err;
            console.error("Generic Login error:", err);
        } else {
            error.value = "An unknown error occurred";
            console.error("Unknown Login error:", err);
        }
    }
}
</script>

<style scoped>
.logo {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: auto;
}
.forgot-button {
    top: 57%;
    left: 82%;
}
.login-card {
    top: 30%;
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}
</style>
