<template>
    <v-row class="pb-0 pt-0" justify="end">
        <v-col
            v-if="selectedInterval === '0'"
            cols="4"
            class="pb-0 pt-0"
            style="height: 40px"
        >
            <TextField
                v-model="internalStartDate"
                :label="$t('components.startDate')"
                type="date"
                :disableMessage="true"
            />
        </v-col>
        <v-col
            v-if="selectedInterval === '0'"
            cols="4"
            class="pb-0 pt-0"
            style="height: 40px"
        >
            <TextField
                v-model="internalEndDate"
                :label="$t('components.endDate')"
                type="date"
                :disableMessage="true"
            />
        </v-col>
        <v-col :cols="selectedInterval === '0' ? 4 : 8" class="pb-0 pt-0">
            <SelectField
                v-model="selectedInterval"
                :items="dateIntervals"
                :item-title="displayText"
                item-value="id"
                :label="label ? label : fieldLabel"
                :returnObject="false"
                disableMessage
                noAlphaSort
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { SelectField, TextField } from "@/components/customVuetify";
import { IdName } from "@/types";
import {
    format,
    startOfMonth,
    startOfQuarter,
    startOfToday,
    startOfWeek,
    subDays,
} from "date-fns";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
    label: {
        type: String,
    },
});

const emit = defineEmits(["update:start-date", "update:end-date"]);

const selectedInterval = ref<string | null>(null);
const internalStartDate = ref<string | null>(props.startDate);
const internalEndDate = ref<string | null>(props.endDate);

const dateIntervals = ref<IdName[]>([
    { id: "1", name: $t("components.today") },
    { id: "2", name: $t("components.yesterday") },
    { id: "3", name: $t("components.thisWeek") },
    { id: "4", name: $t("components.last7Days") },
    { id: "5", name: $t("components.thisMonth") },
    { id: "6", name: $t("components.last30Days") },
    { id: "7", name: $t("components.thisQuarter") },
    { id: "8", name: $t("components.last3Months") },
    { id: "0", name: $t("components.freeSelection") },
]);

const fieldLabel = ref("");

const displayText = computed(
    () => (size: IdName | null) => size ? size.name : ""
);

if (!props.label) {
    fieldLabel.value = $t("components.date");
}

watch(internalStartDate, () => {
    emit("update:start-date", internalStartDate.value);
});

watch(internalEndDate, () => {
    emit("update:end-date", internalEndDate.value);
});

watch(selectedInterval, (newInterval) => {
    const today = startOfToday();
    switch (newInterval) {
        case "1": {
            internalStartDate.value = format(today, "yyyy-MM-dd");
            internalEndDate.value = format(today, "yyyy-MM-dd");
            break;
        }
        case "2": {
            const yesterday = subDays(today, 1);
            internalStartDate.value = format(yesterday, "yyyy-MM-dd");
            internalEndDate.value = format(yesterday, "yyyy-MM-dd");
            break;
        }
        case "3": {
            const thisWeekStart = startOfWeek(today, { weekStartsOn: 1 });
            internalStartDate.value = format(thisWeekStart, "yyyy-MM-dd");
            internalEndDate.value = format(today, "yyyy-MM-dd");
            break;
        }
        case "4": {
            const last7DaysStart = subDays(today, 6);
            internalStartDate.value = format(last7DaysStart, "yyyy-MM-dd");
            internalEndDate.value = format(today, "yyyy-MM-dd");
            break;
        }
        case "5": {
            const thisMonthStart = startOfMonth(today);
            internalStartDate.value = format(thisMonthStart, "yyyy-MM-dd");
            internalEndDate.value = format(today, "yyyy-MM-dd");
            break;
        }
        case "6": {
            const last30DaysStart = subDays(today, 29);
            internalStartDate.value = format(last30DaysStart, "yyyy-MM-dd");
            internalEndDate.value = format(today, "yyyy-MM-dd");
            break;
        }
        case "7": {
            const thisQuarterStart = startOfQuarter(today);
            internalStartDate.value = format(thisQuarterStart, "yyyy-MM-dd");
            internalEndDate.value = format(today, "yyyy-MM-dd");
            break;
        }
        case "8": {
            const last3MonthsStart = subDays(today, 89);
            internalStartDate.value = format(last3MonthsStart, "yyyy-MM-dd");
            internalEndDate.value = format(today, "yyyy-MM-dd");
            break;
        }
        default:
            internalStartDate.value = props.startDate;
            internalEndDate.value = props.endDate;
            break;
    }
});

const findMatchingInterval = () => {
    const today = startOfToday();
    const intervals = [
        { id: "1", start: today, end: today },
        { id: "2", start: subDays(today, 1), end: subDays(today, 1) },
        { id: "3", start: startOfWeek(today, { weekStartsOn: 1 }), end: today },
        { id: "4", start: subDays(today, 6), end: today },
        { id: "5", start: startOfMonth(today), end: today },
        { id: "6", start: subDays(today, 29), end: today },
        { id: "7", start: startOfQuarter(today), end: today },
        { id: "8", start: subDays(today, 89), end: today },
    ];

    for (const interval of intervals) {
        if (
            format(interval.start, "yyyy-MM-dd") === internalStartDate.value &&
            format(interval.end, "yyyy-MM-dd") === internalEndDate.value
        ) {
            selectedInterval.value = interval.id;
            return;
        }
    }

    selectedInterval.value = "0";
};

watch(
    () => props.startDate,
    () => {
        internalStartDate.value = props.startDate;
        findMatchingInterval();
    }
);

watch(
    () => props.endDate,
    () => {
        internalEndDate.value = props.endDate;
        findMatchingInterval();
    }
);

onMounted(findMatchingInterval);
</script>
