<template>
    <div class="container">
        <Title :title="$t('extracts.titleMails')" />
        <Filters :filteredMails="filteredMails" @applyFilters="applyFilters" />
        <List :mails="filteredMails" :loading="isLoading" />
    </div>
</template>

<script setup lang="ts">
import { getTrigram } from "@/commons/convertion";
import {
    getCurrentClient,
    getOrderFromId,
    getUserFromId,
} from "@/commons/firebase";
import { getMailForDate } from "@/commons/reporting";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { store } from "@/store";
import { doc, getDoc } from "firebase/firestore";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const mails = ref<any>([]);
const isLoading = ref(false);
const filters = ref({
    Type: "",
    startDate: new Date(),
    endDate: new Date(),
    ArchivedMails: true,
});

const filteredMails = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return mails.value;
    }
    return mails.value.filter((client: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                typeof value === "string" &&
                client[key] &&
                typeof client[key] === "string"
            ) {
                return client[key].toLowerCase().includes(value.toLowerCase());
            }
            return true;
        })
    );
});

async function applyFilters(newFilters: any) {
    filters.value = newFilters;
    await getData();
}

async function getData() {
    isLoading.value = true;
    const mailList = await getMailForDate(
        getCurrentClient().value.id,
        filters.value.startDate,
        filters.value.endDate,
        filters.value.ArchivedMails
    );

    const allMails = await Promise.all(
        mailList.map(async (mail: any) => {
            const user = (await getUserFromId(mail.EmployeeId)).data();
            const order = (await getOrderFromId(mail.OrderId)).data();

            let prospectRef = doc(
                db,
                "Orders",
                mail.OrderId,
                "Prospects",
                mail.ProspectId
            );
            let prospectSnap = await getDoc(prospectRef);
            let prospectData = prospectSnap.data();

            if (prospectData === undefined) {
                prospectRef = doc(
                    db,
                    "ArchivedOrders",
                    mail.OrderId,
                    "ArchivedProspects",
                    mail.ProspectId
                );
                prospectSnap = await getDoc(prospectRef);
                prospectData = prospectSnap.data();
            }

            return {
                Company: prospectData?.Name,
                Contact: mail.FirstName + " " + mail.LastName.toUpperCase(),
                FirstName: mail.FirstName,
                LastName: mail.LastName,
                Position: mail.Position,
                Phone: mail.Phone,
                Mail: mail.Mail,
                Order: order?.Name,
                OrderId: mail.OrderId,
                DocId: mail.DocId,
                ProspectId: mail.ProspectId,
                Comments: mail.Comments,
                CallDate: mail.Date.toDate().toLocaleDateString("fr-FR"),
                Employee: getTrigram(user?.FirstName, user?.LastName),
                PostalCode: prospectData?.PostalCode,
                City: prospectData?.City,
                Timestamp: mail.Date.toMillis(),
            };
        })
    );

    // Filtrer pour garder uniquement le mail le plus récent par ProspectId
    const uniqueMails = allMails.reduce((acc: any, current: any) => {
        const existing = acc.find(
            (mail: any) => mail.ProspectId === current.ProspectId
        );
        if (!existing || existing.Timestamp < current.Timestamp) {
            // Remplacer l'entrée si elle est plus ancienne
            return acc
                .filter((mail: any) => mail.ProspectId !== current.ProspectId)
                .concat(current);
        }
        return acc;
    }, []);

    mails.value = uniqueMails;
    isLoading.value = false;
}

watch(
    () => store.state.currentClient,
    (newClient) => {
        if (newClient) {
            getData();
        }
    }
);

onMounted(async () => {
    await getData();
});
</script>
