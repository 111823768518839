<template>
    <Dialog
        :title="$t('db.importProspects')"
        width="40%"
        :closable="!isLoading"
        :clickOutside="!isLoading"
        @submit="processFile"
        @close="$emit('close')"
    >
        <template #text>
            <v-row class="alert alert-row">
                <v-col class="text-center alert">
                    <v-icon class="pr-4 red">mdi-alert-outline</v-icon>
                    <Span class="bold">{{ $t("db.warningImport") }}</Span>
                </v-col>
            </v-row>
            <v-file-input
                v-model="selectedFile"
                :accept="'.xlsx, .xls, .csv, .ods'"
                density="compact"
                :label="$t('common.chooseAFile')"
                variant="outlined"
                :rules="[rules.required]"
                :bg-color="`rgb(var(--v-theme-field))`"
                :show-size="true"
            />
        </template>
        <template #actions>
            <div class="center-content">
                <v-btn
                    class="button-bg-secondary"
                    :text="$t('common.import')"
                    prepend-icon="mdi-file-export-outline"
                    type="submit"
                    :disabled="!selectedFile"
                    :loading="isLoading"
                />
            </div>
            <AlertDuplicate
                v-model="isAlertDuplicateOpen"
                :prospectDB="currentProspectDB"
                :prospectImport="currentDuplicateProspect"
                @rejectAll="
                    isRejectAll = true;
                    handleDuplicate('reject');
                "
                @reject="handleDuplicate('reject')"
                @accept="handleDuplicate('accept')"
                @acceptAll="
                    isAcceptAll = true;
                    handleDuplicate('accept');
                "
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import {
    getDateObjFromDateString,
    getDateObjFromDateTimeStrings,
} from "@/commons/convertion";
import { AlertDuplicate } from "@/components";
import { Span } from "@/components/customVuetify";
import Dialog from "@/components/customVuetify/Dialog.vue";
import { db } from "@/main";
import rules from "@/rules";
import { store } from "@/store";
import { Call, IdName, Salutations, Trigrams } from "@/types";
import * as ExcelJS from "exceljs";
import {
    addDoc,
    collection,
    doc,
    getDocs,
    query,
    setDoc,
    where,
} from "firebase/firestore";
import Papa from "papaparse";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t: $t } = useI18n();

const emit = defineEmits(["close", "importSuccess", "importFail"]);

const route = useRoute();
const orderId = ref(route.params.id as string);
const isLoading = ref(false);
let resolveDuplicate: (value: string) => void;

const customHeaders = [
    "None",
    "None",
    "Name",
    "Phone",
    "Comment",
    "None",
    "None",
    "None",
    "Region",
    "Size",
    "APECode",
    "Turnover",
    "Address",
    "City",
    "PostalCode",
    "Website",
    "Mail",
    "FirstName",
    "LastName",
    "Position",
    "PersonalMail",
    "PersonalPhone",
];

const isAlertDuplicateOpen = ref(false);
const selectedFile = ref<File | undefined>();
const currentClient = ref<IdName | null>(null);
const isRejectAll = ref(false);
const isAcceptAll = ref(false);

const currentDuplicateProspect = {
    Name: "",
    PostalCode: "",
    Address: "",
};

const currentProspectDB = {
    Name: "",
    PostalCode: "",
    Address: "",
};

function handleDuplicate(action: string) {
    isAlertDuplicateOpen.value = false;
    if (resolveDuplicate) {
        resolveDuplicate(action);
    }
}

async function processFile() {
    isLoading.value = true;
    try {
        if (!selectedFile.value) throw new Error("No file selected");
        const fileType = selectedFile.value.name.split(".").pop();
        if (fileType === "csv") {
            Papa.parse(selectedFile.value, {
                complete: async function (results) {
                    await processData(results.data);
                },
            });
        } else if (["xls", "xlsx", "ods"].includes(fileType as string)) {
            const workbook = new ExcelJS.Workbook();
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                const buffer = e.target?.result as ArrayBuffer;
                await workbook.xlsx.load(buffer);
                const worksheet = workbook.worksheets[0];
                const jsonData = worksheet.getSheetValues().slice(1);
                await processData(jsonData);
            };
            fileReader.readAsArrayBuffer(selectedFile.value);
        } else {
            emit("importFail");
        }
    } catch (error) {
        emit("importFail");
    }
}

function transformStringInNextAction(action: string): number {
    if (typeof action !== "string") {
        return 0;
    }
    if (action.startsWith("Ko") || action.startsWith("ko")) {
        return 4;
    } else if (action.startsWith("Call") || action.startsWith("call")) {
        return 1;
    } else if (action.startsWith("Mail") || action.startsWith("mail")) {
        return 3;
    } else if (
        action.startsWith("Rdv") ||
        action.startsWith("rdv") ||
        action.startsWith("meeting") ||
        action.startsWith("Meeting")
    ) {
        return 2;
    } else {
        return 0;
    }
}

function transformInitialsinId(initials: string): string {
    const operator = Trigrams.find(
        (operator) => operator.initials === initials
    );
    return operator ? operator.id : initials;
}

async function processData(jsonData: any[]) {
    const lengthHeaders = jsonData[0].length - 1;
    const numbercallMax = (lengthHeaders - 25 - 7) / 11;
    const data = jsonData.slice(1);

    const orderDocRef = doc(db, "Orders", orderId.value);
    const prospectsCollectionRef = collection(orderDocRef, "Prospects");

    for (const row of data) {
        if (row == undefined) {
            break;
        }
        if (
            row.every(
                (cell: any) => cell == null || cell === "" || /^\s*$/.test(cell)
            )
        ) {
            continue;
        }

        const contactDuringCall = {
            FirstName: row[22] ?? "",
            LastName: row[23] ?? "",
            Position: row[24] ?? "",
            Mail: row[25] ?? "",
            Phone: row[26] ?? "",
        };

        const allInfoCallDuringCall = ref<Call[]>([]);

        let prospect: Record<string, any> = {};
        console.log("prospect", row);
        customHeaders.forEach((header, index) => {
            prospect[header] = row[index] ?? null;
        });

        const ordersSnapshot = await getDocs(
            query(
                prospectsCollectionRef,
                where("Name", "==", prospect["Name"]),
                where("Phone", "==", prospect["Phone"]),
                where("Address", "==", prospect["Address"])
            )
        );

        for (let i = 0; i < numbercallMax; i++) {
            if (row[31 + i * 11] != null && row[31 + i * 11] != undefined) {
                const appointmentDate = row[26 + numbercallMax * 11 + 2];
                const appointmentTime = row[26 + numbercallMax * 11 + 3];
                const appointmentDateObj = getDateObjFromDateTimeStrings(
                    appointmentDate,
                    appointmentTime
                );

                const callDate = row[28 + i * 11];
                const callDateObj = getDateObjFromDateString(callDate);

                if (!currentClient.value) {
                    return;
                }

                const infoCallDuringCall: Call = {
                    Salutation: 0 as Salutations,
                    DateToBeDefined: false,
                    Salesman: "",
                    AppointmentAddress: "",
                    AppointmentDate: appointmentDateObj,
                    AssociateId: "",
                    CallAgainDate: null,
                    ClientId: currentClient.value.id,
                    Comments: row[35 + i * 11] ?? "",
                    Date: callDateObj,
                    DecisionMaker: row[32 + i * 11] == "Oui" ? true : false,
                    EmployeeId: transformInitialsinId(row[27 + i * 11]) ?? "",
                    FirstCall: false,
                    FirstName: row[lengthHeaders - 4] ?? "",
                    LastName: row[lengthHeaders - 3] ?? "",
                    OrderId: orderId.value,
                    Mail: row[lengthHeaders - 1] ?? "",
                    NextAction: transformStringInNextAction(row[33 + i * 11]),
                    Phone: row[lengthHeaders] ?? "",
                    PickedUp: row[31 + i * 11] == "Oui" ? true : false,
                    Position: row[lengthHeaders - 2] ?? "",
                    ProspectInterest: 0 as any,
                    RdvType: 0,
                    SendMail: row[37 + i * 11] ?? false,
                };

                if (infoCallDuringCall.NextAction != 2) {
                    infoCallDuringCall.AppointmentDate = null;
                    infoCallDuringCall.FirstName = "";
                    infoCallDuringCall.LastName = "";
                    infoCallDuringCall.Mail = "";
                    infoCallDuringCall.Phone = "";
                    infoCallDuringCall.Position = "";
                }

                allInfoCallDuringCall.value.push(infoCallDuringCall);
            }
        }
        if (!ordersSnapshot.empty) {
            const prospectDoc = ordersSnapshot.docs[0];

            const prospectDocRef = doc(
                db,
                "Orders",
                orderId.value,
                "Prospects",
                prospectDoc.id
            );

            const contactsCollectionRef = collection(
                prospectDocRef,
                "Contacts"
            );

            if (
                contactDuringCall.FirstName ||
                contactDuringCall.LastName ||
                contactDuringCall.Phone ||
                contactDuringCall.Mail ||
                contactDuringCall.Position
            ) {
                await addDoc(contactsCollectionRef, contactDuringCall);
            }

            let callNumber = 0;
            allInfoCallDuringCall.value.forEach(async (call) => {
                callNumber++;
                if (callNumber === 1) {
                    call.FirstCall = true;
                }
                const callDocRef = doc(
                    db,
                    "Orders",
                    orderId.value,
                    "Prospects",
                    prospectDoc.id,
                    "Calls",
                    String(callNumber)
                );
                await setDoc(callDocRef, call);
            });
            allInfoCallDuringCall.value = [];
            continue;
        }
    }
    isLoading.value = false;
    isRejectAll.value = false;
    isAcceptAll.value = false;
    emit("importSuccess");
    emit("close");
}

onMounted(() => {
    currentClient.value = store.state.currentClient;
});
</script>

<style scoped>
.alert {
    padding: 0;
    height: auto !important;
}

.alert-row {
    padding-bottom: 12px !important;
}
</style>
