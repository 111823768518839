import { db } from "@/main";
import { store } from "@/store";
import { Client, IdName, Order, UserDB } from "@/types";
import { getAuth, getIdTokenResult } from "firebase/auth";
import {
    DocumentData,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import { computed, ref, watchEffect } from "vue";

export async function getClientList() {
    const auth = getAuth();
    if (!auth.currentUser) {
        return [];
    }
    const idTokenResult = await getIdTokenResult(auth.currentUser);
    if (idTokenResult.claims.admin) {
        const clientsSnapshot = await getDocs(collection(db, "Clients"));
        const clientList = clientsSnapshot.docs.map((doc) => ({
            DocId: doc.id,
            ...doc.data(),
        }));
        return clientList;
    } else {
        const user = await getCurrentUser();
        const assignedClients = user.AssignedClients;

        if (!assignedClients) {
            return [];
        }

        const clientList = [];
        for (const clientId of assignedClients) {
            const clientDoc = await getDoc(doc(db, "Clients", clientId));
            clientList.push({
                DocId: clientDoc.id,
                ...clientDoc.data(),
            });
        }
        return clientList;
    }
}

export async function getUserFromId(userId: string) {
    const userRef = doc(db, "Users", userId);
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
        throw new Error(`User with id ${userId} not found`);
    }
    return userDoc;
}

export async function getOrderFromId(orderId: string) {
    const orderRef = doc(db, "Orders", orderId);
    const orderDoc = await getDoc(orderRef);
    if (!orderDoc.exists()) {
        const archivedOrderDoc = await getDoc(
            doc(db, "ArchivedOrders", orderId)
        );
        if (archivedOrderDoc.exists()) {
            return archivedOrderDoc;
        }
        throw new Error(`Order with id ${orderId} not found`);
    }
    return orderDoc;
}

export async function getOrderListFromClients(clientIds?: string[]) {
    const orderList = ref<Order[]>([]);

    let ordersQuery;

    if (!clientIds || clientIds.length === 0) {
        ordersQuery = collection(db, "Orders");
    } else {
        ordersQuery = query(
            collection(db, "Orders"),
            where("ClientId", "in", clientIds)
        );
    }

    const ordersSnapshot = await getDocs(ordersQuery);
    orderList.value = ordersSnapshot.docs.map(
        (doc) =>
            ({
                DocId: doc.id,
                ...doc.data(),
            } as Order)
    );

    return orderList;
}

export async function getOrderListFromClientsNameId(clientIds?: string[]) {
    const orders = await getOrderListFromClients(clientIds);
    const idNameList = ref<IdName[]>([]);

    watchEffect(() => {
        idNameList.value = orders.value.map(
            (o): IdName => ({ id: o.DocId, name: o.Name })
        );
    });

    return idNameList;
}

export async function getActiveOrderList() {
    const orderList = ref<Order[]>([]);
    const currentClientId = getCurrentClient().value.id;

    const ordersSnapshot = await getDocs(
        query(
            collection(db, "Orders"),
            where("ClientId", "==", currentClientId)
        )
    );
    orderList.value = ordersSnapshot.docs.map(
        (doc) =>
            ({
                DocId: doc.id,
                ...doc.data(),
            } as Order)
    );
    return orderList;
}

export async function getActiveOrderListNameId() {
    const orders = await getActiveOrderList();
    const idNameList = ref<IdName[]>([]);

    watchEffect(() => {
        idNameList.value = orders.value.map(
            (o): IdName => ({ id: o.DocId, name: o.Name })
        );
    });

    return idNameList;
}

export async function getAllOrderList() {
    const orderList = ref<Order[]>([]);

    const ordersSnapshot = await getDocs(collection(db, "Orders"));
    orderList.value = ordersSnapshot.docs.map(
        (doc) =>
            ({
                DocId: doc.id,
                ...doc.data(),
            } as Order)
    );

    return orderList;
}

export async function getAllOrderListNameId() {
    const orders = await getAllOrderList();
    const idNameList = ref<IdName[]>([]);

    watchEffect(() => {
        idNameList.value = orders.value.map(
            (o): IdName => ({ id: o.DocId, name: o.Name })
        );
    });

    return idNameList;
}

export async function getUserListFromClient(clientId: string) {
    const usersSnapshot = await getDocs(
        query(collection(db, "Users"), where("ClientId", "==", clientId))
    );
    const userList = usersSnapshot.docs.map(
        (doc) =>
            ({
                DocId: doc.id,
                ...doc.data(),
            } as UserDB)
    );
    return userList;
}

export async function getCalendlyUserListFromClient(clientId: string) {
    const userList = await getUserListFromClient(clientId);

    const calendlyUserList = userList.filter(
        (user) =>
            user.Calendly && user.Calendly.AccessToken && user.Calendly.Link
    );

    return calendlyUserList;
}

export async function getClientListNameId(): Promise<IdName[]> {
    const clients = await getClientList();
    const idNameList = clients.map(
        (c: any): IdName => ({ id: c.DocId, name: c.Name })
    );
    return idNameList;
}

export function getCurrentClient() {
    const currentClient = computed(() => store.state.currentClient);
    return currentClient;
}

export async function getCurrentUser() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        const userDocRef = doc(db, "Users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
            const userData = {
                DocId: userDoc.id,
                ...userDoc.data(),
            };
            return userData as UserDB;
        }
    }
    return {} as UserDB;
}

export async function getUserCalendlyAccessToken(userId: string) {
    const userRef = doc(db, "Users", userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
        const user = userDoc.data();
        if (user && user.Calendly && user.Calendly) {
            const accessToken = user.Calendly.AccessToken;
            return accessToken as string;
        }
    }

    return "none";
}

export async function getCurrentUserClientNameId() {
    const user = await getCurrentUser();

    if (user) {
        const clientList = await getClientList();

        const client = clientList.find((c) => c.DocId == user.ClientId);

        return client;
    }

    return {} as Client;
}

export async function getUserClientNameId(user: DocumentData) {
    const clientList = await getClientList();
    const client = clientList.find((c) => c.DocId == user.ClientId) as any;
    if (!client) {
        return { id: "", name: "" };
    }
    return { id: client.DocId, name: client.Name };
}

export async function getUserLastFirstNameFromId(userId: string) {
    if (!userId) {
        return "ERROR ERROR";
    }
    const userRef = doc(db, "Users", userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
        const user = userDoc.data();
        if (user) {
            return user.LastName + " " + user.FirstName;
        }
    }

    return "";
}

export async function isTovia() {
    const user = await getCurrentUser();
    if (user.ClientId == "6X8mesClIltu3m4vtIae") {
        return true;
    }
    return false;
}

export async function isAdmin() {
    const auth = getAuth();
    if (!auth.currentUser) return false;
    const idTokenResult = await getIdTokenResult(auth.currentUser);
    if (idTokenResult.claims.admin) return true;
    return false;
}

export async function getAssignationsForClient(clientId: string) {
    const usersQuery = query(
        collection(db, "Users"),
        where("AssignedClients", "array-contains", clientId)
    );
    const usersSnapshot = await getDocs(usersQuery);

    const assignations = usersSnapshot.docs.map(
        (doc) =>
            ({
                id: doc.id,
                name: doc.data().FirstName + " " + doc.data().LastName,
            } as IdName)
    );

    return assignations;
}

export async function getYSTeam(disabled: boolean): Promise<UserDB[]> {
    const usersQuery = query(
        collection(db, "Users"),
        where("ClientId", "==", "MoUfzDUmvDXO0TXsizsJ")
    );

    const usersSnapshot = await getDocs(usersQuery);

    const users = usersSnapshot.docs.map(
        (doc) =>
            ({
                DocId: doc.id,
                ...doc.data(),
            } as UserDB)
    );

    if (!disabled) {
        return users.filter((u) => u.Disabled !== true);
    }

    return users;
}

export async function getYSTeamIdName(disabled: boolean) {
    const users = await getYSTeam(disabled);
    const idNameList = users.map(
        (u): IdName => ({ id: u.DocId, name: u.FirstName + " " + u.LastName })
    );
    return idNameList;
}
