<template>
    <Dialog
        v-model="createOrderOpen"
        :title="$t('orders.create')"
        width="65vw"
        :clickOutside="false"
        @submit="createOrder"
        @close="close()"
    >
        <template #text>
            <Transition :name="transitionName" mode="out-in">
                <div :key="transitionKey">
                    <div v-if="currentIndex === 0">
                        <v-row>
                            <v-col cols="6">
                                <PopupTextField
                                    v-model="order.Name"
                                    :label="$t('orders.orderName')"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                            <v-col cols="6">
                                <SectionField
                                    v-model="order.Sections"
                                    :label="$t('orders.section')"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <DivisionField
                                    v-model="order.Divisions"
                                    :sections="order.Sections"
                                    :disabled="!order.Sections"
                                    :label="$t('orders.division')"
                                    multiple
                                    @newval="setNewValue($event, 'Divisions')"
                                />
                            </v-col>
                            <v-col cols="6">
                                <GroupField
                                    v-model="order.Groups"
                                    multiple
                                    :divisions="order.Divisions"
                                    :disabled="
                                        order.Divisions === undefined ||
                                        order.Divisions.length === 0
                                    "
                                    :label="$t('orders.group')"
                                    @newval="setNewValue($event, 'Groups')"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <ClassField
                                    v-model="order.Classes"
                                    multiple
                                    :groups="order.Groups"
                                    :disabled="
                                        order.Groups === undefined ||
                                        order.Groups.length === 0
                                    "
                                    :label="$t('orders.class')"
                                    @newval="setNewValue($event, 'Classes')"
                                />
                            </v-col>
                            <v-col cols="6">
                                <ApeField
                                    v-model="order.ApeCodes"
                                    :label="$t('orders.apeCode')"
                                    clearable
                                    multiple
                                    restricted
                                    :classes="order.Classes"
                                    :disabled="
                                        order.Classes === undefined ||
                                        order.Classes.length === 0
                                    "
                                    @newval="setNewValue($event, 'ApeCodes')"
                                />
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else-if="currentIndex === 1">
                        <v-row>
                            <v-col cols="4">
                                <PopupTextField
                                    v-model="order.Turnover"
                                    :label="$t('orders.turnover')"
                                />
                            </v-col>
                            <v-col cols="4">
                                <SelectField
                                    v-model="order.SectorType"
                                    :items="orderSectorType"
                                    :label="$t('orders.sectorType')"
                                    item-title="name"
                                    item-value="id"
                                    :returnObject="false"
                                    :isI18n="true"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                            <v-col cols="4">
                                <SelectField
                                    v-model="order.Phase"
                                    :items="orderPhase"
                                    :label="$t('orders.phase')"
                                    item-title="name"
                                    item-value="id"
                                    :returnObject="false"
                                    :isI18n="true"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                style="padding-bottom: 20px !important"
                            >
                                <div class="tree">
                                    <Treeselect
                                        v-model="localisation"
                                        :multiple="true"
                                        :options="options"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col :cols="order.SectorType === 2 ? 4 : 6">
                                <SizeField
                                    v-model="companySizes"
                                    multiple
                                    clearable
                                    :label="$t('orders.companySizes')"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                            <v-col v-if="order.SectorType === 2" cols="4">
                                <SizeField
                                    v-model="municipalitySizes"
                                    multiple
                                    municipality
                                    clearable
                                    :label="$t('orders.municipalitySizes')"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                            <v-col :cols="order.SectorType === 2 ? 4 : 6">
                                <SelectField
                                    v-model="order.Priority"
                                    :items="orderPriority"
                                    :label="$t('orders.priority')"
                                    item-title="name"
                                    item-value="id"
                                    :returnObject="false"
                                    :isI18n="true"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <TextArea
                                    v-model="order.Comments"
                                    :label="$t('orders.comment')"
                                />
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </Transition>
        </template>
        <template #actions>
            <v-btn
                v-if="currentIndex === 1"
                class="button-bg-secondary"
                @click="previousPage"
                :text="$t('common.previous')"
                prepend-icon="mdi-arrow-left"
            />
            <v-spacer></v-spacer>
            <v-btn
                v-if="currentIndex === 0"
                class="button-bg-secondary"
                @click="nextPage"
                :text="$t('common.next')"
                prepend-icon="mdi-arrow-right"
                :disabled="
                    !order.Name ||
                    !order.Sections ||
                    !order.Divisions ||
                    !order.Groups ||
                    !order.Classes ||
                    !order.ApeCodes
                "
            />
            <v-btn
                v-else-if="currentIndex === 1"
                class="button-bg-secondary"
                type="submit"
                :loading="isLoading"
                :disabled="isLoading"
                :prepend-icon="isLoading ? 'mdi-loading' : 'mdi-check'"
                :text="isLoading ? '' : $t('common.create')"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { getCurrentClient, getCurrentUser } from "@/commons/firebase";
import { ApeField, SizeField } from "@/components";
import ClassField from "@/components/cpf/ClassField.vue";
import DivisionField from "@/components/cpf/DivisionField.vue";
import GroupField from "@/components/cpf/GroupField.vue";
import SectionField from "@/components/cpf/SectionField.vue";
import Dialog from "@/components/customVuetify/Dialog.vue";
import SelectField from "@/components/customVuetify/SelectField.vue";
import TextArea from "@/components/customVuetify/TextArea.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import { db } from "@/main";
import rules from "@/rules";
import {
    departmentRegionTypeMapping,
    orderPhase,
    orderPriority,
    orderSectorType,
} from "@/types";
import { defaultOrderData, Departments, Order } from "@/types/order";
import Treeselect from "@zanmato/vue3-treeselect";
import { addDoc, collection } from "firebase/firestore";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emit = defineEmits(["close"]);

const createOrderOpen = ref(false);

const order = ref({ ...defaultOrderData });
const currentUser = await getCurrentUser();
const currentClient = getCurrentClient();

const currentIndex = ref(0);
const transitionName = ref("");
const transitionKey = ref(0);

const companySizes = ref([]);
const municipalitySizes = ref([]);

const regions = new Map();
const departments = ref<Departments[]>([]);
const localisation = ref<(string | null)[]>([]);
const options = ref<
    {
        id: (string | null)[];
        label: string;
        children: { id: string; label: string }[];
    }[]
>([]);

function setNewValue(newVal: any, objToChange: keyof Order) {
    order.value[objToChange] = newVal;
}

Object.entries(departmentRegionTypeMapping).forEach(
    ([deptCode, { region, department, type }]) => {
        const category =
            type === "DOM"
                ? "Outre-Mer - DOM"
                : type === "TOM"
                ? "Outre-Mer - TOM"
                : "Métropole";

        if (!regions.has(category)) {
            regions.set(category, {
                id: category,
                label: category,
                children: [],
            });
        }

        if (
            !regions
                .get(category)
                .children.some((sub: any) => sub.id === region)
        ) {
            regions.get(category).children.push({
                id: region,
                label: region,
                children: [],
            });
        }

        const regionGroup = regions
            .get(category)
            .children.find((sub: any) => sub.id === region);
        regionGroup.children.push({ id: deptCode, label: department });
    }
);

const treeselectOptions = Array.from(regions.values());
const listSelectedCodes = ref<string[]>([]);

function close() {
    order.value = { ...defaultOrderData };
    localisation.value = [];
    currentIndex.value = 0;
    emit("close");
}

const isLoading = ref(false);

function changeState(name: string) {
    transitionName.value = name;
    transitionKey.value++;
}

function previousPage() {
    changeState("slide-left-fast");
    currentIndex.value--;
}

function nextPage() {
    changeState("slide-right-fast");
    currentIndex.value++;
}

async function createOrder() {
    try {
        isLoading.value = true;
        order.value.CreationDate = new Date();
        order.value.AuthorId = currentUser.DocId;
        order.value.ClientId = currentClient.value.id;
        order.value.Departments = listSelectedCodes.value.map((dept) => ({
            EmployeeID: "",
            DepartmentNumber: dept || "",
        }));
        municipalitySizes.value
            ? (order.value.Sizes = municipalitySizes.value)
            : (order.value.Sizes = companySizes.value);
        await addDoc(collection(db, "Orders"), order.value);
        close();
    } catch (error) {
        console.error("Error creating order: ", error);
    } finally {
        isLoading.value = false;
    }
}

watch(companySizes, (newVal) => {
    if (newVal.length > 0) {
        municipalitySizes.value = [];
    }
});

watch(municipalitySizes, (newVal) => {
    if (newVal.length > 0) {
        companySizes.value = [];
    }
});

watch(
    localisation,
    (newValue) => {
        const selectedCodes = newValue
            .map((item: any) => {
                if (typeof item !== "string") {
                    return [];
                }

                let lastThreeChars = item.split(" - ").pop();

                if (
                    item === "Métropole" ||
                    lastThreeChars === "DOM" ||
                    lastThreeChars === "TOM"
                ) {
                    return Object.entries(departmentRegionTypeMapping)
                        .filter(
                            ([, { type }]) =>
                                type === item || type === lastThreeChars
                        )
                        .map(([code]) => code);
                } else if (isNaN(Number(item))) {
                    return Object.entries(departmentRegionTypeMapping)
                        .filter(([, { region }]) => region === item)
                        .map(([code]) => code);
                } else {
                    return item;
                }
            })
            .flat();
        listSelectedCodes.value = selectedCodes;
    },
    { immediate: true }
);

onMounted(() => {
    departments.value = [];
    options.value = treeselectOptions;
});
</script>

<style scoped>
.v-row {
    height: auto !important;
}

.v-col {
    padding: 4px 12px !important;
}
</style>
