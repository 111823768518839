<template>
    <List
        :items="orders"
        :headers="headers"
        :sort-by="[{ key: 'Name', order: 'asc' }]"
        @rowClicked="editOrder"
    />
    <EditArchivedOrder
        v-if="selectedOrderId && editDialog == true"
        :orderId="selectedOrderId"
        v-model="editDialog"
        @close="editDialog = false"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { Order } from "@/types";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import EditArchivedOrder from "./EditArchivedOrder.vue";

const { t: $t } = useI18n();

defineProps({
    orders: Array as () => Order[] | DocumentData[],
});

const selectedOrderId: any = ref();
const editDialog = ref(false);

function editOrder(order: any) {
    selectedOrderId.value = order.id;
    editDialog.value = true;
}

const headers = [
    { title: $t("orders.name"), value: "Name" },
    { title: $t("orders.turnover"), value: "Turnover" },
    { title: $t("orders.comment"), value: "Comments" },
];
</script>
