<template>
    <div ref="chartContainer" class="chart-container">
        <svg ref="lineChart">
            <g id="main-group" transform="translate(50,20)">
                <g id="x-axis"></g>
                <g id="y-axis"></g>
            </g>
        </svg>
    </div>
</template>

<script setup lang="ts">
import * as d3 from "d3";
import { onMounted, ref, watch } from "vue";

interface Data {
    date: Date;
    value1: number;
    value2: number;
}

const props = defineProps<{
    data: Data[];
}>();

const chartContainer = ref<HTMLDivElement | null>(null);
const lineChart = ref<SVGSVGElement | null>(null);

const margin = { top: 20, right: 30, bottom: 30, left: 50 };
const height = 400 - margin.top - margin.bottom;

const updateChart = () => {
    if (!chartContainer.value || !lineChart.value) return;

    const containerWidth = chartContainer.value.clientWidth;
    const width = containerWidth - margin.left - margin.right;

    const svg = d3
        .select(lineChart.value)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom);

    const g = svg.select<SVGGElement>("#main-group");

    const xScale = d3
        .scaleBand()
        .domain(props.data.map((d) => d.date.toString()))
        .range([0, width])
        .padding(0.1);

    const yScale = d3.scaleLinear().domain([0, 100]).range([height, 0]);

    const xAxis = d3
        .axisBottom(xScale)
        .tickSizeOuter(0)
        .tickFormat((d, i) => {
            if (props.data.length <= 14) {
                return d;
            } else if (props.data.length <= 28) {
                return i % 2 === 0 ? d : "";
            } else {
                return i % 4 === 0 ? d : "";
            }
        });
    const yAxis = d3.axisLeft(yScale).tickSizeOuter(0).ticks(10);

    g.select<SVGGElement>("#x-axis")
        .attr("transform", `translate(0,${height})`)
        .call(xAxis);

    g.select<SVGGElement>("#y-axis").call(yAxis);

    const line1 = d3
        .line<Data>()
        // eslint-disable-next-line
        .x((d) => xScale(d.date.toString())! + xScale.bandwidth() / 2)
        .y((d) => yScale(d.value1));

    const line2 = d3
        .line<Data>()
        // eslint-disable-next-line
        .x((d) => xScale(d.date.toString())! + xScale.bandwidth() / 2)
        .y((d) => yScale(d.value2));

    g.selectAll(".line1")
        .data([props.data])
        .join("path")
        .attr("class", "line1")
        .attr("d", line1)
        .attr("fill", "none")
        .attr("stroke", "rgb(var(--v-theme-primary))")
        .attr("stroke-width", 2);

    g.selectAll(".line2")
        .data([props.data])
        .join("path")
        .attr("class", "line2")
        .attr("d", line2)
        .attr("fill", "none")
        .attr("stroke", "rgb(var(--v-theme-secondary))")
        .attr("stroke-width", 2);

    g.selectAll(".dot1")
        .data(props.data)
        .join("circle")
        .attr("class", "dot1")
        // eslint-disable-next-line
        .attr("cx", (d) => xScale(d.date.toString())! + xScale.bandwidth() / 2)
        .attr("cy", (d) => yScale(d.value1))
        .attr("r", 4)
        .attr("fill", "rgb(var(--v-theme-primary))");

    g.selectAll(".dot2")
        .data(props.data)
        .join("circle")
        .attr("class", "dot2")
        // eslint-disable-next-line
        .attr("cx", (d) => xScale(d.date.toString())! + xScale.bandwidth() / 2)
        .attr("cy", (d) => yScale(d.value2))
        .attr("r", 4)
        .attr("fill", "rgb(var(--v-theme-secondary))");

    g.selectAll(".label1")
        .data(props.data)
        .join("text")
        .attr("class", "label1")
        // eslint-disable-next-line
        .attr("x", (d) => xScale(d.date.toString())! + xScale.bandwidth() / 2)
        .attr("y", (d) => yScale(d.value1) - 10)
        .attr("text-anchor", "middle")
        .text((d) => `${d.value1}%`)
        .style("font-size", "12px")
        .style("font-weight", "bold")
        .style("fill", "rgb(var(--v-theme-primary))");

    g.selectAll(".label2")
        .data(props.data)
        .join("text")
        .attr("class", "label2")
        // eslint-disable-next-line
        .attr("x", (d) => xScale(d.date.toString())! + xScale.bandwidth() / 2)
        .attr("y", (d) => yScale(d.value2) - 10)
        .attr("text-anchor", "middle")
        .text((d) => `${d.value2}%`)
        .style("font-size", "12px")
        .style("font-weight", "bold")
        .style("fill", "rgb(var(--v-theme-secondary))");

    g.selectAll(".tick text")
        .style("font-family", "var(--inter)")
        .style("font-size", "0.7rem");

    g.selectAll(".tick line").style("stroke", "none");
};

onMounted(() => {
    if (chartContainer.value) {
        const resizeObserver = new ResizeObserver(updateChart);
        resizeObserver.observe(chartContainer.value);
    }

    updateChart();
});

watch(
    () => props.data,
    () => {
        updateChart();
    }
);
</script>

<style scoped>
.chart-container {
    width: 100%;
}
</style>
