<template>
    <div class="container">
        <Title :title="$t('calls.title')" />
        <Filters
            @applyFilters="applyFilters"
            :prospectsNumber="filteredProspects.length"
            :decisionMakerNumber="decisionMakerCount"
            :callsNumber="callsCount"
            :mailsNumber="mailsCount"
            :meetingsNumber="meetingsCount"
            :koNumber="koCount"
            :creationDateList="creationDateList"
            :operatorList="operatorList"
        />
        <List :prospects="filteredProspects" :loading="loading" />
    </div>
</template>

<script setup lang="ts">
import {
    formatPhoneNumber,
    formatPrice,
    formatTimestampTommhhddmmyyyy,
    getDepartmentNumberFromPostalCode,
    getTrigramFromId,
} from "@/commons/convertion";
import { getActiveOrderList } from "@/commons/firebase";
import Title from "@/components/customVuetify/Title.vue";
import { db } from "@/main";
import { store } from "@/store";
import {
    IdName,
    NextAction,
    Sizes,
    departmentRegionTypeMapping,
} from "@/types";
import {
    collection,
    doc,
    getDocs,
    limit,
    orderBy,
    query,
} from "firebase/firestore";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Filters from "./Filters.vue";
import List from "./List.vue";

const { t: $t } = useI18n();

const prospects = ref<any[]>([]);
const currentClient = ref<IdName | null>(null);
const filters = ref({});
const loading = ref(false);
const creationDateList = ref<{ name: string; id: string }[]>([]);
const operatorList = ref<{ name: string; id: string }[]>([]);

const callsCount = computed(() => {
    return filteredProspects.value.filter(
        (prospect) =>
            prospect.NextAction === NextAction.call || !prospect.NextAction
    ).length;
});

const decisionMakerCount = computed(() => {
    return filteredProspects.value.filter(
        (prospect) => prospect.DecisionMaker === true
    ).length;
});

const mailsCount = computed(() => {
    return filteredProspects.value.filter(
        (prospect) => prospect.NextAction === NextAction.mail
    ).length;
});

const meetingsCount = computed(() => {
    return filteredProspects.value.filter(
        (prospect) => prospect.NextAction === NextAction.meeting
    ).length;
});

const koCount = computed(() => {
    return filteredProspects.value.filter(
        (prospect) => prospect.NextAction === NextAction.ko
    ).length;
});

const filteredProspects = computed(() => {
    if (!filters.value || Object.keys(filters.value).length === 0) {
        return prospects.value;
    }
    return prospects.value.filter((prospect: any) =>
        Object.entries(filters.value).every(([key, value]) => {
            if (key === "id") return true;
            if (
                key === "CallNumber" &&
                Array.isArray(value) &&
                value.length > 0
            ) {
                const numberPart = value.map((v) =>
                    v === "6+" ? 6 : parseInt(v, 10)
                );
                if (numberPart.includes(6)) {
                    return (
                        (prospect[key] && parseInt(prospect[key]) > 5) ||
                        numberPart.includes(parseInt(prospect[key]))
                    );
                } else {
                    return (
                        prospect[key] &&
                        numberPart.includes(parseInt(prospect[key]))
                    );
                }
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "number"
            ) {
                return value.some((v) => v === prospect[key]);
            }
            if (
                typeof value === "number" &&
                prospect[key] &&
                typeof prospect[key] === "number"
            ) {
                return prospect[key] === value;
            }
            if (
                typeof value === "string" &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return prospect[key]
                    .toLowerCase()
                    .includes(value.toLowerCase());
            }
            if (
                Array.isArray(value) &&
                value.length > 0 &&
                prospect[key] &&
                typeof prospect[key] === "string"
            ) {
                return value.some(
                    (v) => v.toLowerCase() === prospect[key].toLowerCase()
                );
            }
            if (
                typeof value === "boolean" &&
                prospect[key] !== undefined &&
                typeof prospect[key] === "boolean"
            ) {
                if (key === "HasContactSheet") {
                    return value ? prospect[key] === value : true;
                }
                return prospect[key] === value;
            }
            return true;
        })
    );
});

function applyFilters(newFilters: any) {
    filters.value = newFilters;
}

async function setupFirestoreListener() {
    loading.value = true;
    prospects.value = [];
    if (!currentClient.value) return;

    const allOrders = await getActiveOrderList();

    const orderProspects = await Promise.all(
        allOrders.value.map(async (order: any) => {
            const prospectsCollection = collection(
                doc(db, "Orders", order.DocId),
                "Prospects"
            );
            const prospectsSnapshot = await getDocs(prospectsCollection);

            return Promise.all(
                prospectsSnapshot.docs.map(async (prospectDoc) => {
                    const prospectData = prospectDoc.data();

                    const callsCollection = collection(
                        prospectDoc.ref,
                        "Calls"
                    );
                    const callsQuery = query(
                        callsCollection,
                        orderBy("Date", "desc"),
                        limit(1)
                    );
                    const callsSnapshot = await getDocs(callsQuery);

                    let lastCall;
                    if (callsSnapshot.docs.length > 0) {
                        lastCall = callsSnapshot.docs[0].data();
                    } else {
                        lastCall = null;
                    }

                    const contactsCollection = collection(
                        prospectDoc.ref,
                        "Contacts"
                    );
                    const contactsSnapshot = await getDocs(contactsCollection);
                    const hasContactSheet = contactsSnapshot.docs.length > 0;

                    let callNumber;

                    if (callsSnapshot.docs.length === 0) {
                        callNumber = 0;
                    } else {
                        callNumber = Number(callsSnapshot.docs[0].id);
                    }

                    let calledToday = false;
                    if (lastCall) {
                        const latestCallDate = lastCall.Date.toDate();
                        const today = new Date();
                        if (
                            latestCallDate.getDate() === today.getDate() &&
                            latestCallDate.getMonth() === today.getMonth() &&
                            latestCallDate.getFullYear() === today.getFullYear()
                        ) {
                            calledToday = true;
                        }
                    }
                    const departmentMapping =
                        departmentRegionTypeMapping[
                            getDepartmentNumberFromPostalCode(
                                prospectData.PostalCode
                            )
                        ];

                    return {
                        DocId: prospectDoc.id,
                        OrderId: order.DocId,
                        ...prospectData,
                        Size: Sizes.find(
                            (size) => size.id === prospectData.Size
                        )?.name,
                        DecisionMaker: lastCall?.DecisionMaker,
                        Phone: prospectData.Phone
                            ? formatPhoneNumber(prospectData.Phone)
                            : "",
                        Turnover: prospectData.Turnover
                            ? formatPrice(prospectData.Turnover)
                            : "",
                        CallNumber: callNumber + 1,
                        CallDate: lastCall?.Date
                            ? formatTimestampTommhhddmmyyyy(lastCall.Date)
                            : "",
                        ProspectInterest: lastCall?.ProspectInterest,
                        NextAction: lastCall?.NextAction ?? NextAction.call,
                        Region: departmentMapping?.region,
                        Department: departmentMapping?.department,
                        CalledToday: calledToday,
                        HasContactSheet: hasContactSheet,
                        CreationDate: prospectData.CreationDate
                            ? prospectData.CreationDate.toDate
                                ? prospectData.CreationDate.toDate()
                                      .toISOString()
                                      .split("T")[0]
                                : prospectData.CreationDate
                            : "",
                        Operator: prospectData.AuthorId,
                    } as any;
                })
            );
        })
    );

    prospects.value = orderProspects.flat().filter((prospect: any) => prospect);

    const uniqueCreationDates = [
        ...new Set(
            prospects.value.map((prospect) =>
                prospect.CreationDate ? prospect.CreationDate : ""
            )
        ),
    ].filter((date) => date !== "");

    creationDateList.value = uniqueCreationDates.map((date) => {
        const [year, month, day] = date.split("-");
        return { name: `${day}/${month}/${year}`, id: date };
    });

    const uniqueOperators = [
        ...new Set(
            prospects.value.map((prospect) =>
                prospect.AuthorId ? prospect.AuthorId : ""
            )
        ),
    ].filter((id) => id !== "");

    operatorList.value = await Promise.all(
        uniqueOperators.map(async (operatorId) => {
            const trigram = await getTrigramFromId(operatorId);
            return { name: trigram, id: operatorId };
        })
    );

    loading.value = false;
}

watch(
    () => store.state.currentClient,
    (newClient) => {
        if (newClient) {
            prospects.value = [];
            currentClient.value = newClient;
            setupFirestoreListener();
        }
    }
);

onMounted(() => {
    currentClient.value = store.state.currentClient;
    setupFirestoreListener();
});
</script>
