import { db } from "@/main";
import { MunicipalitySizes, NextAction, rdvTypes, Sizes } from "@/types";
import { format } from "date-fns";
import {
    collection,
    collectionGroup,
    doc,
    endAt,
    getCountFromServer,
    getDoc,
    getDocs,
    orderBy,
    query,
    startAt,
    where,
} from "firebase/firestore";
import { getTrigram } from "./convertion";
import { getOrderFromId, getUserFromId } from "./firebase";

export async function getTotalCallsForDay(
    clientId: string,
    userId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("EmployeeId", "==", userId),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<=", endDate)
    );

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getTotalCallsTakenForDay(
    clientId: string,
    userId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("EmployeeId", "==", userId),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("PickedUp", "==", true)
    );

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getTotalDecisionMakersForDay(
    clientId: string,
    userId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("EmployeeId", "==", userId),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("DecisionMaker", "==", true)
    );

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getTotalRdvForDay(
    clientId: string,
    userId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("EmployeeId", "==", userId),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.meeting)
    );

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getCallsForEmployee(
    userId: string,
    startDate: Date,
    endDate: Date,
    orderIds?: string[],
    clientIds?: string[]
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let q = query(
        collectionGroup(db, "Calls"),
        where("EmployeeId", "==", userId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate)
    );

    if (orderIds && orderIds.length > 0) {
        q = query(q, where("OrderId", "in", orderIds));
    }

    if (clientIds && clientIds.length > 0) {
        q = query(q, where("ClientId", "in", clientIds));
    }

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getCallsTakenForEmployee(
    userId: string,
    startDate: Date,
    endDate: Date,
    orderIds?: string[],
    clientIds?: string[]
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let q = query(
        collectionGroup(db, "Calls"),
        where("EmployeeId", "==", userId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("PickedUp", "==", true)
    );

    if (orderIds && orderIds.length > 0) {
        q = query(q, where("OrderId", "in", orderIds));
    }

    if (clientIds && clientIds.length > 0) {
        q = query(q, where("ClientId", "in", clientIds));
    }

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getDecisionMakersForEmployee(
    userId: string,
    startDate: Date,
    endDate: Date,
    orderIds?: string[],
    clientIds?: string[]
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let q = query(
        collectionGroup(db, "Calls"),
        where("EmployeeId", "==", userId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("DecisionMaker", "==", true)
    );

    if (orderIds && orderIds.length > 0) {
        q = query(q, where("OrderId", "in", orderIds));
    }

    if (clientIds && clientIds.length > 0) {
        q = query(q, where("ClientId", "in", clientIds));
    }

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getRdvForEmployee(
    userId: string,
    startDate: Date,
    endDate: Date,
    orderIds?: string[],
    clientIds?: string[]
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let q = query(
        collectionGroup(db, "Calls"),
        where("EmployeeId", "==", userId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.meeting)
    );

    if (orderIds && orderIds.length > 0) {
        q = query(q, where("OrderId", "in", orderIds));
    }

    if (clientIds && clientIds.length > 0) {
        q = query(q, where("ClientId", "in", clientIds));
    }

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getCallsForClient(
    clientId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate)
    );

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getCallsTakenForClient(
    clientId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("PickedUp", "==", true)
    );

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getDecisionMakersForClient(
    clientId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("DecisionMaker", "==", true)
    );

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getRdvForClient(
    clientId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.meeting)
    );

    const querySnapshot = await getCountFromServer(q);

    return querySnapshot.data().count;
}

export async function getCallsForOrder(
    orderId: string,
    startDate: Date,
    endDate: Date,
    userId?: string
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let callsQ = query(
        collectionGroup(db, "Calls"),
        where("OrderId", "==", orderId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate)
    );

    if (userId) {
        callsQ = query(callsQ, where("EmployeeId", "==", userId));
    }

    const callsSnapshot = await getCountFromServer(callsQ);

    return callsSnapshot.data().count;
}

export async function getCallsTakenForOrder(
    orderId: string,
    startDate: Date,
    endDate: Date,
    userId?: string
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let callsQ = query(
        collectionGroup(db, "Calls"),
        where("OrderId", "==", orderId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("PickedUp", "==", true)
    );

    if (userId) {
        callsQ = query(callsQ, where("EmployeeId", "==", userId));
    }

    const callsSnapshot = await getCountFromServer(callsQ);

    return callsSnapshot.data().count;
}

export async function getDecisionMakersForOrder(
    orderId: string,
    startDate: Date,
    endDate: Date,
    userId?: string
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let callsQ = query(
        collectionGroup(db, "Calls"),
        where("OrderId", "==", orderId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("DecisionMaker", "==", true)
    );

    if (userId) {
        callsQ = query(callsQ, where("EmployeeId", "==", userId));
    }

    const callsSnapshot = await getCountFromServer(callsQ);

    return callsSnapshot.data().count;
}

export async function getKoForOrder(
    orderId: string,
    startDate: Date,
    endDate: Date,
    userId?: string
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let callsQ = query(
        collectionGroup(db, "Calls"),
        where("OrderId", "==", orderId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.ko)
    );

    if (userId) {
        callsQ = query(callsQ, where("EmployeeId", "==", userId));
    }

    const callsSnapshot = await getCountFromServer(callsQ);

    return callsSnapshot.data().count;
}

export async function getMailForOrder(
    orderId: string,
    startDate: Date,
    endDate: Date,
    userId?: string
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let callsQ = query(
        collectionGroup(db, "Calls"),
        where("OrderId", "==", orderId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.mail)
    );

    if (userId) {
        callsQ = query(callsQ, where("EmployeeId", "==", userId));
    }

    const callsSnapshot = await getCountFromServer(callsQ);

    return callsSnapshot.data().count;
}

export async function getRdvForOrder(
    orderId: string,
    startDate: Date,
    endDate: Date,
    userId?: string
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let callsQ = query(
        collectionGroup(db, "Calls"),
        where("OrderId", "==", orderId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.meeting)
    );

    if (userId) {
        callsQ = query(callsQ, where("EmployeeId", "==", userId));
    }

    const callsSnapshot = await getCountFromServer(callsQ);

    return callsSnapshot.data().count;
}

export async function getOrdersWorkedOnForDay(
    clientId: string,
    userId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const ordersQuery = query(
        collection(db, "Orders"),
        where("ClientId", "==", clientId)
    );
    const ordersSnapshot = await getDocs(ordersQuery);
    const orders = [];

    for (const docSnapshot of ordersSnapshot.docs) {
        const orderId = docSnapshot.id;
        const orderData = docSnapshot.data();

        const totalCalls = await getCallsForOrder(
            orderId,
            startDate,
            endDate,
            userId
        );
        const totalCallsTaken = await getCallsTakenForOrder(
            orderId,
            startDate,
            endDate,
            userId
        );
        const totalDecisionMakers = await getDecisionMakersForOrder(
            orderId,
            startDate,
            endDate,
            userId
        );
        const totalRdv = await getRdvForOrder(
            orderId,
            startDate,
            endDate,
            userId
        );

        if (totalCalls > 0) {
            const rdvOverCallsTaken =
                totalCallsTaken > 0
                    ? Number(((totalRdv / totalCallsTaken) * 100).toFixed(2)) +
                      "%"
                    : "0%";
            const rdvOverDecisionMakers =
                totalDecisionMakers > 0
                    ? Number(
                          ((totalRdv / totalDecisionMakers) * 100).toFixed(2)
                      ) + "%"
                    : "0%";

            const order = {
                id: orderId,
                name: orderData.Name,
                totalCalls,
                totalCallsTaken,
                totalDecisionMakers,
                totalRdv,
                rdvOverCallsTaken,
                rdvOverDecisionMakers,
            };
            orders.push(order);
        }
    }

    return orders;
}

export async function getMeetingsFromClient(
    userId: string,
    clientId: string,
    startDate: Date,
    endDate: Date
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const meetingsSnapshot = await getDocs(
        query(
            collectionGroup(db, "Calls"),
            where("NextAction", "==", 2),
            where("ClientId", "==", clientId),
            where("EmployeeId", "==", userId),
            orderBy("Date"),
            startAt(startDate),
            endAt(endDate)
        )
    );

    const getSizeName = (sizeId: string | undefined) => {
        if (!sizeId) return null;
        const size =
            Sizes.find((s) => s.id === sizeId) ||
            MunicipalitySizes.find((m) => m.id === sizeId);
        return size ? size.name : null;
    };

    const meetingsList = await Promise.all(
        meetingsSnapshot.docs.map(async (document) => {
            const appointmentDate = document.data().AppointmentDate;
            const user = (
                await getUserFromId(document.data().EmployeeId)
            ).data();
            const order = (
                await getOrderFromId(document.data().OrderId)
            ).data();
            const prospectId = document.ref.parent.parent?.id;

            if (!prospectId) return;
            const prospectRef = doc(
                db,
                "Orders",
                document.data().OrderId,
                "Prospects",
                prospectId
            );
            const prospectSnap = await getDoc(prospectRef);
            const prospectData = prospectSnap.data();

            let hours = "";
            let minutes = "";
            let date = null;
            if (appointmentDate) {
                date = appointmentDate.toDate();
                hours = date.getHours().toString().padStart(2, "0");
                minutes = date.getMinutes().toString().padStart(2, "0");
            }
            return {
                Type: rdvTypes[
                    document.data().RdvType as keyof typeof rdvTypes
                ],
                Associate: document.data().AssociateId,
                FirstName: document.data().FirstName,
                LastName: document.data().LastName,
                Position: document.data().Position,
                DateToBeDefined: document.data().DateToBeDefined,
                Phone: document.data().Phone,
                Mail: document.data().Mail,
                Company: prospectData?.Name,
                Contact: `${document.data().FirstName} ${document
                    .data()
                    .LastName.toUpperCase()}`,
                Date: appointmentDate ? date.toLocaleDateString("fr-FR") : null,
                Hour: appointmentDate ? `${hours}:${minutes}` : null,
                Comments: document.data().Comments,
                DateTaken: document
                    .data()
                    .Date.toDate()
                    .toLocaleDateString("fr-FR"),
                Employee: getTrigram(user?.FirstName, user?.LastName),
                OrderId: document.data().OrderId,
                ProspectId: document.ref.parent.parent?.id,
                DocId: document.id,
                Order: order?.Name,
                PostalCode: prospectData?.PostalCode,
                City: prospectData?.City,
                Website: prospectData?.Website,
                Size: getSizeName(prospectData?.Size),
                SizeCode: prospectData?.Size,
                Turnover: prospectData?.Turnover,
                Address: prospectData?.Address,
                CompanyPhone: prospectData?.Phone,
                APECode: prospectData?.APECode,
            };
        })
    );

    return meetingsList.filter(Boolean);
}

export async function getCallNumberForProspect(
    orderId: string,
    prospectId: string
) {
    if (!orderId || !prospectId) return 0;
    const q = query(
        collection(db, "Orders", orderId, "Prospects", prospectId, "Calls")
    );
    const querySnapshot = await getCountFromServer(q);
    return querySnapshot.data().count;
}

export async function getRdvForDate(
    clientId: string,
    startDate: Date,
    endDate: Date,
    archived: boolean
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.meeting)
    );

    let q2;

    if (archived) {
        q2 = query(
            collectionGroup(db, "ArchivedCalls"),
            where("ClientId", "==", clientId),
            where("Date", ">=", startDate),
            where("Date", "<", endDate),
            where("NextAction", "==", NextAction.meeting)
        );
    }

    const querySnapshot = await getDocs(q);

    if (archived && q2) {
        const querySnapshot2 = await getDocs(q2);
        return querySnapshot.docs
            .map((doc) => ({
                ...doc.data(),
                DocId: doc.id,
                ProspectId: doc.ref.parent.parent?.id,
            }))
            .concat(
                querySnapshot2.docs.map((doc) => ({
                    ...doc.data(),
                    DocId: doc.id,
                    ProspectId: doc.ref.parent.parent?.id,
                }))
            );
    }

    return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        DocId: doc.id,
        ProspectId: doc.ref.parent.parent?.id,
    }));
}

export async function getMailForDate(
    clientId: string,
    startDate: Date,
    endDate: Date,
    archived: boolean
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.mail)
    );
    const q2 = query(
        collectionGroup(db, "Calls"),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate),
        where("NextAction", "==", NextAction.call),
        where("SendMail", "==", true)
    );

    let q3, q4;

    if (archived) {
        q3 = query(
            collectionGroup(db, "ArchivedCalls"),
            where("ClientId", "==", clientId),
            where("Date", ">=", startDate),
            where("Date", "<", endDate),
            where("NextAction", "==", NextAction.mail)
        );
        q4 = query(
            collectionGroup(db, "ArchivedCalls"),
            where("ClientId", "==", clientId),
            where("Date", ">=", startDate),
            where("Date", "<", endDate),
            where("NextAction", "==", NextAction.call),
            where("SendMail", "==", true)
        );
    }

    const querySnapshot = await getDocs(q);
    const querySnapshot2 = await getDocs(q2);

    if (archived && q3 && q4) {
        const querySnapshot3 = await getDocs(q3);
        const querySnapshot4 = await getDocs(q4);
        return querySnapshot.docs
            .map((doc) => ({
                ...doc.data(),
                DocId: doc.id,
                ProspectId: doc.ref.parent.parent?.id,
            }))
            .concat(
                querySnapshot2.docs.map((doc) => ({
                    ...doc.data(),
                    DocId: doc.id,
                    ProspectId: doc.ref.parent.parent?.id,
                }))
            )
            .concat(
                querySnapshot3.docs.map((doc) => ({
                    ...doc.data(),
                    DocId: doc.id,
                    ProspectId: doc.ref.parent.parent?.id,
                }))
            )
            .concat(
                querySnapshot4.docs.map((doc) => ({
                    ...doc.data(),
                    DocId: doc.id,
                    ProspectId: doc.ref.parent.parent?.id,
                }))
            );
    }

    return querySnapshot.docs
        .map((doc) => ({
            ...doc.data(),
            DocId: doc.id,
            ProspectId: doc.ref.parent.parent?.id,
        }))
        .concat(
            querySnapshot2.docs.map((doc) => ({
                ...doc.data(),
                DocId: doc.id,
                ProspectId: doc.ref.parent.parent?.id,
            }))
        );
}

export async function getCallsForDate(
    clientId: string,
    startDate: Date,
    endDate: Date,
    archived: boolean
) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const q = query(
        collectionGroup(db, "Calls"),
        where("ClientId", "==", clientId),
        where("Date", ">=", startDate),
        where("Date", "<", endDate)
    );

    let q2;

    if (archived) {
        q2 = query(
            collectionGroup(db, "ArchivedCalls"),
            where("ClientId", "==", clientId),
            where("Date", ">=", startDate),
            where("Date", "<", endDate)
        );
    }

    const querySnapshot = await getDocs(q);

    if (archived && q2) {
        const querySnapshot2 = await getDocs(q2);
        return querySnapshot.docs
            .map((doc) => ({
                ...doc.data(),
                DocId: doc.id,
                ProspectId: doc.ref.parent.parent?.id,
            }))
            .concat(
                querySnapshot2.docs.map((doc) => ({
                    ...doc.data(),
                    DocId: doc.id,
                    ProspectId: doc.ref.parent.parent?.id,
                }))
            );
    }

    return querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        DocId: doc.id,
        ProspectId: doc.ref.parent.parent?.id,
    }));
}

export async function getCallsAndPickedUpForOrderOrClient(
    startDate: Date,
    endDate: Date,
    clientId: string,
    orderIds?: string[]
) {
    const dates = getDatesBetween(startDate, endDate);

    const result = await Promise.all(
        dates.map(async (date) => {
            const startOfDay = new Date(date);
            startOfDay.setHours(0, 0, 0, 0);

            const endOfDay = new Date(date);
            endOfDay.setHours(23, 59, 59, 999);

            let callsQ = query(
                collectionGroup(db, "Calls"),
                where("ClientId", "==", clientId),
                where("Date", ">=", startOfDay),
                where("Date", "<=", endOfDay)
            );

            if (orderIds && orderIds.length > 0) {
                callsQ = query(callsQ, where("OrderId", "in", orderIds));
            }

            const callsCount = await getCountFromServer(callsQ);

            let pickedUpQ = query(
                collectionGroup(db, "Calls"),
                where("ClientId", "==", clientId),
                where("Date", ">=", startOfDay),
                where("Date", "<=", endOfDay),
                where("PickedUp", "==", true)
            );

            if (orderIds && orderIds.length > 0) {
                pickedUpQ = query(pickedUpQ, where("OrderId", "in", orderIds));
            }

            const pickedUpCount = await getCountFromServer(pickedUpQ);

            return {
                date: format(date, "dd/MM/yyyy"),
                calls: callsCount.data().count,
                pickedUp: pickedUpCount.data().count,
            };
        })
    );

    return result;
}

export async function getCallsPickedUpAndDecisionMakersForOrderOrClient(
    startDate: Date,
    endDate: Date,
    clientId: string,
    orderIds?: string[]
) {
    const dates = getDatesBetween(startDate, endDate);

    const result = await Promise.all(
        dates.map(async (date) => {
            const startOfDay = new Date(date);
            startOfDay.setHours(0, 0, 0, 0);

            const endOfDay = new Date(date);
            endOfDay.setHours(23, 59, 59, 999);

            let callsQ = query(
                collectionGroup(db, "Calls"),
                where("ClientId", "==", clientId),
                where("Date", ">=", startOfDay),
                where("Date", "<=", endOfDay)
            );

            if (orderIds && orderIds.length > 0) {
                callsQ = query(callsQ, where("OrderId", "in", orderIds));
            }

            const callsCount = await getCountFromServer(callsQ);

            let pickedUpQ = query(
                collectionGroup(db, "Calls"),
                where("ClientId", "==", clientId),
                where("Date", ">=", startOfDay),
                where("Date", "<=", endOfDay),
                where("PickedUp", "==", true)
            );

            if (orderIds && orderIds.length > 0) {
                pickedUpQ = query(pickedUpQ, where("OrderId", "in", orderIds));
            }

            const pickedUpCount = await getCountFromServer(pickedUpQ);

            let decisionMakersQ = query(
                collectionGroup(db, "Calls"),
                where("ClientId", "==", clientId),
                where("Date", ">=", startOfDay),
                where("Date", "<=", endOfDay),
                where("DecisionMaker", "==", true)
            );

            if (orderIds && orderIds.length > 0) {
                decisionMakersQ = query(
                    decisionMakersQ,
                    where("OrderId", "in", orderIds)
                );
            }

            const decisionMakersCount = await getCountFromServer(
                decisionMakersQ
            );

            return {
                date: format(date, "dd/MM/yyyy"),
                calls: callsCount.data().count,
                pickedUp: pickedUpCount.data().count,
                decisionMakers: decisionMakersCount.data().count,
            };
        })
    );

    return result;
}

function getDatesBetween(startDate: Date, endDate: Date) {
    const dates = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
}
