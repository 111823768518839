<template>
    <v-row>
        <v-col cols="2">
            <v-card class="card" rounded="lg">
                <v-card-title class="card-title">{{
                    $t("home.callsGivenToday")
                }}</v-card-title>
                <v-card-text class="card-text">
                    <Span>{{ cardInfos.callsGivenToday }}</Span>
                    <div class="icon-background">
                        <v-icon large size="110">mdi-phone-ring-outline</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="card" rounded="lg">
                <v-card-title class="card-title">{{
                    $t("home.callsTakenToday")
                }}</v-card-title>
                <v-card-text class="card-text">
                    <Span>{{ cardInfos.callsTakenToday }}</Span>
                    <div class="icon-background">
                        <v-icon large size="110"
                            >mdi-phone-in-talk-outline</v-icon
                        >
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="card" rounded="lg">
                <v-card-title class="card-title">{{
                    $t("home.decisionMakers")
                }}</v-card-title>
                <v-card-text class="card-text">
                    <Span>{{ cardInfos.decisionMakers }}</Span>
                    <div class="icon-background">
                        <v-icon large size="110">mdi-account-tie</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="card" rounded="lg">
                <v-card-title class="card-title">{{
                    $t("home.rdvTakenToday")
                }}</v-card-title>
                <v-card-text class="card-text">
                    <Span>{{ cardInfos.rdvTakenToday }}</Span>
                    <div class="icon-background">
                        <v-icon large size="110">mdi-calendar-text</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="card" rounded="lg">
                <v-card-title class="card-title">{{
                    $t("home.rateOnPickedUp")
                }}</v-card-title>
                <v-card-text class="card-text">
                    <Span>{{ cardInfos.rateOnPickedUp }}</Span>
                    <div class="icon-background">
                        <v-icon large size="110">mdi-percent-outline</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card class="card" rounded="lg">
                <v-card-title class="card-title">{{
                    $t("home.rateOnDecisionMakers")
                }}</v-card-title>
                <v-card-text class="card-text">
                    <Span>{{ cardInfos.rateOnDecisionMakers }}</Span>
                    <div class="icon-background">
                        <v-icon large size="110">mdi-percent-outline</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { getCurrentClient } from "@/commons/firebase";
import {
    getTotalCallsForDay,
    getTotalCallsTakenForDay,
    getTotalDecisionMakersForDay,
    getTotalRdvForDay,
} from "@/commons/reporting";
import { Span } from "@/components/customVuetify";
import { getAuth } from "firebase/auth";
import { onMounted, ref, watch } from "vue";

const auth = getAuth();

const currentClient = getCurrentClient();

const cardInfos = ref({
    callsGivenToday: 0,
    callsTakenToday: 0,
    decisionMakers: 0,
    rdvTakenToday: 0,
    rateOnPickedUp: 0,
    rateOnDecisionMakers: 0,
});

async function getData() {
    if (!auth.currentUser || !currentClient.value) {
        return;
    }
    cardInfos.value.callsGivenToday = await getTotalCallsForDay(
        currentClient.value?.id,
        auth.currentUser.uid,
        new Date(),
        new Date()
    );
    cardInfos.value.callsTakenToday = await getTotalCallsTakenForDay(
        currentClient.value?.id,
        auth.currentUser.uid,
        new Date(),
        new Date()
    );
    cardInfos.value.decisionMakers = await getTotalDecisionMakersForDay(
        currentClient.value?.id,
        auth.currentUser.uid,
        new Date(),
        new Date()
    );
    cardInfos.value.rdvTakenToday = await getTotalRdvForDay(
        currentClient.value?.id,
        auth.currentUser.uid,
        new Date(),
        new Date()
    );
    cardInfos.value.rateOnPickedUp =
        cardInfos.value.callsTakenToday > 0
            ? Math.round(
                  (cardInfos.value.rdvTakenToday /
                      cardInfos.value.callsTakenToday) *
                      100
              )
            : 0;
    cardInfos.value.rateOnDecisionMakers =
        cardInfos.value.decisionMakers > 0
            ? Math.round(
                  (cardInfos.value.rdvTakenToday /
                      cardInfos.value.decisionMakers) *
                      100
              )
            : 0;
}

watch(currentClient, async () => {
    await getData();
});

onMounted(async () => {
    await getData();
});
</script>

<style scoped>
.card {
    padding: 1.5vh 1.5vw;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.card-title {
    font-size: 1.05rem;
    color: rgb(var(--v-theme-text));
    opacity: 0.65;
    font-weight: bold;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.card-text {
    font-size: 3rem;
    font-weight: bold;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.icon-background {
    position: absolute;
    right: -5%;
    top: 40%;
    color: rgb(var(--v-theme-secondary));
    opacity: 0.5;
}
</style>
