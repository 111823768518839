<template>
    <Dialog
        :title="$t('duplicateProspect.attention')"
        :closable="false"
        :clickOutside="false"
        max-width="600px"
    >
        <template #text>
            <div class="text-center">
                {{ $t("duplicateProspect.itsPossible") }}
                {{ $t("duplicateProspect.import") }}
            </div>
            <v-row style="height: auto">
                <v-col cols="6">
                    <v-card>
                        <v-card-title
                            ><Span class="bold white">{{
                                $t("duplicateProspect.prospectDB")
                            }}</Span></v-card-title
                        >
                        <v-card-text class="pt-4">
                            <div
                                v-for="(value, key) in prospectDB"
                                :key="key"
                                class="pb-2"
                            >
                                <Span class="bold"
                                    >{{
                                        $t("duplicateProspect." + key)
                                    }}: </Span
                                >{{ value }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title
                            ><Span class="bold white">{{
                                $t("duplicateProspect.prospectImport")
                            }}</Span></v-card-title
                        >
                        <v-card-text class="pt-4">
                            <div
                                v-for="(value, key) in prospectImport"
                                :key="key"
                                class="pb-2"
                            >
                                <Span class="bold"
                                    >{{
                                        $t("duplicateProspect." + key)
                                    }}: </Span
                                >{{ value }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template #actions>
            <v-btn
                class="button-bg-red"
                @click="$emit('rejectAll')"
                :text="$t('duplicateProspect.rejectAll')"
            />
            <v-btn
                class="red"
                @click="$emit('reject')"
                :text="$t('duplicateProspect.reject')"
            />
            <v-spacer></v-spacer>
            <v-btn
                color="var(--ys-green)"
                @click="$emit('accept')"
                :text="$t('duplicateProspect.accept')"
            />
            <v-btn
                class="button-bg-green"
                @click="$emit('acceptAll')"
                :text="$t('duplicateProspect.acceptAll')"
            />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Dialog, Span } from "./customVuetify";

const { t: $t } = useI18n();

defineProps({
    prospectDB: {
        type: Object,
        required: true,
    },
    prospectImport: {
        type: Object,
        required: true,
    },
});

defineEmits(["reject", "accept", "rejectAll", "acceptAll"]);
</script>

<style scoped>
.v-card-title {
    background-color: rgb(var(--v-theme-secondary));
}

.white {
    color: white !important;
}
</style>
