import { APECodes } from "./ape";
import { FirestoreDoc } from "./common";

export const orderState = [
    { name: "orders.todo", id: 1 },
    { name: "orders.ongoing", id: 2 },
    { name: "orders.done", id: 3 },
];

export const orderSectorType = [
    { name: "orders.private", id: 1 },
    { name: "orders.public", id: 2 },
    { name: "orders.all", id: 3 },
];

export const orderPhase = [
    { name: "orders.testing", id: 1 },
    { name: "orders.industrialization", id: 2 },
];

export const orderPriority = [
    { name: "orders.low", id: 1 },
    { name: "orders.medium", id: 2 },
    { name: "orders.high", id: 3 },
    { name: "orders.urgent", id: 4 },
];

export interface Departments {
    DepartmentNumber: string;
    EmployeeID: string;
}

export interface Order extends FirestoreDoc {
    ApeCodes: APECodes[];
    AuthorId: string;
    Classes: string[];
    ClientId: string;
    Comments: string;
    CreationDate: Date;
    Departments: Departments[];
    Divisions: string[];
    Groups: string[];
    Name: string;
    Phase: number;
    Priority: number;
    Sections: string;
    SectorType: number;
    Sizes: string[];
    State: number;
    Turnover: string;
}

export const defaultOrderData: Partial<Order> = {
    ApeCodes: [],
    AuthorId: "",
    Classes: undefined,
    ClientId: "",
    Comments: "",
    CreationDate: new Date(),
    Departments: [],
    Divisions: undefined,
    Groups: undefined,
    Name: "",
    Phase: null as unknown as number,
    Priority: null as unknown as number,
    Sections: null as unknown as string,
    SectorType: null as unknown as number,
    Sizes: [],
    State: 1,
    Turnover: "",
};

export interface Scripts {
    Intro: string;
    ValueProposition: string;
    ObjectionHandling: string;
    MandatoryHabits: string;
    DesiredInterlocutor: string;
    EndOfCall: string;
    Subject: string;
    KeyArguments: string;
    CreatorID: number;
    DateOfCreation: Date;
    LastModificationDate: Date;
    LastModifierID: number;
    NumberOfModifications: number;
    Order: number;
}
