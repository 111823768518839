<template>
    <div ref="chartContainer" class="chart-container">
        <svg ref="barChart">
            <g id="main-group" transform="translate(50,20)">
                <g id="x-axis"></g>
                <g id="y-axis"></g>
            </g>
        </svg>
    </div>
</template>

<script setup lang="ts">
import * as d3 from "d3";
import { onMounted, ref, watch } from "vue";

interface Data {
    date: Date;
    higherValue: number;
    lowerValue: number;
}

const props = defineProps<{
    data: Data[];
}>();

const chartContainer = ref<HTMLDivElement | null>(null);
const barChart = ref<SVGSVGElement | null>(null);

const margin = { top: 20, right: 30, bottom: 30, left: 50 };
const height = 400 - margin.top - margin.bottom;

const updateChart = () => {
    if (!chartContainer.value || !barChart.value) return;

    const containerWidth = chartContainer.value.clientWidth;
    const width = containerWidth - margin.left - margin.right;

    const svg = d3
        .select(barChart.value)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom);

    const g = svg.select<SVGGElement>("#main-group");

    const maxCalls = d3.max(props.data, (d) => d.higherValue) || 0;
    const yScale = d3.scaleLinear().domain([0, maxCalls]).range([height, 0]);

    const numBars = props.data.length;
    const padding = numBars === 1 ? 0.7 : 0.1 + 1 / numBars;

    const xScale = d3
        .scaleBand()
        .domain(props.data.map((d) => d.date.toString()))
        .range([0, width])
        .padding(padding);

    const xAxis = d3
        .axisBottom(xScale)
        .tickSizeOuter(0)
        .ticks(props.data.length > 15 ? 0 : undefined)
        .tickFormat((d, i) => {
            if (props.data.length <= 14) {
                return d;
            } else if (props.data.length <= 28) {
                return i % 2 === 0 ? d : "";
            } else {
                return i % 4 === 0 ? d : "";
            }
        });

    const yAxis = d3
        .axisLeft(yScale)
        .ticks(maxCalls / 10)
        .tickSizeOuter(0);

    const nonZeroData = props.data.filter((d) => d.higherValue > 0);

    g.select<SVGGElement>("#x-axis")
        .attr("transform", `translate(0,${height})`)
        .call(xAxis);

    g.select<SVGGElement>("#y-axis").call(yAxis);

    g.selectAll(".higherValue")
        .data(nonZeroData)
        .join(
            (enter) => enter.append("rect").attr("class", "higherValue"),
            (update) => update,
            (exit) => exit.remove()
        )
        .attr("x", (d) => xScale(d.date.toString()) ?? 0)
        .attr("y", (d) => yScale(d.higherValue))
        .attr("height", (d) => height - yScale(d.higherValue))
        .attr("width", xScale.bandwidth())
        .attr("fill", "rgb(var(--v-theme-primary))")
        .attr("rx", 10);

    g.selectAll(".lowerValue")
        .data(nonZeroData)
        .join(
            (enter) => enter.append("rect").attr("class", "lowerValue"),
            (update) => update,
            (exit) => exit.remove()
        )
        .attr("x", (d) => xScale(d.date.toString()) ?? 0)
        .attr("y", (d) => yScale(d.lowerValue))
        .attr("height", (d) => height - yScale(d.lowerValue))
        .attr("width", xScale.bandwidth())
        .attr("fill", "rgb(var(--v-theme-secondary))")
        .attr("rx", 10);

    g.selectAll(".lowerValueLabel")
        .data(nonZeroData)
        .join(
            (enter) => enter.append("text").attr("class", "lowerValueLabel"),
            (update) => update,
            (exit) => exit.remove()
        )
        // eslint-disable-next-line
        .attr("x", (d) => xScale(d.date.toString())! + xScale.bandwidth() / 2)
        .attr(
            "y",
            (d) => yScale(d.lowerValue) + (height - yScale(d.lowerValue)) / 2
        )
        .text((d) =>
            height - yScale(d.lowerValue) > 15 && xScale.bandwidth() > 25
                ? d.lowerValue.toString()
                : ""
        )
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .attr("fill", "white")
        .style("font-weight", "bold");

    g.selectAll(".higherValueLabel")
        .data(nonZeroData)
        .join(
            (enter) => enter.append("text").attr("class", "higherValueLabel"),
            (update) => update,
            (exit) => exit.remove()
        )
        // eslint-disable-next-line
        .attr("x", (d) => xScale(d.date.toString())! + xScale.bandwidth() / 2)
        .attr(
            "y",
            (d) =>
                yScale(d.lowerValue) +
                (yScale(d.higherValue) - yScale(d.lowerValue)) / 2 +
                2
        )
        .text((d) =>
            yScale(d.lowerValue) - yScale(d.higherValue) > 25 &&
            xScale.bandwidth() > 25
                ? d.higherValue.toString()
                : ""
        )
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .attr("fill", "white")
        .style("font-weight", "bold");

    g.selectAll(".tick text")
        .style("font-family", "var(--inter)")
        .style("font-size", "0.7rem");

    g.selectAll(".tick line").style("stroke", "none");
};

onMounted(() => {
    if (chartContainer.value) {
        const resizeObserver = new ResizeObserver(updateChart);
        resizeObserver.observe(chartContainer.value);
    }

    updateChart();
});

watch(
    () => props.data,
    () => {
        updateChart();
    }
);
</script>

<style scoped>
.chart-container {
    width: 100%;
}
</style>
