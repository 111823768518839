<template>
    <Dialog
        v-model="dialogOpen"
        :title="$t('common.help')"
        width="35%"
        @submit="sendTicket"
        @close="close"
    >
        <template #text>
            <PopupTextField
                v-model="ticket.name"
                :label="$t('help.ticketName')"
                :rules="[rules.required]"
            />
            <TextArea
                v-model="ticket.description"
                :label="$t('help.description')"
                :rules="[rules.required]"
            />
            <PopupSelect
                v-model="selectedPriority"
                :items="priorities"
                :label="$t('help.priority')"
                :rules="[rules.required]"
                item-title="name"
                item-value="value"
                return-object
            />
            <PopupSelect
                v-model="selectedType"
                :items="types"
                :label="$t('help.type')"
                :rules="[rules.required]"
                item-title="name"
                item-value="value"
                return-object
            />
        </template>
        <template #actions>
            <div class="center-content">
                <v-btn
                    type="submit"
                    class="button-bg-secondary"
                    :loading="isLoading"
                    :text="$t('common.send')"
                    prepend-icon="mdi-send"
                />
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { getCurrentUser } from "@/commons/firebase";
import Dialog from "@/components/customVuetify/Dialog.vue";
import PopupSelect from "@/components/customVuetify/SelectField.vue";
import TextArea from "@/components/customVuetify/TextArea.vue";
import PopupTextField from "@/components/customVuetify/TextField.vue";
import rules from "@/rules";
import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t: $t } = useI18n();

const dialogOpen = ref(false);
const isLoading = ref(false);
const route = useRoute();

const ticket = ref({
    name: "",
    description: "",
});

const selectedPriority: Ref<{ name: string; value: string } | null> = ref(null);
const selectedType: Ref<{ name: string; value: string } | null> = ref(null);

const priorities = ref([
    { name: $t("help.low"), value: "LOW" },
    { name: $t("help.medium"), value: "MEDIUM" },
    { name: $t("help.high"), value: "HIGH" },
]);

const types = ref([
    { name: $t("help.bug"), value: "BUG" },
    { name: $t("help.feature"), value: "FEATURE" },
]);

function close() {
    ticket.value = {
        name: "",
        description: "",
    };
    selectedPriority.value = null;
    selectedType.value = null;
    emit("close");
}

const emit = defineEmits(["close", "success"]);

async function sendTicket() {
    try {
        isLoading.value = true;
        const url =
            "https://europe-west1-youscalesaas.cloudfunctions.net/sendTicketHttp";

        if (!selectedPriority.value || !selectedType.value) {
            throw new Error("Priority and type must be selected");
        }

        const currentUser = await getCurrentUser();

        const body = {
            properties: {
                hs_pipeline: "0",
                hs_pipeline_stage: "1",
                hs_ticket_priority: selectedPriority.value.value,
                subject: ticket.value.name,
                content: ticket.value.description,
                hs_ticket_category: selectedType.value.value,
                route: route.fullPath,
                userid: currentUser?.FirstName + " " + currentUser?.LastName,
            },
        };

        const auth = getAuth();
        if (!auth.currentUser) {
            throw new Error("User not authenticated");
        }
        const idToken = await getIdToken(auth.currentUser);

        const response = await axios.post(url, body, {
            headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
            },
        });

        if (response.data.message == "Ticket created successfully") {
            emit("success");
            close();
        } else {
            throw new Error("Error sending ticket");
        }
    } catch (error) {
        alert(
            "Error sending ticket. Please try again or contact support at ticket@youscale.fr"
        );
    } finally {
        isLoading.value = false;
        close();
    }
}
</script>
