<template>
    <Card v-if="orderComment" :title="$t('calls.orderComment')">
        <template #content>
            <v-row>
                <v-col>
                    <TextArea
                        v-model="orderComment"
                        :label="$t('calls.orderComment')"
                        :readonly="true"
                        :disableMessage="true"
                    />
                </v-col>
            </v-row>
        </template>
    </Card>
</template>

<script setup lang="ts">
import Card from "@/components/customVuetify/Card.vue";
import TextArea from "@/components/customVuetify/TextArea.vue";
import { db } from "@/main";
import { doc, getDoc } from "firebase/firestore";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const orderComment = ref();

const props = defineProps({
    orderId: {
        type: String,
        required: true,
    },
});

async function getOrderComment() {
    if (!props.orderId) {
        return;
    }

    const docRef = doc(db, "Orders", props.orderId);

    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            orderComment.value = docSnap.data().Comments;
        }
    } catch (error) {
        console.error("Error fetching document:", error);
    }
}

watch(() => props.orderId, getOrderComment, { immediate: true });
</script>
