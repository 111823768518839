<template>
    <List
        :headers="headers"
        :items="clients"
        :sort-by="[{ key: 'Name', order: 'asc' }]"
        @rowClicked="editClient"
    />
    <EditArchivedClient
        v-if="selectedClient && editDialog == true"
        :clientId="selectedClient"
        v-model="editDialog"
        @close="editDialog = false"
    />
</template>

<script setup lang="ts">
import List from "@/components/customVuetify/List.vue";
import { Client } from "@/types";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import EditArchivedClient from "./EditArchivedClient.vue";

const { t: $t } = useI18n();

defineProps({
    clients: Array as () => Client[] | DocumentData[],
});

const selectedClient: any = ref();
const editDialog = ref(false);

function editClient(client: any) {
    selectedClient.value = client.id;
    editDialog.value = true;
}

const headers = [
    { title: $t("clients.name"), value: "Name" },
    { title: $t("clients.address"), value: "Address" },
    { title: $t("clients.postalCode"), value: "PostalCode" },
    { title: $t("clients.city"), value: "City" },
    { title: $t("clients.department"), value: "Department" },
    { title: $t("clients.region"), value: "Region" },
    { title: $t("clients.country"), value: "Country" },
    { title: $t("clients.siret"), value: "SIRET" },
    { title: $t("clients.apeCode"), value: "APECode" },
    { title: $t("clients.capital"), value: "Capital" },
    { title: $t("clients.turnover"), value: "Turnover" },
    { title: $t("clients.size"), value: "Size" },
];
</script>
