<template>
    <div v-if="!isAdmin" class="container">
        <Title :title="$t('reporting.title')" />
        <DetailsForClient />
    </div>
    <div v-if="isAdmin" class="container">
        <Title :title="$t('reporting.title')" />
        <ClientDetails />
        <EmployeeDetails />
    </div>
</template>

<script setup lang="ts">
import { getCurrentUser } from "@/commons/firebase";
import Title from "@/components/customVuetify/Title.vue";
import { computed } from "vue";
import ClientDetails from "./ClientDetails/Index.vue";
import DetailsForClient from "./DetailsForClient/Index.vue";
import EmployeeDetails from "./EmployeeDetails/Index.vue";

const currentUser = await getCurrentUser();

const isAdmin = computed(() => {
    return currentUser?.ClientId === "6X8mesClIltu3m4vtIae" ? true : false;
});
</script>

<style scoped></style>
